<template>
  <div class="lease-area-container">
    <div class="amendments-container">
      <label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Amendments</IxRes>
      </label>
      <div class="amendments-list">
        <ToggleButton
          v-for="(amendment, index) in amendments"
          :key="index"
          :value="currentAmendment"
          :toggle-value="amendment"
          large
          @input="setCurrentAmendment(amendment)"
        >
          <Slot>
            {{ formatISODate(amendment.validOn) }}
          </Slot>
        </ToggleButton>
      </div>

      <div class="table-container">
        <SimpleTable
          ref="segments"
          :columns="columnDefinition"
          :data="areas"
          :highlight="selected"
        >
          <template slot="link" slot-scope="{value}">
            <a :href="value" target="_blank" data-ajax="false">
              <span class="glyphicon glyphicon-circle-arrow-right" />
            </a>
          </template>

          <template slot="sizeActual" slot-scope="{value}">
            <div class="pull-right">
              {{ value }}
            </div>
          </template>

          <template slot="header-sizeContractual" slot-scope="{title}">
            <span>{{ title }}</span>
            <span class="fa fa-pencil" />
          </template>

          <template slot="sizeContractual" slot-scope="{value, row}">
            <span class="input-field">
              <NumericInput
                :value="value"
                format="f4"
                hide-description
                class="re-numeric"
                @input="onSetSize(row, $event)"
              />
            </span>
          </template>
        </SimpleTable>

        <TableButtons
          :selected-items="selection.segments"
          :enable-edit="false"
          :enable-print="false"
          @add="onAdd"
          @delete="onDelete"
        />
      </div>

      <SegmentModal
        v-if="showModal"
        :title="SR_RealEstate.AddSegmentSearchArea"
        :show-modal="showModal"
        @hidden="closeModal"
        @addSegment="addSegment"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters, mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {format} from 'src/js/i18n/conversion'

import SimpleTable from '@components/table/SimpleTable'
import NumericInput from '@components/forms/NumericInput'
import TableButtons from '@realestate/components/common/TableButtons'
import ToggleButton from '@components/ToggleButton'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import SegmentModal from './widgets/SegmentModal'

export default {
  components: {
    NumericInput,
    TableButtons,
    SimpleTable,
    SegmentModal,
    ToggleButton
  },

  mixins: [
    makeSelectionMixin('segments')
  ],

  data () {
    return {
      segmentsSize: null,
      selected: (row) => {
        if (this.selection.segments.includes(row)) {
          return ['success']
        }
        return []
      },
      showModal: false
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      '@realestate.SR_RealEstate_Lease'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit'
    ]),

    ...mapFormFields('realEstate/lease', [
      'current.amendments',
      'currentAmendment',
      'currentAmendment.areas'
    ]),

    columnDefinition () {
      return {
        link: {
          title: ' '
        },
        landParcelIdentifier: this.SR_RealEstate.LandParcel,
        usage: {
          title: this.SR_RealEstate.Usage,
          value: x => x.usage.text
        },
        groundpoints: this.SR_RealEstate.Groundpoints,
        sizeActual: {
          title: this.SR_RealEstate.SegmentSize,
          value: x => format(x.sizeActual, 'f4')
        },
        sizeContractual: this.SR_RealEstate.LeasedSize
      }
    }
  },
  methods: {
    ...mapMutations('realEstate/lease', [
      'appendArea',
      'removeArea',
      'setLeasedSize',
      'setCurrentAmendment'
    ]),

    onAdd () {
      this.showModal = true
    },

    closeModal () {
      this.showModal = false
    },

    onDelete () {
      for (let segment of this.selection.segments) {
        this.removeArea(segment)
      }
    },

    onSetSize (area, newSize) {
      this.setLeasedSize({areaId: area.uid, newSize: parseFloat(newSize)})
    },

    addSegment (segment) {
      const area = {
        fkLandParcel: segment.landParcelId,
        groundpoints: segment.groundpoints,
        landParcelIdentifier: `${segment.localDistrictName}, ${segment.cadastralDistrictNumber}, ${segment.landParcelNumber}`,
        link: `/RealEstate/Company/${this.currentOrgUnit.uid}/Landparcel/${segment.landParcelId}`,
        sizeActual: segment.segmentSize,
        sizeContractual: segment.segmentSize,
        usage: {
          label: segment.usageShortcut,
          text: segment.usageName,
          value: segment.usageId
        }
      }

      this.closeModal()
      this.appendArea(area)
    },

    formatISODate (iso, format) {
      return moment(iso).format(format || 'LL')
    }
  }
}
</script>

<style lang="scss">

.lease-area-container {

  input {
    width: 80px;
    margin-right: 4px;
  }

  .add-button {
    width: 300px;
  }

  .lease-area-container {
    display: flex;
    flex-direction: column;
  }

  .input-field {
    display: flex;

    .form-control {
      padding-top: 0px;
      padding-bottom: 0px;
      height: auto;
      width: 100%;
    }

    .has-feedback .form-control {
      padding-right: 30px;
    }

    .form-control-feedback {
      line-height: inherit;
    }
  }

  .table-container {
    margin-right: 20px;
  }

  .amendments-container {
    display: flex;
    flex-direction: column;
  }
  .amendments-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
}

</style>
