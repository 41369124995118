<template lang="html">
  <canvas ref="canvas" :width="width" :height="height" />
</template>

<script>
import ChartMixin from './ChartMixin'

export default {
  mixins: [
    ChartMixin
  ],
  props: {
    stacked: Boolean,
    horizontal: Boolean
  },
  computed: {
    options () {
      return {
        type: this.horizontal ? 'horizontalBar' : 'bar',
        options: {
          scales: {
            xAxes: [{
              stacked: this.stacked
            }],
            yAxes: [{
              stacked: this.stacked
            }]
          }
        }
      }
    },
    datasets () {
      return this.processedData.map((data, datasetIndex) => ({
        data,
        backgroundColor: this.colors[datasetIndex],
        stack: this.stacked ? 'default' : undefined
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
