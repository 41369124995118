<template>
  <div class="facility-part">
    <div>
      <SimpleTable
        ref="facility-parts-table"
        :columns="facilityPartColumns"
        :data="parts"
        :highlight="highlightSelectedFacilityPart"
      >
        <template slot="yearOfConstruction" slot-scope="{value}">
          <span>{{ value | formatYear }}</span>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedFacilityParts"
        :enable-edit="false"
        :enable-print="false"
        @add="addFacilityPart"
        @delete="deleteFacilityPart"
      />
    </div>

    <template v-if="currentFacilityPart">
      <div class="facility-part-details">
        <TextInput v-model="currentFacilityPart.name">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Name</IxRes>
          </template>
        </TextInput>
        <FacilityPartUsageSelect v-model="currentFacilityPart.type" :label="SR_Common.TypeOfUse" :fields="{text: 'name', value: 'uid'}" />
        <NumericInput
          v-model="size"
          addon="m²"
          format="f2" hide-description
          readonly class="re-numeric"
        >
          <template #label>
            <IxRes>Common.SR_Common.Size</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.dimensionsOuterSurface.enclosedSpace"
          addon="m³"
          format="f2" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.EnclosedSpace</IxRes>
          </template>
        </NumericInput>
      </div>

      <div class="facility-part-outer-dimensions">
        <h4 class="span-3">
          <IxRes>Areas.RealEstate.SR_RealEstate.DimensionsOuterSurface</IxRes>
        </h4>
        <NumericInput
          v-model="currentFacilityPart.dimensionsOuterSurface.length"
          addon="m"
          format="f2" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Common.SR_Common.Length</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.dimensionsOuterSurface.width"
          addon="m"
          format="f2" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Common.SR_Common.Width</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.dimensionsOuterSurface.height1"
          addon="m"
          format="f2" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.EavesHeight</IxRes>
          </template>
        </NumericInput>
      </div>

      <div class="facility-part-details-lower-row">
        <NumericInput
          v-model="currentFacilityPart.yearOfConstruction"
          hide-description
          integer :rule="between(0, 3000)"
          format="f0" class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.YearOfConstruction</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.startOfUsage"
          hide-description
          integer :rule="between(0, 3000)"
          format="f0" class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.StartOfUsage</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.reconstructionYear"
          hide-description
          integer :rule="between(0, 3000)"
          format="f0" class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.ReconstructionYear</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.pricePerCubicMetreDM"
          format="f2"
          addon="DM/m³" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.PricePerCubicMeter</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="currentFacilityPart.evaluationOfFacilityDM"
          format="f2"
          addon="DM" hide-description
          class="re-numeric"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.EvaluationOfFacility</IxRes>
          </template>
        </NumericInput>
      </div>
    </template>

    <template v-show="currentFacilityPart">
      <div class="facility-part-deductibles">
        <div>
          <h4>{{ SR_RealEstate.Deductibles }}</h4>
          <SimpleTable
            ref="deductible-table"
            :columns="deductibleColumns"
            :data="deductibles"
            :highlight="highlightSelectedDeductible"
          />

          <TableButtons
            :selected-items="selectedDeductibles"
            :enable-print="false"
            @add="addDeductible"
            @edit="editDeductible"
            @delete="deleteDeductible"
          />
        </div>
      </div>
    </template>

    <BsModal v-model="showDeductibleModal" :title="deductibleModalTitle">
      <div class="deductibe-modal">
        <NumericInput v-model="editedDeductible.deductibleValue" hide-description>
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.DeductibleValue</IxRes>
          </template>
        </NumericInput>
        <TextInput v-model="editedDeductible.remark">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Remark</IxRes>
          </template>
        </TextInput>
      </div>
      <div slot="footer">
        <IxButton save large @click="saveDeductible" />
        <IxButton cancel large @click="cancelDeductibleModal" />
      </div>
    </BsModal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import newGuid from '@helpers/new-guid'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import BsModal from '@components/bootstrap/BsModal'
import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import FacilityPartUsageSelect from '@realestate/components/facility/FacilityPartUsageSelect'
import TableButtons from '@realestate/components/common/TableButtons'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import {formatYear} from '@realestate/filters/numberFormatters'

export default {
  components: {
    BsModal,
    FacilityPartUsageSelect,
    IxButton,
    SimpleTable,
    NumericInput,
    TableButtons,
    TextInput
  },

  filters: {
    formatYear
  },

  mixins: [
    makeSelectionMixin('facility-parts-table'),
    makeSelectionMixin('deductible-table'),
    StandardRulesMixin
  ],

  data () {
    return {
      showDeductibleModal: false,
      deductibleModalTitle: null,
      deductibleValue: null,
      deductibleRemark: null,
      selectedPartsChangedInternally: false,
      editedDeductible: {
        deductibleValue: null,
        remark: null
      },

      highlightSelectedFacilityPart: (row) => {
        if (this.selectedFacilityParts.includes(row)) {
          return ['success']
        }
        return []
      },

      highlightSelectedDeductible: (row) => {
        if (this.selectedDeductibles.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common',
      'ModelProperties.SR_ModelProperties'

    ]),

    ...mapFormFields('realEstate/facilities', [
      'current.parts'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit'
    ]),

    selectedFacilityParts: {
      get () {
        return this.selection['facility-parts-table']
      },
      set (value) {
        this.selection['facility-parts-table'] = [...value]
      }
    },

    selectedDeductibles: {
      get () {
        return this.selection['deductible-table']
      },
      set (value) {
        this.selection['deductible-table'] = [...value]
      }
    },

    facilityPartColumns () {
      return {
        name: {
          title: this.SR_ModelProperties.Name
        },
        type: {
          title: this.SR_ModelProperties.Type
        },
        yearOfConstruction: {
          title: this.SR_RealEstate.YearOfConstruction
        },
        dimensionsOuterSurfaceToDisplay: {
          title: this.SR_ModelProperties.DimensionsOuterSurfaceToDisplay
        }
      }
    },

    deductibleColumns () {
      return {
        deductibleValue: {
          title: this.SR_ModelProperties.DeductibleValue
        },
        remark: {
          title: this.SR_ModelProperties.Remark
        }
      }
    },

    deductibles () {
      return this.currentFacilityPart ? this.currentFacilityPart.deductibles : []
    },

    currentFacilityPart () {
      if (this.selectedFacilityParts[0]) {
        const currentFacilityPart = this.selectedFacilityParts[0]
        currentFacilityPart.yearOfConstruction = formatYear(currentFacilityPart.yearOfConstruction)
        currentFacilityPart.startOfUsage = formatYear(currentFacilityPart.startOfUsage)
        currentFacilityPart.reconstructionYear = formatYear(currentFacilityPart.reconstructionYear)
        return currentFacilityPart
      } else return null
    },

    size () {
      return this.currentFacilityPart ? this.currentFacilityPart.dimensionsOuterSurface.width * this.currentFacilityPart.dimensionsOuterSurface.length : null
    }
  },

  methods: {

    makeNewFacilityPart () {
      return {
        deductibles: [],
        dimensionsOuterSurface: {
          enclosedSpace: null,
          height1: null,
          height2: null,
          length: null,
          width: null
        },
        dimensionsOuterSurfaceToDisplay: null,
        evaluationOfFacilityDM: null,
        fkFacilityId: null,
        name: '',
        pricePerCubicMetreDM: null,
        reconstructionYear: null,
        startOfUsage: null,
        type: null,
        typeName: null,
        uid: null,
        yearOfConstruction: null
      }
    },

    addFacilityPart () {
      let newFacilityPart = this.makeNewFacilityPart()
      this.parts.push(newFacilityPart)
      let index = this.parts.indexOf(newFacilityPart)
      this.selectedFacilityParts = [this.parts[index]]
      this.selectedPartsChangedInternally = true
    },

    deleteFacilityPart () {
      let index = this.parts.indexOf(this.currentFacilityPart)
      this.parts.splice(index, 1)
      this.selectedFacilityParts = []
      this.selectedPartsChangedInternally = true
    },

    addDeductible () {
      let newDeductible = {
        uid: null,
        deductibleValue: null,
        fkFacilityPart: null,
        remark: null
      }
      this.editedDeductible = newDeductible
      this.deductibleModalTitle = this.SR_Common.AddEntry
      this.showDeductibleModal = true
    },

    editDeductible () {
      this.editedDeductible = this.selectedDeductibles[0]
      this.deductibleModalTitle = this.SR_Common.EditEntry
      this.showDeductibleModal = true
    },

    saveDeductible () {
      if (this.editedDeductible.uid) {
        this.selectedDeductibles[0] = this.editedDeductible
      } else {
        this.editedDeductible.uid = newGuid()
        this.editedDeductible.fkFacilityPart = this.currentFacilityPart.uid
        this.deductibles.push(this.editedDeductible)
      }
      this.showDeductibleModal = false
    },

    cancelDeductibleModal () {
      this.showDeductibleModal = false
    },

    deleteDeductible () {
      let index = this.deductibles.indexOf(this.selectedDeductibles[0])
      this.deductibles.splice(index, 1)
      this.selectedDeductibles = []
    }
  },

  watch: {
    parts () {
      if (this.selectedPartsChangedInternally) {
        this.selectedPartsChangedInternally = false
      } else {
        this.selectedFacilityParts = this.parts ? [this.parts[0]] : []
      }
    },
    currentFacilityPart () {
      this.selectedDeductibles = []
    }
  },

  mounted () {
    this.selectedFacilityParts = this.parts ? [this.parts[0]] : []
  }
}

</script>

<style lang="scss">

  @import "../../styles/_grid";

  .facility-part {
    @include makeGrid(1);
  }

  .facility-part-details {
    @include makeGrid(2);
    margin-bottom: 15px;
  }

  .facility-part-details-lower-row {
    @include makeGrid(3);
    margin-bottom: 10px;
    h4

  {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  }

  .facility-part-outer-dimensions {
    @include makeGrid(3);
    margin-bottom: 10px;
    h4

  {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  }

  .facility-part-deductibles {
    @include makeGrid(1);
    margin-bottom: 10px;
    h4

  {
    margin-top: 0px;
  }

  }

  .deductibe-modal {
    @include makeGrid(1);
    h4

  {
    margin-bottom: -5px;
  }
  }
</style>
