import Chart from 'chart.js'
import 'chartjs-plugin-labels'
import {mergeWith} from 'lodash'
import {format} from 'src/js/i18n/conversion'

const defaultColors = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56'
]

export default {
  props: {
    width: Number,
    height: Number,
    data: Array, // {label, value}
    percent: Boolean,
    legend: Boolean,
    noArea: {
      type: Boolean,
      default: false
    },
    tooltipCallbacks: {
      type: Object, // NOTE deprecated, move to additionalOptions
      default: () => {
        return {
          label: (tooltipItem, data) => {
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            const label = data.labels[tooltipItem.index]
            let sum = 0
            data.datasets[tooltipItem.datasetIndex].data.forEach(val => {
              sum += val
            })

            const mainLabel = Array.isArray(label) ? label[0] : label
            const additionalLabels = Array.isArray(label) ? label.slice(1) : []
            return [mainLabel, data.noArea ? '' : `${format(value)} ha`, `${format(value / sum * 100)} %`, ...additionalLabels].filter(x => x !== '')
          }
        }
      }
    },
    colorFunction: Function, // ({dataItem, dataIndex, datasetIndex}) => <colorlike>
    additionalOptions: Object,
    plugins: {
      type: Array,
      default: () => []
    },
    labelPluginOptions: [Object, Array], // help: https://github.com/emn178/chartjs-plugin-labels // NOTE deprecated, move to additionalOptions
    multidata: Boolean
  },
  computed: {
    normalizedData () {
      return this.data[0] && Array.isArray(this.data[0])
        ? this.data
        : [this.data]
    },
    processedData () {
      return this.normalizedData.map(dataset => dataset.map(x => x.value))
    },
    datasets () {
      return this.processedData.map((data, datasetIndex) => ({
        data,
        backgroundColor: this.colors[datasetIndex]
      }))
    },
    colors () {
      const colorFunction = this.colorFunction || this.defaultColorFunction

      return this.normalizedData.map((data, datasetIndex) => {
        return data.map((dataItem, dataIndex) => colorFunction({dataIndex, dataItem, datasetIndex}))
      })
    },
    labels () {
      return this.multidata
        ? this.normalizedData.map(dataset => dataset.map(dataItem => dataItem.label))
        : this.normalizedData[0].map(dataItem => dataItem.label)
    },
    legendLables () {
      return {
        generateLabels: function (chart) {
          return chart.data.labels.map((label, i) => {
            return {
              text: label,
              fillStyle: chart.data.datasets[0].backgroundColor[i]
            }
          })
        }
      }
    }
  },
  methods: {
    defaultColorFunction ({dataIndex, dataItem}) {
      return dataItem.color || defaultColors[dataIndex % (defaultColors.length)]
    }
  },
  watch: {
    data () {
      if (this.datasets.length === this.chart.data.datasets.length) {
        this.datasets.forEach((dataset, i) => Object.assign(this.chart.data.datasets[i], dataset))
      } else {
        this.chart.data.datasets = this.datasets
      }
      this.chart.data.labels = [...this.labels]
      this.chart.options.legend.labels = this.legendLables
      this.chart.data.noArea = this.noArea
      this.chart.update()
    },
    height (value) {
      this.chart.canvas.height = value
      this.chart.canvas.style.height = value + 'px'
      this.chart.render()
    }
  },
  beforeUpdate () {
    this.chart.unbindEvents()
  },
  updated () {
    this.chart.bindEvents()
  },
  beforeDestroy () {
    this.chart.destroy()
  },
  mounted () {
    const defaultOptions = {
      data: {
        labels: [...this.labels],
        datasets: this.datasets,
        noArea: this.noArea
      },
      options: {
        legend: {
          display: this.legend,
          position: 'right',
          labels: this.legendLables
        },
        tooltips: {
          callbacks: this.tooltipCallbacks || {}
        },
        onClick: (event, activeElements) => {
          this.$emit('click', activeElements.map(element => this.data[element._datasetIndex][element._index]))
        },
        plugins: {
          labels: false
        }
      },
      plugins: [
        ...this.plugins
      ]
    }

    if (this.labelPluginOptions) {
      defaultOptions.options.plugins.labels = this.labelPluginOptions
    }

    const percentOptions = this.percent
      ? {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                callback: value => `${this.$i18n.format(value, 'percent')}`
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                return `${this.$i18n.format(value, 'percent')}`
              }
            }
          }
        }
      }
      : {}

    const options = mergeWith(defaultOptions, percentOptions, this.options, {options: this.additionalOptions})

    this.chart = new Chart(this.$refs.canvas, options)
  }
}
