<template lang="html">
  <div class="search-navigation">
    <hr>
    <ListCard
      v-for="(node, i) in searchResult"
      :key="i"
      :title="node.title"
      @click.native="selectNode(node)"
    />
  </div>
</template>

<script>

import ListCard from 'src/vue/components/card/ListCard'

export default {
  components: {
    ListCard
  },

  props: {
    searchResult: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
    }
  },

  methods: {
    selectNode (node) {
      this.$emit('input', node)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
