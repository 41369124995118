<template>
  <div>
    <div class="facility-common">
      <TextInput v-model="name">
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Name</IxRes>
        </template>
      </TextInput>
      <FacilityUsageSelect v-model="type" :label="SR_RealEstate.FacilityType" :fields="{text: 'name', value: 'uid'}" />
      <VueSelectize
        v-model="property"
        allow-empty :options="propertyOptionsExtended"
        :fields="{text: 'name', value: 'uid', label: 'assignedOrgUnitName'}"
        @type="updatePropertyOptions"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Property</IxRes>
          <a target="_blank" :href="propertyLink" data-ajax="false">
            <span class="glyphicon glyphicon-circle-arrow-right" />
          </a>
        </template>
      </VueSelectize>
      <UsePotentialSelect v-model="potentialForUse" :label="SR_RealEstate.PotentialForUse" :fields="{text: 'name', value: 'uid'}" />
      <VueSelectize
        v-model="photovoltaicSystemState"
        allow-empty
        :options="photovoltaicSystemStateOptions"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.PhotovoltaicSystemState</IxRes>
        </template>
      </VueSelectize>
      <TextInput v-model="region" disabled>
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Region</IxRes>
        </template>
      </TextInput>
      <TextInput
        v-model="remark"
        multiline
        class="span-2"
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Remark</IxRes>
        </template>
      </TextInput>

      <div class="span-2">
        <SimpleTable
          ref="dms-reference-table"
          :columns="columnDefinition"
          :data="dmsReferences"
          :highlight="selected"
        />
        <TableButtons
          :selected-items="selectedDmsEntries"
          :enable-print="false"
          @add="addDmsEntry"
          @edit="editDmsEntry"
          @delete="deleteDmsEntry"
        />
      </div>
    </div>

    <BsModal v-model="showDmsModal">
      <template #title>
        <IxRes>Areas.RealEstate.SR_RealEstate.DMSEntry</IxRes>
      </template>
      <div class="dms-reference-modal">
        <VueSelectize
          v-model="dmsEntry.type"
          :options="dmsReferenceTypeOptions" allow-empty
          :fields="{text: 'name', value: 'uid'}"
          @type="updateDmsReferenceOptions"
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Type</IxRes>
          </template>
        </VueSelectize>
        <TextInput v-model="dmsEntry.reference">
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.DMSEntry</IxRes>
          </template>
        </TextInput>
        <TextInput v-model="dmsEntry.referenceLink">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.ReferenceLink</IxRes>
          </template>
        </TextInput>
      </div>
      <div slot="footer">
        <IxButton save large @click="saveDmsEntry" />
        <IxButton cancel large @click="cancelDmsModal" />
      </div>
    </BsModal>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

import newGuid from '@helpers/new-guid'
import {mapFormFields, mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import BsModal from '@components/bootstrap/BsModal'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'

import FacilityUsageSelect from '@realestate/components/facility/FacilityUsageSelect'
import UsePotentialSelect from '@realestate/components/facility/UsePotentialSelect'
import TableButtons from '@realestate/components/common/TableButtons'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {
  components: {
    BsModal,
    FacilityUsageSelect,
    IxButton,
    SimpleTable,
    UsePotentialSelect,
    TableButtons,
    TextInput,
    VueSelectize
  },

  mixins: [
    makeSelectionMixin('dms-reference-table')
  ],

  data () {
    return {
      propertyOptions: [],
      photovoltaicSystemStateOptions: [],
      dmsReferenceTypeOptions: [],
      showDmsModal: false,
      dmsEntry: {
        uid: null,
        fkFacilityId: null,
        type: null,
        reference: null,
        referenceLink: null
      },

      selected: (row) => {
        if (this.selectedDmsEntries.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/facilities', [
      'current.uid',
      'current.name',
      'current.type',
      'current.property',
      'current.potentialForUse',
      'current.photovoltaicSystemState',
      'current.region',
      'current.remark',
      'current.dmsReferences'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit'
    ]),

    columnDefinition () {
      return {
        type: {
          title: this.SR_ModelProperties.Type
        },
        reference: {
          title: this.SR_RealEstate.DMSEntry
        },
        referenceLink: {
          title: this.SR_ModelProperties.ReferenceLink
        }
      }
    },

    propertyLink () {
      if (this.property) {
        return 'RealEstate/Company/' + this.property.assignedOrgUnitId + '/Property/' + this.property.uid
      } else {
        return ''
      }
    },

    selectedDmsEntries () {
      return this.selection['dms-reference-table']
    },

    propertyOptionsExtended () {
      return this.property ? [this.property, ...this.propertyOptions] : this.propertyOptions
    }
  },

  methods: {
    addDmsEntry () {
      this.showDmsModal = true
      this.dmsEntry = {type: null, reference: null, referenceLink: null}
    },

    editDmsEntry () {
      this.showDmsModal = true
      this.dmsEntry = this.selectedDmsEntries[0]
    },

    deleteDmsEntry () {
      let index = this.dmsReferences.indexOf(this.selectedDmsEntries[0])
      this.dmsReferences.splice(index, 1)
    },

    saveDmsEntry () {
      if (this.dmsEntry.uid) { // dmsEntry was edited and not newly created
        let selectionIndex = this.dmsReferences.indexOf(this.selectedDmsEntries[0])
        this.dmsReferences[selectionIndex] = this.dmsEntry
      } else {
        this.dmsEntry.uid = newGuid()
        this.dmsEntry.fkFacilityId = this.uid
        this.dmsReferences.push(this.dmsEntry)
      }
      this.showDmsModal = false
    },

    cancelDmsModal () {
      this.showDmsModal = false
    },

    updatePropertyOptions (input) {
      axios.get('RealEstate/Property/GetPropertiesWithAssignedOrgUnit', {params: {entityId: this.currentOrgUnit.uid, query: input}})
        .then(result => {
          this.propertyOptions = result.data
        })
    },

    updateDmsReferenceOptions (input) {
      axios.get('RealEstate/Facility/GetDmsReferenceTypes', {params: {query: input}})
        .then(result => {
          this.dmsReferenceTypeOptions = []
          result.data.forEach(reference => this.dmsReferenceTypeOptions.push({name: reference.label, uid: reference.value}))
        })
    },

    loadPhotovoltaicSystemStateOptions () {
      axios.get('RealEstate/Facility/GetPhotovoltaicSystemStates')
        .then(result => {
          result.data.forEach(option => this.photovoltaicSystemStateOptions.push({text: option.displayName, value: option.value}))
        })
    }
  },

  mounted () {
    this.$on('selected', this.onSelected)
    this.updatePropertyOptions('')
    this.updateDmsReferenceOptions('')
    this.loadPhotovoltaicSystemStateOptions()
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.facility-common {
  @include makeGrid(2);
}

.dms-reference-modal {
  @include makeGrid(1);
}

</style>
