<template>
  <div class="select-create">
    <VueSelectize
      v-model="currentSelection"
      :options="selectizeOptions"
      :fields="fields"
      :label="label"
      :create="allowCreate ? onCreate : false"
      allow-empty
      @type="onType"
    >
      <slot slot="label" name="label" />
    </VueSelectize>

    <BsModal
      v-if="newItem"
      :value="isDialogOpen"
      :title="title"
      :create="onCreate"
      blocking
      @hidden="closeDialog"
    >
      <template slot="footer">
        <IxButton save large @click="onSave" />
        <IxButton cancel large @click="onCancel" />
      </template>

      <slot :value="newItem" />
    </BsModal>
  </div>
</template>

<script>

import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'

export default {

  components: {
    BsModal,
    IxButton,
    VueSelectize
  },

  props: {
    title: String,
    label: String,
    options: Array,
    fields: Object,
    value: {type: Object, default: null},
    makeNewItem: Function,
    allowCreate: {type: Boolean, default: false}
  },

  data () {
    return {
      isDialogOpen: false,
      callback: null,
      newItem: null
    }
  },

  computed: {
    selectizeOptions () {
      return this.value ? [this.value, ...this.options] : this.options
    },

    currentSelection: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    onCreate (input, callback) {
      this.callback = callback
      this.$emit('createStarted', input)
      this.newItem = this.makeNewItem(input)
      this.openDialog()
      return undefined
    },

    onType (value) {
      this.$emit('type', value)
    },

    onSave () {
      this.callback(this.newItem)

      this.$emit('save', this.newItem)

      const _this = this

      if (this.newItem.action) {
        this.newItem.action.then((newItem) => {
          _this.currentSelection = newItem
        })
      } else {
        this.currentSelection = this.newItem
      }

      this.closeDialog()
    },

    onCancel () {
      this.callback(undefined)
      this.$emit('cancel')
      this.closeDialog()
    },

    openDialog () {
      this.isDialogOpen = true
      this.$emit('dialogOpened')
    },

    closeDialog () {
      this.newItem = null
      this.callback = null
      this.isDialogOpen = false
      this.$emit('dialogClose')
    }
  }
}

</script>
