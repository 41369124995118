<template>
  <div>
    <SimpleTable
      ref="terms"
      :columns="columnDefinition"
      :data="rows"
      :highlight="selected"
    >
      <template slot="leasePrice" slot-scope="{value}">
        <div class="pull-right">
          {{ value | formatAsMoney }}
        </div>
      </template>

      <template slot="currency" slot-scope="{value}">
        <span>{{ value.uid | formatAsCurrency }}</span>
      </template>
    </SimpleTable>

    <TableButtons
      :selected-items="selection.terms"
      :enable-print="false"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    />

    <BsModal
      v-if="editedTerm"
      v-model="showModal"
    >
      <template #title>
        <IxRes>Areas.RealEstate.SR_RealEstate.{{ termModalTitle }}</IxRes>
      </template>
      <div class="term-modal-content">
        <VueDateTimePicker
          v-model="editedTerm.start"
          iso
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Start</IxRes>
          </template>
        </VueDateTimePicker>

        <VueDateTimePicker
          v-model="editedTerm.end"
          iso
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.End</IxRes>
          </template>
        </VueDateTimePicker>

        <NumericInput
          v-model="editedTerm.leasePrice"
          format="f2"
          hide-description
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.LeasePrice</IxRes>
          </template>
        </NumericInput>

        <VueSelectize
          v-model="editedTerm.currency"
          :options="availableCurrencies"
          :fields="currencyFields"
          allow-empty
          class="currency-selectize"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
          </template>
        </VueSelectize>
      </div>

      <TextInput v-model="editedTerm.remark" multiline>
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Remark</IxRes>
        </template>
      </TextInput>

      <div slot="footer">
        <IxButton
          icon="check" save
          large
          @click="saveTerm"
        >
          <IxRes>Common.SR_Common.Apply</IxRes>
        </IxButton>
        <IxButton cancel large @click="cancelModal" />
      </div>
    </BsModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import newGuid from '@helpers/new-guid'
import {mapResources, mapFormFields} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'

import BsModal from '@components/bootstrap/BsModal'

import {formatAsCurrency, formatAsMoney} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import TableButtons from '@realestate/components/common/TableButtons'

export default {

  components: {
    BsModal,
    IxButton,
    SimpleTable,
    NumericInput,
    TableButtons,
    TextInput,
    VueSelectize,
    VueDateTimePicker
  },

  filters: {
    formatAsCurrency,
    formatAsMoney
  },

  mixins: [
    makeSelectionMixin('terms')
  ],

  data () {
    return {
      showModal: false,

      termModalTitle: null,
      currencyFields: {text: 'short', value: 'uid'},

      editedTerm: null,

      selected: (row) => {
        return this.selection.terms.includes(row) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapGetters('realEstate', [
      'availableCurrencies'
    ]),

    ...mapFormFields('realEstate/lease', [
      'current.paymentRecipients',
      'current.payments',
      'current.terms'
    ]),

    columnDefinition () {
      return {
        start: {
          title: this.SR_ModelProperties.Start,
          type: 'date'
        },
        end: {
          title: this.SR_ModelProperties.End,
          type: 'date'
        },
        remark: {
          title: this.SR_ModelProperties.Remark
        },
        leasePrice: {
          title: this.SR_ModelProperties.LeasePrice
        },
        currency: {
          title: this.SR_ModelProperties.Currency
        }
      }
    },

    rows () {
      return this.terms || []
    }
  },

  methods: {

    ...mapMutations('realEstate/lease', [
      'appendTerm',
      'removeTerm',
      'updateTerm'
    ]),

    onAdd () {
      this.termModalTitle = 'CreateTerm'
      this.editedTerm = {
        uid: null,
        start: null,
        end: null,
        remark: null,
        leasePrice: null,
        currency: null
      }
      this.showModal = true
    },

    onEdit () {
      this.termModalTitle = 'EditTerm'
      this.editedTerm = this.selection.terms[0]
      this.showModal = true
    },

    onModalCancel () {
      this.showModal = false
    },

    saveTerm () {
      if (!this.editedTerm.uid) {
        this.editedTerm.uid = newGuid()
        this.appendTerm(this.editedTerm)
      } else {
        this.updateTerm(this.editedTerm)
      }
      this.selection.terms = [this.editedTerm]
      this.closeModal()
    },

    cancelModal () {
      this.showModal = false
    },

    closeModal () {
      this.editedTerm = null
      this.showModal = false
    },

    onDelete () {
      for (let term of this.selection.terms) {
        this.removeTerm(term)
      }
    }
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.term-modal-content {
  @include makeGrid(2);
}
</style>
