import {format} from 'src/js/i18n/conversion'
import moment from 'moment'

export function formatAsHectare (value) {
  return format(value, 'f4')
}

export function formatAsCurrency (value) {
  switch (value) {
  case '346c8459-bfb3-4054-ad9c-e08cd17792fe': return '€'
  case '207e1730-0bcf-4414-85d2-55ca59157d97': return 'DM'
  case 'bb241df1-6353-453b-a804-106da9823b1c': return '$'
  default: return ''
  }
}

export function formatDate (value) {
  return moment(value).format('L')
}

export function formatAsMoney (value) {
  return format(value, 'f2')
}

export function formatYear (value) {
  return value ? moment(value).year() : null
}
