<script>
import axios from 'axios'
import {debounce} from 'lodash'

import {unByKey} from 'ol/Observable'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {transformExtent} from 'ol/proj'

import {bbox} from 'ol/loadingstrategy'

import {defaultAttribution} from '@helpers/openlayers/layers'
import {geoJsonFormat} from '@helpers/openlayers/features'

export default {
  inject: [
    'getMap'
  ],
  props: {
    featureStyle: Function,
    dataForLoading: null
  },
  data () {
    return {
      zoom: null,
      lastZoom: null,

      handleZoomDebounced: debounce(function () {
        // if (!this.layer || (this.zoom > this.maxZoomForCluster && this.lastZoom <= this.maxZoomForCluster) || (this.zoom < this.maxZoomForCluster && this.lastZoom >= this.maxZoomForCluster)) {
        this.recreate()
        // }
        this.lastZoom = this.zoom
        this.$emit('zoom', this.zoom)
      }, 100)

    }
  },

  methods: {

    recreate () {
      if (this.layer) {
        this.map.getLayers().remove(this.layer)
        this.layer = null
      }

      let orgUnit = this.dataForLoading.orgUnit
      let ids = this.dataForLoading.ids
      let url = this.dataForLoading.url
      let featureType = this.dataForLoading.featureType

      let vectorSource = new VectorSource({
        attributions: [defaultAttribution],
        format: geoJsonFormat,
        loader: function (extent, resolution) {
          let data = {
            orgUnit: orgUnit,
            type: featureType,
            ids: ids,
            extent: transformExtent(extent, 'EPSG:3857', 'EPSG:4326'),
            res: resolution
          }

          axios.post(url, data)
            .then(result => {
              if (result.data.features) {
                var features = vectorSource.getFormat().readFeatures(result.data, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
                // vectorSource.clear()
                vectorSource.addFeatures(features)
              }
            })
        },
        strategy: bbox
      })

      this.layer = new VectorLayer({
        source: vectorSource,
        style: this.featureStyle,
        renderMode: 'image'
      })
      this.map.getLayers().push(this.layer)
    }

  },
  watch: {
    zoom () {
      this.handleZoomDebounced()
    },
    dataForLoading (value) {
      if (value) this.recreate()
    }
  },

  updated () {
    if (this.map) {
      this.recreate()
    }
  },

  mounted () {
    this.getMap().then(map => {
      this.map = map
      this.zoom = this.map.getView().getZoom()

      this.zoomListenerKey = this.map.getView().on('change:resolution', () => {
        this.zoom = this.map.getView().getZoom()
      })
      this.recreate()
    })
  },

  beforeDestroy () {
    if (this.layer) {
      this.map.getLayers().remove(this.layer)
    }
    unByKey(this.zoomListenerKey)
  },

  render () {
    return null
  }
}
</script>

<style lang="scss" scoped>

</style>
