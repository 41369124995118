<template>
  <div class="common-panel">
    <BsCheckbox v-model="isRentOut">
      <IxRes>Areas.RealEstate.SR_RealEstate.IsRentOut</IxRes>
    </BsCheckbox>

    <TextInput
      v-model="certificateNumber"
      required
      class="column-1"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.CertificateNumber</IxRes>
      </template>
    </TextInput>

    <VueDateTimePicker
      v-model="contractDate"
      iso
      required
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.ContractDate</IxRes>
      </template>
    </VueDateTimePicker>

    <BusinessObjectSelect
      v-model="leaseObject"
      :label="SR_RealEstate.BusinessObject"
      :modal-title="SR_RealEstate.BusinessObject"
      class="column-1"
    />

    <VueDateTimePicker
      :value="terms ? terms[0].start : null"
      readonly
      iso
      class="column-1"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.TermFrom</IxRes>
      </template>
    </VueDateTimePicker>

    <VueDateTimePicker
      :value="terms ? terms[terms.length - 1].end : null"
      readonly
      iso
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.TermTo</IxRes>
      </template>
    </VueDateTimePicker>

    <div class="amendments-container span-2">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.Amendments</IxRes>
      </h4>
      <div class="amendments-list">
        <ToggleButton
          v-for="(amendment, index) in amendments" :key="index"
          :value="currentAmendment"
          :toggle-value="amendment"
          large
          @input="setCurrentAmendment(amendment)"
        >
          <Slot>
            {{ formatISODate(amendment.validOn) }}
          </Slot>
        </ToggleButton>
      </div>
    </div>

    <div>
      <label>
        <IxRes>ModelProperties.SR_ModelProperties.Lessor</IxRes>
      </label>
      <div v-for="(lessor, index) in lessors" :key="lessor.value">
        <div class="person-row">
          <ContactSelect v-model="lessors[index]" contact-type="Other" />
          <button :disabled="lessors.length < 2" class="btn btn-default" @click="removeLessor(lessor)">
            <i class="fa fa-trash" />
          </button>
        </div>
      </div>
      <a class="small" @click="addLessor">
        <IxRes>Areas.RealEstate.SR_RealEstate.AddLessor</IxRes>
      </a>
    </div>

    <div>
      <label>
        <IxRes>ModelProperties.SR_ModelProperties.Leaser</IxRes>
      </label>
      <div v-for="(leaser, index) in leasers" :key="leaser.value">
        <div class="person-row">
          <ContactSelect v-model="leasers[index]" contact-type="Other" />
          <button :disabled="leasers.length < 2" class="btn btn-default" @click="removeLeaser(leaser)">
            <i class="fa fa-trash" />
          </button>
        </div>
      </div>
      <a class="small" @click="addLeaser">
        <IxRes>Areas.RealEstate.SR_RealEstate.AddLeaser</IxRes>
      </a>
    </div>

    <div>
      <BsCheckbox v-model="isExchangeAllowed">
        <IxRes>ModelProperties.SR_ModelProperties.IsExchangeAllowed</IxRes>
      </BsCheckbox>
      <BsCheckbox v-model="isWaterPaidByLeaser">
        <IxRes>ModelProperties.SR_ModelProperties.IsWaterPaidByLeaser</IxRes>
      </BsCheckbox>
      <BsCheckbox v-model="isTaxPaidByLeaser">
        <IxRes>ModelProperties.SR_ModelProperties.IsTaxPaidByLeaser</IxRes>
      </BsCheckbox>
    </div>

    <div class="contract-terms">
      <h4 class="span-2">
        <IxRes>Areas.RealEstate.SR_RealEstate.AutoRenewalAndCancellation</IxRes>
      </h4>

      <BsCheckbox v-model="autoRenew">
        <IxRes>ModelProperties.SR_ModelProperties.AutoRenew</IxRes>
      </BsCheckbox>

      <NumericInput
        v-model="autoRenewalPeriod"
        :addon="SR_Common.Months"
        integer
        hide-description
        class="column-1 re-numeric"
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.AutoRenewalPeriod</IxRes>
        </template>
      </NumericInput>

      <NumericInput
        v-model="cancellationPeriod"
        :addon="SR_Common.Months"
        integer
        hide-description
        class="re-numeric"
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.CancellationPeriod</IxRes>
        </template>
      </NumericInput>
    </div>

    <TextInput
      v-model="comments"
      multiline
      class="span-2"
    >
      <template #label>
        <IxRes>Common.SR_Common.Remarks</IxRes>
      </template>
    </TextInput>

    <div class="dms">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.DmsReferences</IxRes>
      </h4>
      <SimpleTable
        ref="leaseDmsReferencesTable"
        :data="dmsReferences || []"
        :columns="dmsColumnDefinition"
        :highlight="highlightSelectedDMSReference"
      >
        <template slot="type" slot-scope="{value}">
          <span>{{ value.text }}</span>
        </template>
        <template slot="reference" slot-scope="{value}">
          <span>
            {{ value }}
            <!-- <a :href="value" target="_blank">{{ value }}</a> -->
          </span>
        </template>
      </SimpleTable>
      <TableButtons
        :selected-items="selectedDmsItems"
        :enable-print="false"
        @add="addDMSReference"
        @edit="editDMSReference"
        @delete="removeDMSReference"
      />
    </div>

    <BsModal v-model="dms.isModalOpen" :title="'DMS Reference'">
      <div>
        <VueSelectize
          v-model="dms.currentItem.type"
          :fields="{text: 'text', value: 'value'}"
          :options="dmsReferenceTypeOptions"
          :default="dmsReferenceTypeOptions[0]"
          allow-empty
          required
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Type</IxRes>
          </template>
        </VueSelectize>

        <TextInput
          v-model="dms.currentItem.name"
          required
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Reference</IxRes>
          </template>
        </TextInput>

        <TextInput v-model="dms.currentItem.reference">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.ReferenceLink</IxRes>
          </template>
        </TextInput>
      </div>
      <div slot="footer">
        <IxButton save large @click="saveDMSReference" />
        <IxButton cancel large @click="cancelDMSModal" />
      </div>
    </BsModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

import {mapResources, mapFormFields} from '@helpers/vuex'

import ToggleButton from '@components/ToggleButton'
import VueSelectize from '@components/VueSelectize'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import BsModal from '@components/bootstrap/BsModal'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'
import TableButtons from '@realestate/components/common/TableButtons'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'

import BusinessObjectSelect from '../business-object/BusinessObjectSelect'
import ContactSelect from '../contacts/ContactSelect'

export default {

  components: {
    ToggleButton,
    VueSelectize,
    BsCheckbox,
    BsModal,
    NumericInput,
    TextInput,
    VueDateTimePicker,
    TableButtons,
    IxButton,
    SimpleTable,
    BusinessObjectSelect,
    ContactSelect
  },

  mixins: [
    makeSelectionMixin('leaseDmsReferencesTable')
  ],

  data () {
    return {
      selectedAmendmentIndex: 0,

      dms: {
        selectedItems: [],
        isModalOpen: false,
        currentItem: {
          uid: null,
          type: null,
          name: null,
          reference: null
        }
      }
    }
  },

  computed: {
    ...mapResources([
      'Common.SR_Common',
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapGetters('realEstate', [
      'availableDmsReferenceTypes'
    ]),

    ...mapFormFields('realEstate/lease', [
      'current',
      'current.amendments',
      'current.autoRenew',
      'current.autoRenewalPeriod',
      'current.cancellationPeriod',
      'current.certificateNumber',
      'current.comments',
      'current.contractDate',
      'current.dmsReferences',
      'current.isRentOut',
      'current.leaseObject',
      'current.terms',

      'currentAmendment',
      'currentAmendment.hasAreaChages',
      'currentAmendment.isExchangeAllowed',
      'currentAmendment.isStart',
      'currentAmendment.isTaxPaidByLeaser',
      'currentAmendment.isWaterPaidByLeaser',
      'currentAmendment.leaser',
      'currentAmendment.lessor',
      'currentAmendment.uid',
      'currentAmendment.validon'
    ]),

    leasers () {
      return this.currentAmendment ? this.leaser : []
    },

    lessors () {
      return this.currentAmendment ? this.lessor : []
    },

    dmsReferenceTypeOptions () {
      return this.availableDmsReferenceTypes
        .map(t => {
          return {text: t.name, value: t.uid}
        })
    },

    dmsColumnDefinition () {
      return {
        type: {
          title: this.SR_ModelProperties.Type
        },
        name: {
          title: this.SR_ModelProperties.Reference
        },
        reference: {
          title: this.SR_ModelProperties.ReferenceLink
        }
      }
    },

    selectedDmsItems () {
      return this.selection.leaseDmsReferencesTable
    }
  },

  methods: {
    ...mapMutations('realEstate/lease', [
      'setCurrentAmendment',
      'appendLeaser',
      'removeLeaser',
      'appendLessor',
      'removeLessor',
      'appendDmsReference',
      'removeDmsReference',
      'updateDmsReference'
    ]),

    addLessor () {
      this.appendLessor({})
    },

    addLeaser () {
      this.appendLeaser({})
    },

    selectDMSReference (items) {
      this.dms.selectedItems = items
    },

    addDMSReference () {
      this.dms.currentItem = {
        uid: null,
        type: null, // { text: null, value: null, label: null },
        name: null,
        reference: null
      }

      this.dms.isModalOpen = true
    },

    editDMSReference () {
      this.dms.currentItem = JSON.parse(JSON.stringify(this.selectedDmsItems[0]))
      this.dms.isModalOpen = true
    },

    removeDMSReference () {
      const index = this.dmsReferences.indexOf(this.selectedDmsItems[0])

      this.dmsReferences.splice(index, 1)
      this.dms.selectedItems = []
    },

    highlightSelectedDMSReference (item) {
      if (item && this.selectedDmsItems.includes(item)) {
        return ['success']
      }
      return []
    },

    saveDMSReference () {
      if (!this.dms.currentItem.uid) {
        this.appendDmsReference(this.dms.currentItem)
      } else {
        this.updateDmsReference(this.dms.currentItem)
      }
      this.dms.isModalOpen = false
    },

    cancelDMSModal () {
      this.dms.currentItem = null
      this.dms.isModalOpen = false
    },

    parseISODate (iso) {
      return moment(iso)
    },
    formatISODate (iso, format) {
      return moment(iso).format(format || 'LL')
    }
  },

  watch: {
    current (value) {
      if (value && value.amendments.length > 0) {
        this.setCurrentAmendment(value.amendments[0])
      }
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.common-panel {

  @include makeGrid(2);

  .person-row
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  .contact-selector

{
  flex: 1 1 auto;
  margin-right: 2px;
}

.btn {
  flex: 0 0 auto;
}

}

.contract-terms {
  @extend .span-2;
  @include makeGrid(2);

  margin-top: 10px;

  h4 {
        margin-top: 0px;
        margin-bottom: 0px;
      }
  }

.amendments-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .amendments-list {
    display: flex;
    flex-direction: row;
  }
}

.dms {
  @extend .span-2;
  margin-bottom: 10px;

  h4 {
        margin {
        top: 0px;
        bottom: 0px;
        }
      }
  }
}

</style>
