<template>
  <ContractAggregationView />
</template>

<script>

import ContractAggregationView from './ContractAggregationView'

export default {

  components: {
    ContractAggregationView
  }

}

</script>
