<template>
  <div class="facility-insurance">
    <TextInput
      v-model="insuranceRelatedData"
      multiline
      :rows="4"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.InsuranceRelatedData</IxRes>
      </template>
    </TextInput>
    <NumericInput
      v-model="coverageAmountAllocatedProportionally"
      format="f2"
      addon="€" hide-description
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.CoverageAmountAllocatedProportionally</IxRes>
      </template>
    </NumericInput>
  </div>
</template>

<script>

import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    NumericInput,
    TextInput
  },

  data () {
    return {}
  },

  computed: {
    ...mapFormFields('realEstate/facilities', [
      'current.coverageAmountAllocatedProportionally',
      'current.insuranceRelatedData'
    ])
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.facility-insurance {
  @include makeGrid(2);
}

</style>
