<template lang="html">
  <DefaultLayout>
    <div slot="leftContent">
      <AreaSelector />
      <OrgUnitSelector />
    </div>
    <CenterContent slot="mainContent" />
    <RightUpperLayer slot="rightUpperLayer" />
    <RightBottomLayer slot="rightBottomLayer" />

    <TranslationControls />
  </DefaultLayout>
</template>

<script>
import {mapActions} from 'vuex'

import TranslationControls from '@components/translation/TranslationControls'

import AreaSelector from '@realestate/components/left-sidebar/AreaSelector'
import OrgUnitSelector from '@realestate/components/left-sidebar/OrgUnitSelector'

import DefaultLayout from '@realestate/components/DefaultLayout'
import CenterContent from '@realestate/components/CenterContent'
import RightBottomLayer from '@realestate/components/right-sidebar/RightBottomLayer'
import RightUpperLayer from '@realestate/components/right-sidebar/RightUpperLayer'

import SelfDestruct from '@mixins/SelfDestruct'

export default {

  components: {
    TranslationControls,
    AreaSelector,
    CenterContent,
    DefaultLayout,
    OrgUnitSelector,
    RightBottomLayer,
    RightUpperLayer
  },

  mixins: [
    SelfDestruct
  ],

  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
    ...mapActions('realEstate', [
      'loadGlobalMasterData'
    ])
  },

  mounted () {
    this.loadGlobalMasterData()
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties',
      'Messages.Error.SR_ErrorMessages',
      'Messages.Info.SR_InfoMessages',
      'Areas.FieldRecordSystem.SR_FieldRecordSystem'
    ])
  }
}
</script>

<style lang="scss">

  .re-numeric input {
      text-align: right;
  }

</style>
