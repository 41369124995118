<template>
  <div v-if="sumContractAreas" class="lease-aggregation-view">
    <div class="sum-contract-areas-box">
      <strong>
        <IxRes>Areas.RealEstate.SR_RealEstate.SumContractAreasWithDuration</IxRes> (ha):
      </strong>
      <span>{{ sumContractAreas | formatAsHectare }}</span>
    </div>
    <div class="number-leased-in-box">
      <strong>
        <IxRes>Areas.RealEstate.SR_RealEstate.NumberLeasedIn</IxRes>
      </strong>
      <span>{{ leasedIn }}</span>
    </div>
    <div class="number-leased-out-box">
      <strong>
        <IxRes>Areas.RealEstate.SR_RealEstate.NumberLeasedOut</IxRes>
      </strong>
      <span>{{ leasedOut }}</span>
    </div>

    <div class="sum-segments-by-usage-type">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.SumSegmentsByUsageType</IxRes>
      </h4>
      <PieChart
        :data="transformedSegmentSizesByUsage"
        :label-plugin-options="sumOfSegmentsLabel"
        legend
      />
    </div>

    <div class="average-price-per-hectare">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.AveragePricePerHectare</IxRes>
      </h4>

      <div class="chart-scrollpane">
        <div ref="chartContainer" class="chartContainer" :style="{'max-height': height + 'px', height: height + 'px', width: chartWidth + 'px'}">
          <BarChart
            ref="barchart" :data="transformedPriceByLocalDistrictAndUsage"
            :additional-options="chartOptions" :width="chartWidth"
            :height="height" horizontal
            legend
          />

          <canvas id="axisCanvas" class="axisCanvas" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {format} from 'src/js/i18n/conversion'

import {formatAsHectare} from '@realestate/filters/numberFormatters'
import BarChart from '@components/charts/BarChart'
import PieChart from '@components/charts/PieChart'

function * shadeGenerator ({r = 0, g = 0, b = 0, stepSize = 0.1}) {
  let step = 1 + stepSize
  while (step > 0) {
    step = step - stepSize
    yield `rgba(${r},${g},${b},${step}`
  }
}

export default {

  components: {
    BarChart,
    PieChart
  },

  filters: {
    formatAsHectare
  },

  data () {
    return {
      sumOfSegmentsLabel: {
        render: 'percentage',
        fontColor: '#fff'
      },
      averagePricePerHectareLabel: {
        render: 'percentage',
        fontColor: '#fff'
      },
      chartWidth: 500,
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) => {
              switch (tooltipItem.datasetIndex) {
              case 0: return 'Gesamt:        ' + format(tooltipItem.xLabel, 'f2') + ' €/ha'
              case 1: return 'Ackerfläche:  ' + format(tooltipItem.xLabel, 'f2') + ' €/ha'
              case 2: return 'Ackerland:     ' + format(tooltipItem.xLabel, 'f2') + ' €/ha'
              case 3: return 'Grünland:      ' + format(tooltipItem.xLabel, 'f2') + ' €/ha'
              default: return format(tooltipItem.xLabel, 'f2') + ' €/ha'
              }
            }
          }
        },
        legendCallback: (chart) => {
          chart.legend.legendItems[0].text = 'Gesamt'
          chart.legend.legendItems[1].text = 'Ackerfläche'
          chart.legend.legendItems[2].text = 'Ackerland'
          chart.legend.legendItems[3].text = 'Grünfläche'
        },
        scales: {
          yAxes: [{
            barThickness: 'flex',
            maxBarThickness: 20
          }],
          xAxes: [{
            position: 'top',
            beginAtZero: true,
            ticks: {
              callback: (value) => format(value, 'f2') + ' €/ha'
            }
          }]
        },
        animation: {
          onComplete: function ({chart}) {
            const sourceCanvas = chart.canvas
            const copyHeight = chart.scales['x-axis-0'].height
            const copyWidth = sourceCanvas.width
            if (copyHeight <= 0 || copyWidth <= 0) {
              return
            }
            const targetCtx = document.getElementById('axisCanvas').getContext('2d')
            targetCtx.canvas.width = copyWidth
            targetCtx.canvas.height = copyHeight
            targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight)
          }
        }
      }
    }
  },

  computed: {
    ...mapState('realEstate/lease', [
      'sumContractAreas',
      'leasedIn',
      'leasedOut',
      'priceByLocalDistrictAndUsage',
      'segmentSizeByUsage'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit',
      'selection'
    ]),

    height () {
      return this.transformedPriceByLocalDistrictAndUsage[0].length * 50
    },

    transformedSegmentSizesByUsage () {
      const gen = shadeGenerator({g: 128, stepSize: 0.05})
      return this.segmentSizeByUsage
        .concat()
        .sort((left, right) => right.size - left.size)
        .map(entry => ({
          label: entry.usageType,
          value: entry.size,
          color: gen.next().value
        }))
    },

    transformedPriceByLocalDistrictAndUsage () {
      if (!this.priceByLocalDistrictAndUsage) return []

      let oldArray = this.priceByLocalDistrictAndUsage

      let newArray = []
      let keys = oldArray.filter(x => x.usage === 'Gesamt').map(x => x.localDistrict)

      keys.splice(keys.findIndex(x => x === 'Gesamt'), 1) // move 'Gesamt' to first index of array/resulting chart
      keys.splice(0, 0, 'Gesamt')

      keys.forEach(key => {
        let matchingItems = oldArray.filter(x => x.localDistrict === key)

        let tt = matchingItems.find(x => x.usage === 'Gesamt')
        if (tt) {
          newArray.push({dataset: 'Gesamt', value: tt.averagePrice, label: key, color: 'rgb(11, 67, 170)'})
        } else {
          newArray.push({dataset: 'Ackerfläche', value: 0, label: key, color: 'rgb(11, 67, 170)'})
        }

        let af = matchingItems.find(x => x.usage === 'Ackerfläche')
        if (af) {
          newArray.push({dataset: 'Ackerfläche', value: af.averagePrice, label: key, color: 'rgb(110, 67, 170)'})
        } else {
          newArray.push({dataset: 'Ackerfläche', value: 0, label: key, color: 'rgb(110, 67, 170)'})
        }

        let al = matchingItems.find(x => x.usage === 'Ackerland')
        if (al) {
          newArray.push({dataset: 'Ackerland', value: al.averagePrice, label: key, color: 'rgb(190, 67, 170)'})
        } else {
          newArray.push({dataset: 'Ackerland', value: 0, label: key, color: 'rgb(190, 67, 170)'})
        }

        let gf = matchingItems.find(x => x.usage === 'Grünland')
        if (gf) {
          newArray.push({dataset: 'Grünland', value: gf.averagePrice, label: key, color: 'rgb(110, 167, 170)'})
        } else {
          newArray.push({dataset: 'Grünland', value: 0, label: key, color: 'rgb(110, 167, 170)'})
        }
      })

      let totalDataset = newArray.filter(x => x.dataset === 'Gesamt')
      let farmlandDataset = newArray.filter(x => x.dataset === 'Ackerfläche')
      let croplandDataset = newArray.filter(x => x.dataset === 'Ackerland')
      let grasslandDataset = newArray.filter(x => x.dataset === 'Grünland')

      let transformedData = [totalDataset, farmlandDataset, croplandDataset, grasslandDataset]

      return transformedData
    }
  },

  updated () {
    this.chartWidth = this.$refs.chartContainer.clientWidth
    this.$refs.barchart.chart.generateLegend()
  }
}

</script>

<style lang="scss">

.lease-aggregation-view {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;

  div {
    flex: 0 0 auto;
    width: 80%;
    margin: {
      top: 10px;
      left: auto;
      right: auto;
    }
  }

  .sum-contract-areas-box, .number-leased-in-box, .number-leased-out-box {
    font-size: larger;
  }

  .sum-segments-by-usage-type {
    flex: 1 1 auto;
  }

  .average-price-per-hectare {
    position: relative;
    flex: 1 1 auto;
    height: 10%;
    width: 100%;
  }

  .chartContainer {
    position: relative;
    width: 100%;
  }

  .chart-scrollpane {
    position: relative;
    overflow-y: auto;
    height: 100%;
  }

  .axisCanvas {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
  }
}

</style>
