var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"land-parcel-pre-succ"},[_c('div',[_c('h4',[_c('IxRes',[_vm._v("Areas.RealEstate.SR_RealEstate.Predecessors")])],1),_c('SimpleTable',{ref:"predecessorTable",attrs:{"columns":_vm.columnDefinition,"data":_vm.landParcelPredecessors,"highlight":_vm.highlightPredecessor},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value,"target":"_blank","data-ajax":"false"}},[_c('span',{staticClass:"glyphicon glyphicon-circle-arrow-right"})])]}},{key:"currentSize",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"pull-right"},[_vm._v("\n          "+_vm._s(_vm._f("formatAsHectare")(value))+"\n        ")])]}}])},[_c('template',{slot:"header-link"},[_c('span',{staticClass:"glyphicon dont-show"})])],2),_c('TableButtons',{attrs:{"selected-items":_vm.selectedPredecessors,"enable-print":false,"enable-edit":false},on:{"add":_vm.onAddPredecessor,"delete":_vm.onDeletePredecessor}})],1),_c('div',{staticClass:"successor"},[_c('h4',[_c('IxRes',[_vm._v("Areas.RealEstate.SR_RealEstate.Successors")])],1),_c('SimpleTable',{ref:"successorTable",attrs:{"columns":_vm.columnDefinition,"data":_vm.landParcelSuccessors,"highlight":_vm.highlightSuccessor},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value,"target":"_blank","data-ajax":"false"}},[_c('span',{staticClass:"glyphicon glyphicon-circle-arrow-right"})])]}},{key:"currentSize",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"pull-right"},[_vm._v("\n          "+_vm._s(_vm._f("formatAsHectare")(value))+"\n        ")])]}}])},[_c('template',{slot:"header-link"},[_c('span',{staticClass:"glyphicon dont-show"})])],2),_c('TableButtons',{attrs:{"selected-items":_vm.selectedSuccessors,"enable-print":false,"enable-edit":false},on:{"add":_vm.onAddSuccessor,"delete":_vm.onDeleteSuccessor}})],1),_c('LandParcelModal',{attrs:{"show-modal":_vm.showModal,"associated-entities":_vm.assignedEntities},on:{"save":_vm.update,"cancel":function($event){_vm.showModal=false}}},[_c('template',{slot:"additionalFieldsForExistingLandParcel"},[_c('TextInput',{attrs:{"multiline":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IxRes',[_vm._v("ModelProperties.SR_ModelProperties.Remark")])]},proxy:true}]),model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }