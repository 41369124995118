<template>
  <div class="property-segments">
    <NumericInput
      :value="segmentSumSize"
      readonly
      format="f4"
      addon="ha"
      hide-description
      class="re-numeric"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.SumSegmentSize</IxRes>
      </template>
    </NumericInput>

    <div class="segment-table span-3">
      <SimpleTable
        ref="segments"
        :columns="columnDefinition"
        :data="segments"
        :highlight="selected"
      >
        <template slot="header-link">
          <span class="glyphicon dont-show" />
        </template>

        <template slot="link" slot-scope="{value}">
          <a :href="value" target="_blank" data-ajax="false">
            <span class="glyphicon glyphicon-circle-arrow-right" />
          </a>
        </template>

        <template slot="size" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsHectare }}
          </div>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedSegments"
        :enable-print="false"
        :enable-edit="false"
        class="property-segment-buttons"
        @add="onAdd"
        @delete="onDelete"
      />
    </div>

    <SegmentModal
      v-if="isDialogOpen"
      :title="SR_RealEstate.AddSegmentSearchArea"
      :show-modal="isDialogOpen"
      @hidden="closeDialog"
      @addSegment="onAddSegment"
    />
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import newGuid from '@helpers/new-guid'

import {formatAsHectare} from '@realestate/filters/numberFormatters'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import NumericInput from '@components/forms/NumericInput'
import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'
import SegmentModal from '@realestate/components/lease/widgets/SegmentModal'

export default {
  components: {
    NumericInput,
    SimpleTable,
    TableButtons,
    SegmentModal
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('segments')
  ],

  data () {
    return {
      isDialogOpen: false
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapFormFields('realEstate/properties', [
      'current.segments'
    ]),

    selectedSegments () {
      return this.selection.segments
    },

    segmentSumSize () {
      return this.segments ? this.segments.map(row => row.size).reduce((pre, curr) => pre + curr, 0) : 0
    },

    columnDefinition () {
      return {
        link: {
          title: ' '
        },

        localDistrictName: {
          title: this.SR_RealEstate.LocalDistrict
        },

        cadastralDistrictNumber: {
          title: this.SR_RealEstate.CadastralDistrict
        },

        landParcelNumber: {
          title: this.SR_RealEstate.LandParcel
        },

        size: {
          title: this.SR_RealEstate.Size
        },

        usage: {
          title: this.SR_RealEstate.Usage
        },

        groundpoints: {
          title: this.SR_RealEstate.Groundpoints
        },

        owner: {
          title: this.SR_RealEstate.Owner
        }
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/properties', [
      'appendSegment',
      'removeSegment'
    ]),

    selected (row) {
      return this.selectedSegments &&
        this.selectedSegments.includes(row)
        ? ['success']
        : []
    },

    onAdd () {
      this.isDialogOpen = true
    },

    onDelete () {
      for (let segment of this.selectedSegments) {
        this.removeSegment(segment)
      }
    },

    onAddSegment (value) {
      const segmentToAdd = {
        uid: newGuid(),
        landParcelId: value.landParcelId,
        localDistrictName: value.localDistrictName,
        cadastralDistrictNumber: value.cadastralDistrictNumber,
        landParcelNumber: value.landParcelNumber,
        size: value.segmentSize,
        usage: value.usageShortcut,
        usageId: value.usageId,
        link: null,
        groundpoints: value.groundpoints,
        owner: value.owner
      }
      this.appendSegment(segmentToAdd)
      this.closeDialog()
    },

    closeDialog () {
      this.isDialogOpen = false
    }

  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.property-segments {
  @include makeGrid(3);
}

.segment-table {
  thead { white-space: nowrap; }

  .dont-show::before {
    white-space: pre;
    content: ' ';
  }
}

</style>
