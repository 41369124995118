<template>
  <SimpleTable
    ref="table"
    :columns="columnDefinition"
    :data="rows"
    :highlight="highlightSelected"
    :disable-internal-sort="true"
    fixed-header
    @sorted="onSorted"
    @scroll="onScroll"
  >
    <template slot="Area" slot-scope="{value}">
      <div class="pull-right">
        {{ value | formatAsHectare }}
      </div>
    </template>
  </SimpleTable>
</template>

<script>
import {mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'

import SimpleTable from '@components/table/SimpleTable'

import {formatAsHectare} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {

  components: {
    SimpleTable
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('table')
  ],

  props: {
    rows: Array
  },

  data () {
    return {
      highlightSelected: (row) => {
        if (this.selection.table.includes(row)) {
          return ['success']
        } else if (row.Active === 'Nein') { // XXX need a better flag for this
          return ['warning']
        } else {
          return []
        }
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    columnDefinition () {
      return {
        LocalDistrict: {
          title: this.SR_RealEstate.LocalDistrict,
          sortable: true,
          width: '20%'
        },
        CadastralDistrict: {
          title: this.SR_RealEstate.CadastralDistrict,
          sortable: true,
          width: '10%'
        },
        Number: {
          title: this.SR_RealEstate.Number,
          sortable: true,
          width: '15%'
        },
        Area: {
          title: this.SR_RealEstate.Size,
          width: '15%'
        },
        Usages: {
          title: this.SR_RealEstate.Usages,
          width: '15%'
        },
        Owner: {
          title: this.SR_RealEstate.Owner,
          width: '25%'
        }
      }
    }
  },

  methods: {
    ...mapActions('realEstate', [
      'loadDataForTableSelection'
    ]),

    onSelected (selectedItems) {
      if (selectedItems && selectedItems.length === 1) {
        this.loadDataForTableSelection(`/realestate/LandParcel/Details?id=${selectedItems[0].Uid}`)
      } else {
        // TODO: load data for multiple selection
      }
    },

    onSorted (sortedEventArgs) {
      this.$emit('sorted', sortedEventArgs)
    },

    onScroll (scrollEvent) {
      this.$emit('scroll', scrollEvent)
    }
  },

  mounted () {
    this.$on('selected', this.onSelected)
  }
}

</script>
