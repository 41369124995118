<template>
  <div class="land-parcel-pre-succ">
    <div>
      <h4><IxRes>Areas.RealEstate.SR_RealEstate.Predecessors</IxRes></h4>
      <SimpleTable
        ref="predecessorTable"
        :columns="columnDefinition"
        :data="landParcelPredecessors"
        :highlight="highlightPredecessor"
      >
        <template slot="header-link">
          <span class="glyphicon dont-show" />
        </template>

        <template slot="link" slot-scope="{value}">
          <a :href="value" target="_blank" data-ajax="false">
            <span class="glyphicon glyphicon-circle-arrow-right" />
          </a>
        </template>

        <template slot="currentSize" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsHectare }}
          </div>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedPredecessors"
        :enable-print="false"
        :enable-edit="false"
        @add="onAddPredecessor"
        @delete="onDeletePredecessor"
      />
    </div>

    <div class="successor">
      <h4><IxRes>Areas.RealEstate.SR_RealEstate.Successors</IxRes></h4>
      <SimpleTable
        ref="successorTable"
        :columns="columnDefinition"
        :data="landParcelSuccessors"
        :highlight="highlightSuccessor"
      >
        <template slot="header-link">
          <span class="glyphicon dont-show" />
        </template>

        <template slot="link" slot-scope="{value}">
          <a :href="value" target="_blank" data-ajax="false">
            <span class="glyphicon glyphicon-circle-arrow-right" />
          </a>
        </template>

        <template slot="currentSize" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsHectare }}
          </div>
        </template>
      </SimpleTable>
      <TableButtons
        :selected-items="selectedSuccessors"
        :enable-print="false"
        :enable-edit="false"
        @add="onAddSuccessor"
        @delete="onDeleteSuccessor"
      />
    </div>

    <LandParcelModal
      :show-modal="showModal" :associated-entities="assignedEntities"
      @save="update"
      @cancel="showModal=false"
    >
      <template slot="additionalFieldsForExistingLandParcel">
        <TextInput v-model="remark" multiline>
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Remark</IxRes>
          </template>
        </TextInput>
      </template>
    </LandParcelModal>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import {formatAsHectare} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'
import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'
import TextInput from '@components/forms/TextInput'

import LandParcelModal from './LandParcelModal'

export default {

  components: {
    SimpleTable,
    LandParcelModal,
    TableButtons,
    TextInput
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('predecessorTable'),
    makeSelectionMixin('successorTable')
  ],

  data () {
    return {
      showModal: false,
      remark: null,

      highlightPredecessor: (row) => {
        if (this.selectedPredecessors.includes(row)) {
          return ['success']
        }
        return []
      },

      highlightSuccessor: (row) => {
        if (this.selectedSuccessors.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.assignedEntities',
      'current.landParcelPredecessors',
      'current.landParcelSuccessors'
    ]),

    selectedPredecessors () {
      return this.selection.predecessorTable
    },

    selectedSuccessors () {
      return this.selection.successorTable
    },

    columnDefinition () {
      return {
        link: {
          title: ' ',
          value: x => '/RealEstate/Company/' + this.assignedEntities[0] + '/Landparcel/' + x.landParcelId
        },
        localDistrict: {
          title: this.SR_RealEstate.LocalDistrict
        },
        cadastralDistrict: {
          title: this.SR_RealEstate.CadastralDistrict
        },
        number: {
          title: this.SR_RealEstate.Number
        },
        community: {
          title: this.SR_ModelProperties.Community
        },
        page: {
          title: this.SR_RealEstate.Page
        },
        currentSize: {
          title: this.SR_RealEstate.Size
        },
        remark: {
          title: this.SR_ModelProperties.Remark
        }
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/landparcels', [
      'addSuccessor',
      'removeSuccessor',
      'addPredecessor',
      'removePredecessor'
    ]),

    onAddSuccessor () {
      this.mutation = 'addSuccessor'
      this.remark = null
      this.showModal = true
    },

    onDeleteSuccessor () {
      for (let successor of this.selectedSuccessors) {
        this.removeSuccessor(successor)
      }
    },

    onAddPredecessor () {
      this.mutation = 'addPredecessor'
      this.remark = null
      this.showModal = true
    },

    onDeletePredecessor () {
      for (let predecessor of this.selectedPredecessors) {
        this.removePredecessor(predecessor)
      }
    },

    update (input) {
      const dto = {
        uid: input.number.uid,
        landParcelId: input.number.uid,
        localDistrict: input.localDistrict.text,
        cadastralDistrict: input.cadastralDistrict.number,
        number: input.number.number,
        community: input.community,
        page: input.page,
        currentSize: input.currentSize,
        remark: this.remark ? this.remark : input.remark
      }
      this[this.mutation](dto)
      this.showModal = false
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.land-parcel-pre-succ {
  @include makeGrid(1);

  .dont-show::before {
    white-space: pre;
    content: ' ';
  }
}

.successor {
  margin-top: 10px;
}

</style>
