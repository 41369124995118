<template>
  <div class="land-parcel-common">
    <LocalDistrictSelect
      v-model="localDistrict"
      :label="SR_RealEstate.LocalDistrict"
      :modal-title="SR_RealEstate.LocalDistrict"
      allow-empty
      class="span-2"
    />

    <TextInput v-model="cadastralDistrict">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.CadastralDistrict</IxRes>
      </template>
    </TextInput>

    <TextInput v-model="number" class="number">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Number</IxRes>
      </template>
    </TextInput>

    <BusinessObjectSelect
      v-model="businessObject"
      :label="SR_RealEstate.BusinessObject"
      :modal-title="SR_RealEstate.BusinessObject"
      allow-empty
      class="span-2"
    />

    <NumericInput
      v-model="size"
      addon="ha"
      format="f4"
      hide-description
      class="span-1 re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.CatasterSize</IxRes>
      </template>
    </NumericInput>

    <NumericInput
      v-model="segmentsSize"
      readonly
      addon="ha"
      format="f4"
      hide-description
      class="span-1 re-numeric"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.SumSegmentSize</IxRes>
      </template>
    </NumericInput>

    <ContactSelect
      v-model="owner"
      :label="SR_RealEstate.Owner"
      contact-type="Other"
      allow-empty
      class="span-2"
    />

    <CommunitySelect
      v-model="landRegisterCommunity"
      :label="SR_ModelProperties.LandRegisterCommunity"
      :modal-title="SR_ModelProperties.LandRegisterCommunity"
      allow-empty
      class="span-2"
    />

    <TextInput v-model="landRegister">
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.LandRegisterPage</IxRes>
      </template>
    </TextInput>

    <TextInput
      v-model="remark"
      :rows="4"
      multiline
      class="span-4"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.Remark</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>

import {mapResources, mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'

import LocalDistrictSelect from '@realestate/components/local-district/LocalDistrictSelect'
import BusinessObjectSelect from '@realestate/components/business-object/BusinessObjectSelect'
import ContactSelect from '@realestate/components/contacts/ContactSelect'
import CommunitySelect from '@realestate/components/community/CommunitySelect'

export default {
  components: {
    NumericInput,
    TextInput,
    LocalDistrictSelect,
    BusinessObjectSelect,
    ContactSelect,
    CommunitySelect
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.localDistrict',
      'current.cadastralDistrict',
      'current.number',
      'current.businessObject',
      'current.size',
      'current.segmentations',
      'current.owner',
      'current.landRegisterCommunity',
      'current.landRegister',
      'current.remark'
    ]),

    segmentsSize () {
      return this.segmentations[0]
        .segments
        .map((x) => x.area)
        .reduce((a, b) => a + b, 0)
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.land-parcel-common {
  @include makeGrid(4);
}

</style>
