<template>
  <div class="found-container">
    <div class="title-header">
      <i class="fa fa-check space-right" />
      <p class="space-right">
        <IxRes>Areas.RealEstate.SR_RealEstate.LandParcelFound</IxRes>
      </p>
    </div>
    <div class="segment-table">
      <SimpleTable
        ref="segmentsSelection"
        :columns="columnDefinition"
        :data="segments"
        :highlight="selected"
      />

      <TableButtons
        :selected-items="selection.segmentsSelection"
        :enable-edit="false"
        :enable-print="false"
        :enable-delete="false"
        @add="onAdd"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {format} from 'src/js/i18n/conversion'
import {mapResources} from '@helpers/vuex'

import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {
  components: {
    TableButtons,
    SimpleTable
  },
  mixins: [
    makeSelectionMixin('segmentsSelection')
  ],
  data () {
    return {
      selected: (row) => {
        if (this.selection.segmentsSelection.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },
  computed: {
    ...mapState('realEstate', {
      segments: state => state.data.segments
    }),
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),
    columnDefinition () {
      return {
        localDistrictName: this.SR_RealEstate.LocalDistrict,
        cadastralDistrictNumber: this.SR_RealEstate.CadastralDistrict,
        landParcelNumber: this.SR_RealEstate.LandParcel,
        usageName: this.SR_RealEstate.Usage,
        groundpoints: {
          title: this.SR_RealEstate.Groundpoints,
          value: x => x.groundpoints
        },
        segmentSize: {
          title: this.SR_RealEstate.Size,
          value: x => format(x.segmentSize, 'f4') + ' ha'
        }
      }
    }
  },
  methods: {
    onAdd () {
      this.$emit('addSegment', this.selection.segmentsSelection[0])
    }
  }
}
</script>

<style scoped>
.space-right {
  margin-right: 6px;
}
.fa-check {
  color: forestgreen;
  font-size: 1.8em;
}
.found-container {
  display: flex;
  flex-direction: column;
}
.title-header {
  display: flex;
}
</style>
