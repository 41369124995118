<template lang="html">
  <div class="grid">
    <div class="search-field">
      <OrgUnitSelectorSearch
        :data="flattenedOrgUnits"
        @onSearch="onSearch"
      />
    </div>
    <div class="toggle-buttons">
      <ToggleButtonGroup
        :toggle-buttons="toggleButtons"
        preselection
        @viewSelected="updateView"
      />
    </div>
    <div class="navigation-column">
      <keep-alive>
        <component
          :is="currentNavigationComponent"
          v-model="selection"
          :org-units="availableOrgUnits"
          :search-result="searchResult"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

import ToggleButtonGroup from '@components/ToggleButtonGroup'

import ListNavigation from '@realestate/components/left-sidebar/ListNavigation'
import RocketTreeNavigation from '@realestate/components/left-sidebar/RocketTreeNavigation'
import SearchNavigation from '@realestate/components/left-sidebar/SearchNavigation'
import TagNavigation from '@realestate/components/left-sidebar/TagNavigation'
import OrgUnitSelectorSearch from '@realestate/components/left-sidebar/OrgUnitSelectorSearch'

import {flattenEntityForest} from 'src/js/helpers/tree.js'

export default {

  components: {
    ListNavigation,
    OrgUnitSelectorSearch,
    RocketTreeNavigation,
    SearchNavigation,
    TagNavigation,
    ToggleButtonGroup
  },

  data () {
    return {
      currentNavigationComponent: null,
      toggleButtons: [
        {
          title: 'Hierarchische Navigation',
          icon: 'fa fa-list',
          component: 'RocketTreeNavigation'
        },
        {
          title: 'Baumansicht',
          icon: 'fa fa-sitemap',
          component: 'ListNavigation'
        },
        {
          title: 'Tags',
          icon: 'fa fa-tags',
          component: 'TagNavigation'
        }
      ],
      searchResult: []
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'availableOrgUnits',
      'currentArea',
      'currentOrgUnit'
    ]),

    selection: {
      get () {
        return this.currentOrgUnit
      },
      set (value) {
        this.setCurrentOrgUnit(value)
      }
    },

    flattenedOrgUnits () {
      return flattenEntityForest(this.availableOrgUnits)
    }
  },

  methods: {
    ...mapMutations('realEstate', [
      'setCurrentOrgUnit'
    ]),

    ...mapActions('realEstate', [
      'loadAvailableOrgUnits'
    ]),

    updateView (view) {
      this.currentNavigationComponent = view
    },

    onSearch (result) {
      this.updateView('SearchNavigation')
      this.searchResult = result
    }
  },

  watch: {
    currentArea () {
      this.loadAvailableOrgUnits()
    }
  }
}
</script>

<style lang="scss" scoped>

  .grid {
    display: grid;
    grid-template: "left right" auto "main main" 1fr / 1fr 1fr;
  }

  .search-field {
    grid-area: left;
  }

  .toggle-buttons {
    grid-area: right;
  }

  .navigation-column {
    grid-area: main;
  }
</style>
