<template>
  <SimpleTable
    ref="table"
    :columns="columnDefinition"
    :data="rows"
    :highlight="selected"
    @click="select"
  />
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

import {mapResources} from '@helpers/vuex'
import {formatAsHectare} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {

  components: {
    SimpleTable
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('table')
  ],

  props: {
    rows: Array,
    isSales: Boolean
  },

  data () {
    return {

      selected: (row) => {
        return this.selection.includes(row) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    columnDefinition () {
      return {
        IsPaid: {
          title: this.SR_ModelProperties.IsPaid,
          sortable: true
        },
        Contract: {
          title: this.SR_ModelProperties.LeaseContract,
          type: 'date',
          sortable: true
        },
        Partner: {
          title: this.SR_ModelProperties.Partner,
          type: 'date',
          sortable: true
        },
        IBAN: {
          title: this.SR_ModelProperties.IBAN
        },
        BIC: {
          title: this.SR_ModelProperties.BIC
        },
        Amount: {
          title: this.SR_ModelProperties.Amount
        },
        Currency: {
          title: this.SR_ModelProperties.Currency,
          sortable: true
        },
        DueDate: {
          title: this.SR_ModelProperties.DueDate
        }
      }
    }
  }
}

</script>
