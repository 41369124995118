<template>
  <div class="contact-selector">
    <SelectCreate
      v-model="selectedContact"
      :options="contacts"
      :fields="fields"
      :title="modalTitle"
      :label="label"
      :make-new-item="makeNewContact"
      :allow-create="true"
      @type="onTyping"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <slot slot="label" name="label" />
      <div
        v-if="value"
        slot-scope="{value: model}"
        class="create-contact-dialog"
      >
        <TextInput
          v-if="model"
          v-model="model.text"
          :label="SR_ModelProperties.Name"
        />

        <TextInput
          v-model="model.nameAdditional"
          :label="SR_ModelProperties.NameAdditional"
        />

        <TextInput
          v-model="model.street"
          label="SR_ModelProperties.Street"
        />

        <TextInput
          v-model="model.pobox"
          label="SR_ModelProperties.PostOfficeBox"
        />

        <TextInput
          v-model="model.zipcode"
          label="SR_ModelProperties.PostalCode"
        />

        <TextInput
          v-model="model.city"
          label="SR_ModelProperties.City"
        />

        <TextInput
          v-model="model.localCentre"
          label="SR_ModelProperties.LocalCentre"
        />
      </div>
    </SelectCreate>
  </div>
</template>

<script>
import axios from 'axios'
import {mapResources} from '@helpers/vuex'
import {mapGetters} from 'vuex'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    SelectCreate,
    TextInput
  },

  props: {
    value: Object,
    contactType: String,
    label: String,
    modalTitle: String,
    fields: Object
  },

  data () {
    return {
      contacts: [],
      countries: [],
      regions: [],
      officialCode: '',
      selectedCountry: null,
      selectedRegion: null
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit']),

    selectedContact: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewContact (input) {
      return {
        text: input,
        value: null,
        nameAdditional: '',
        street: '',
        pobox: '',
        city: '',
        localCentre: ''
      }
    },

    onTyping (input) {
      this._loadOptions(input)
    },

    onSave (newContact) {
      const dto = {
        uid: newContact.value,
        name: newContact.text,
        nameAdditional: newContact.nameAdditional,
        type: this.contactType,
        address: {
          street: newContact.street,
          postOfficeBox: newContact.pobox,
          city: newContact.city,
          localCentre: newContact.localCentre
        },
        communications: {},
        bankingAccounts: [],
        info: '',
        fkDataOwner: this.currentOrgUnit.uid
      }

      newContact.action = axios.post('/realestate/contacts/savecontact', dto)
        .then((result) => {
          newContact.value = result.data
          return newContact
        })
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()
    },

    _loadOptions (input) {
      axios.get('/realestate/contacts/selectize', {
        params: {
          query: input,
          type: this.contactType,
          orgUnitId: this.currentOrgUnit.uid
        }
      })
        .then(result => {
          this.contacts = result.data
        })
    }
  },

  watch: {
    selectedContact () {
      this.$emit('input', this.selectedContact)
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.create-contact-dialog {
  @include makeGrid(1);
}

</style>
