<template>
  <SimpleTable
    ref="table"
    :columns="columnDefinition"
    :data="rows"
    :highlight="selected"
    :disable-internal-sort="true"
    fixed-header
    @sorted="onSorted"
    @scroll="onScroll"
  />
</template>

<script>

import {mapActions} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

import {mapResources} from '@helpers/vuex'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {
  components: {
    SimpleTable
  },

  mixins: [
    makeSelectionMixin('table')
  ],

  props: {
    rows: Array
  },

  data () {
    return {

      selected: (row) => {
        if (this.selection.table.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    columnDefinition () {
      return {
        Number: {
          title: this.SR_RealEstate.Number,
          sortable: true,
          width: '25%'
        },
        Name: {
          title: this.SR_RealEstate.Property,
          width: '25%'
        },
        TypeName: {
          title: this.SR_RealEstate.PropertyType,
          sortable: true,
          width: '25%'
        },
        Address: {
          title: this.SR_RealEstate.Address,
          sortable: true,
          width: '25%'
        }
      }
    }
  },

  methods: {
    ...mapActions('realEstate', [
      'loadDataForTableSelection'
    ]),

    onSelected (selectedItems) {
      if (selectedItems && selectedItems.length === 1) {
        this.loadDataForTableSelection(`/realestate/Property/GetPropertyDetails?id=${selectedItems[0].Uid}`)
      } else {
        // TODO: load data for multiple selection
      }
    },

    onSorted (sortedEventArgs) {
      this.$emit('sorted', sortedEventArgs)
    },

    onScroll (scrollEvent) {
      this.$emit('scroll', scrollEvent)
    }
  },

  mounted () {
    this.$on('selected', this.onSelected)
  }
}

</script>

<style lang="scss">

</style>
