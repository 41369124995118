import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Cluster from 'ol/source/Cluster'
import TileLayer from 'ol/layer/Tile'
import TileWMSSource from 'ol/source/TileWMS'
import Point from 'ol/geom/Point'
import GeoJSON from 'ol/format/GeoJSON'
import {bbox} from 'ol/loadingstrategy'
import {getCenter} from 'ol/extent'

export const defaultAttribution = `© ${new Date().getFullYear()} <a href="https://ixmap.eu/">iXmap Services GmbH &amp; Co. KG</a>`

export const makeVectorLayer = ({id, name = 'Unnamed data layer', features, style}) => {
  const layer = new VectorLayer({
    source: new VectorSource({
      attributions: [defaultAttribution],
      features
    }),
    style
  })
  layer.set('id', id)
  layer.set('name', name)
  return layer
}

export const makePolygonClusterLayer = ({id, name = 'Unnamed data layer', source, style, distance}) => {
  const layer = new VectorLayer({
    source: new Cluster({
      attributions: [defaultAttribution],
      distance,
      source,
      geometryFunction: feature => {
        if (feature.getGeometry().getType() === 'MultiPolygon') {
          return new Point(getCenter(feature.getGeometry().getInteriorPoints().getExtent()))
        } else {
          return feature.getGeometry().getInteriorPoint()
        }
      }
    }),
    style
  })
  layer.set('id', id)
  layer.set('name', name)
  return layer
}

export function makeWFSLayer ({url, typeNames, style}) {
  return new VectorLayer({
    // source: new ol.source.Cluster({
    source: new VectorSource({
      attributions: [defaultAttribution],
      format: new GeoJSON(),
      url: function (extent) {
        return `${url}?service=WFS&version=2.0.0&request=GetFeature&typeNames=${typeNames}&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(',')},EPSG:3857`
      },
      strategy: bbox
    }),
    style
  })
}

export function makeWMSLayer ({url, layers}) {
  return new TileLayer({
    source: new TileWMSSource({
      attributions: [defaultAttribution],
      url,
      params: {
        LAYERS: layers
      }
    })
  })
}

export function findLayerById (map, id) {
  const queue = [...map.getLayers().getArray()]

  while (queue.length) {
    const layer = queue.shift()
    if (layer.getLayers instanceof Function) {
      queue.unshift(...layer.getLayers().getArray())
    } else if (layer.get('id') === id) {
      return layer
    }
  }

  return null
}
