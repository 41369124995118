<template>
  <BsModal v-model="showModal">
    <template #title>
      <IxRes>Areas.RealEstate.SR_RealEstate.{{ title }}</IxRes>
    </template>
    <div v-if="selectExistingLandParcel" class="land-parcel-form">
      <LocalDistrictSelect
        v-model="localDistrict" :label="SR_RealEstate.LocalDistrict"
        :allow-create="false"
        allow-empty
      />
      <VueSelectize
        v-model="cadastralDistrict" :options="cadastralDistrictOptions"
        :fields="cadastralDistrictFields"
        allow-empty
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.CadastralDistrict</IxRes>
        </template>
      </VueSelectize>
      <VueSelectize
        v-model="number" :options="numberOptions"
        :fields="numberFields"
        :create="onCreateLandParcel"
        allow-empty
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Number</IxRes>
        </template>
      </VueSelectize>

      <div class="land-register-information">
        <label class="land-register-label"> {{ SR_RealEstate.CurrentLandRegister }}</label>
        <TextInput
          v-model="community" class="land-register-community"
          disabled
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.LandRegisterCommunity</IxRes>
          </template>
        </TextInput>
        <TextInput
          v-model="page" class="land-register-page"
          disabled
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.LandRegisterPage</IxRes>
          </template>
        </TextInput>
        <TextInput
          v-model="owner" class="land-register-owner"
          disabled
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.Owner</IxRes>
          </template>
        </TextInput>
        <NumericInput
          v-model="currentSize" class="land-register-current-size"
          format="f4" hide-description
          readonly
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.CurrentSize</IxRes>
          </template>
        </NumericInput>
      </div>
      <NumericInput
        v-model="sizeByContract"
        format="f4"
        hide-description
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.SizeByContract</IxRes>
        </template>
      </NumericInput>

      <slot name="additionalFieldsForExistingLandParcel" />
    </div>

    <div v-else>
      <TextInput
        v-model="localDistrict.text"
        allow-empty
        disabled
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.LocalDistrict</IxRes>
        </template>
      </TextInput>
      <TextInput v-model="cadastralDistrict.number" disabled>
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.CadastralDistrict</IxRes>
        </template>
      </TextInput>
      <TextInput v-model="number" disabled>
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Number</IxRes>
        </template>
      </TextInput>

      <div class="create-land-register-information">
        <label class="create-land-register-label">
          <IxRes>Areas.RealEstate.SR_RealEstate.LandRegister</IxRes>
        </label>
        <HelpBox class="info-box">
          Bitte Daten für das neu zu erfassende Flurstück eingeben
        </HelpBox>

        <VueSelectize
          v-model="community"
          class="create-land-register-community"
          :options="communityOptions"
          :fields="{value: 'uid', text: 'name'}"
          allow-empty
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.LandRegisterCommunity</IxRes>
          </template>
        </VueSelectize>
        <TextInput v-model="page" class="create-land-register-page">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.LandRegisterPage</IxRes>
          </template>
        </TextInput>
        <NumericInput
          v-model="currentSize" class="create-land-register-current-size"
          format="f4" hide-description
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.CurrentSize</IxRes>
          </template>
        </NumericInput>
        <NumericInput
          v-model="sizeByContract" class="create-land-register-size-by-contract"
          format="f4" hide-description
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.SizeByContract</IxRes>
          </template>
        </NumericInput>
        <TextInput
          v-model="remark" class="create-land-register-remark"
          multiline
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.LandParcelRemark</IxRes>
          </template>
        </TextInput>
      </div>
      <slot name="additionalFieldsForNewLandParcel" />
    </div>
    <template slot="footer">
      <IxButton save large @click="confirmModal">
        <IxRes>Common.SR_Common.{{ saveButtonText }}</IxRes>
      </IxButton>
      <IxButton cancel large @click="cancelModal" />
    </template>
  </BsModal>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import HelpBox from '@components/help/HelpBox'

import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import LocalDistrictSelect from '@realestate/components/local-district/LocalDistrictSelect'

export default {

  components: {
    BsModal,
    HelpBox,
    IxButton,
    LocalDistrictSelect,
    NumericInput,
    TextInput,
    VueSelectize
  },

  props: {
    value: Object,
    showModal: Boolean,
    associatedEntities: Array
  },

  data () {
    return {

      selectExistingLandParcel: true,
      title: null,
      saveButtonText: null,

      localDistrict: null,
      cadastralDistrict: null,
      number: null,
      currentSize: null,
      owner: null,
      community: null,
      page: null,
      sizeByContract: null,
      remark: null,
      cadastralDistrictOptions: [],
      numberOptions: [],
      communityOptions: [],
      cadastralDistrictFields: {text: 'number', value: 'uid'},
      numberFields: {
        text: 'number',
        value: 'uid'
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit'])
  },

  methods: {

    loadCadastralDistricts (localDistrict) {
      axios.get('/realestate/CadastralDistrict/CadastralDistrictsForLocalDistrict', {params: {parentId: localDistrict.value, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.cadastralDistrictOptions = result.data
        })
    },

    loadLandParcels (cadastralDistrict) {
      axios.get('/realestate/LandParcel/GetLandParcelsInCadastralDistrict', {params: {parentId: cadastralDistrict.uid, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.numberOptions = result.data
        })
    },

    loadLandRegister (landParcel) {
      axios.get('/realestate/LandRegister/LandRegisterForLandParcel', {params: {parentId: landParcel.uid}})
        .then(result => {
          this.owner = result.data.owner
          this.currentSize = result.data.area
          this.community = result.data.community
          this.page = result.data.page
          this.sizeByContract = result.data.area
        })
    },

    loadCommunities () {
      axios.get('/RealEstate/Community/CommunitiesInDistrictOfLocalDistrict', {params: {localDistrictId: this.localDistrict.value, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.communityOptions = result.data
        })
    },

    onCreateLandParcel (input) {
      this.selectExistingLandParcel = false
      this.loadCommunities()
      this.number = input
      this.title = 'Flurstück erstellen und hinzufügen'
      this.saveButtonText = 'Erstellen und hinzufügen'
    },

    resetData () {
      this.title = 'AddLandParcel'
      this.saveButtonText = 'Add'
      this.selectExistingLandParcel = true
      this.localDistrict = null
      this.cadastralDistrict = null
      this.number = null
      this.currentSize = null
      this.owner = null
      this.community = null
      this.page = null
      this.sizeByContract = null
      this.remark = null
      this.cadastralDistrictOptions = []
      this.numberOptions = []
    },

    cancelModal () {
      this.$emit('cancel')
      this.resetData()
    },

    confirmModal () {
      if (this.selectExistingLandParcel) {
        this.saveExistingLandParcel()
      } else {
        this.createAndEmitNewLandParcel()
      }
    },

    saveExistingLandParcel () {
      const landParcel = {
        localDistrict: this.localDistrict,
        cadastralDistrict: this.cadastralDistrict,
        number: this.number,
        community: this.community,
        page: this.page,
        currentSize: this.currentSize,
        sizeByContract: this.sizeByContract,
        remark: this.remark
      }
      this.$emit('save', landParcel)
      this.resetData()
    },

    createAndEmitNewLandParcel () {
      const newLandParcel = {
        associatedEntities: this.associatedEntities,
        cadastralDistrictNumber: this.cadastralDistrict.number,
        contractualSize: this.sizeByContract,
        fkDataOwner: this.currentOrgUnit.uid,
        landParcelRemark: this.remark,
        landRegisterCommunity: {
          uid: this.community.uid,
          fkDistrict: this.community.fkDistrict,
          name: this.community.name,
          officialCode: this.community.officialCode,
          fkDataOwner: this.community.fkDataOwner
        },
        landRegisterPage: this.page,
        localDistrictName: this.localDistrict.text,
        localDistrictUid: this.localDistrict.value,
        number: this.number,
        size: this.currentSize
      }

      axios.post('/RealEstate/LandParcel/CreateLandParcelFromModal', newLandParcel)
        .then(result => {
          if (result.data.hasError) {
            notifications.error(result.data.errorMessage)
            this.resetData()
          } else {
            const landParcel = {
              localDistrict: this.localDistrict,
              cadastralDistrict: this.cadastralDistrict,
              number: {
                number: this.number,
                uid: result.data.newUid
              },
              community: this.community.name,
              page: this.page,
              currentSize: this.currentSize,
              sizeByContract: this.sizeByContract,
              remark: this.remark
            }
            notifications.success('Flurstück gespeichert')
            this.$emit('save', landParcel)
            this.resetData()
          }
        })
    }
  },

  watch: {
    localDistrict (value) {
      if (value) {
        this.loadCadastralDistricts(value)
      }
    },

    cadastralDistrict (value) {
      if (value) {
        this.loadLandParcels(value)
      }
    },

    number (value) {
      if (value === null) return
      if (this.selectExistingLandParcel && !!this.localDistrict & !!this.cadastralDistrict) {
        this.loadLandRegister(value)
      }
    }
  },

  mounted () {
    this.saveButtonText = 'Add'
    this.title = 'AddLandParcel'
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.info-box {
  margin-top: 20px;
}

.create-land-register-information, .land-register-information {
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-gap: 5px;
}

.land-register-information {
  grid-template:
    'label label label label'
    'community community community page'
    'owner owner owner size';
}
.land-register-label { grid-area: label; }
.land-register-community { grid-area: community; }
.land-register-page { grid-area: page; }
.land-register-current-size { grid-area: size; }
.land-register-owner { grid-area: owner; }

.create-land-register-information {
  grid-template:
    'label label label label'
    'help help help help'
    'community community page page'
    'current-size current-size size-by-contract size-by-contract'
    'remark remark remark remark';
}

.info-box {
  grid-area: help;
  margin-top: 0px;
  }

.create-land-register-label {
  grid-area: label;
  margin-top: 20px;
 }
.create-land-register-community { grid-area: community; }
.create-land-register-page { grid-area: page; }
.create-land-register-current-size { grid-area: current-size; }
.create-land-register-size-by-contract { grid-area: size-by-contract; }
.create-land-register-remark { grid-area: remark; }

</style>
