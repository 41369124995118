<template>
  <div v-if="totalEntitlement" class="land-parcel-entitlement-aggregation-view">
    <div class="total-entitlement-box">
      <strong><IxRes>Areas.RealEstate.SR_RealEstate.TotalEntitlement</IxRes> (ha):</strong>
      <span>{{ totalEntitlement | formatAsHectare }}</span>
    </div>

    <div class="entitlement-by-usage-box">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.EntitlementByUsage</IxRes>
      </h4>

      <div style="width: 80%; height: 80%;">
        <PieChart
          :data="transformedEntitlementByUsage"
          :additional-options="additionalOptions"
          :label-plugin-options="entitlementByUsageChartLabels"
          legend
        />
      </div>
    </div>

    <div class="entitlement-by-type-box">
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.EntitlementByType</IxRes>
      </h4>

      <div style="width: 80%; height: 80%;">
        <PieChart
          :data="transformedEntitlementByType"
          :additional-options="additionalOptions"
          :label-plugin-options="entitlementByTypeChartLabels"
          legend
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {formatAsHectare} from '@realestate/filters/numberFormatters'
import PieChart from '@components/charts/PieChart'

function * shadeGenerator ({r = 0, g = 0, b = 0, stepSize = 0.1}) {
  let step = 1 + stepSize
  while (step > 0) {
    step = step - stepSize
    yield `rgba(${r},${g},${b},${step}`
  }
}

export default {

  components: {
    PieChart
  },

  filters: {
    formatAsHectare
  },

  data () {
    return {
      entitlementByUsageChartLabels: {
        render: 'percentage',
        fontColor: '#000',
        position: 'border',
        overlap: false
      },
      entitlementByTypeChartLabels: {
        render: 'percentage',
        fontColor: '#000',
        position: 'border'
      },
      additionalOptions: {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            left: 10,
            right: 0,
            bottom: 0,
            top: 0
          }
        }
      }
    }
  },

  computed: {
    ...mapState('realEstate/landparcels', [
      'totalEntitlement',
      'entitlementByUsage',
      'entitlementByType'
    ]),

    ...mapGetters('realEstate', [
      'currentOrgUnit',
      'selection'
    ]),

    transformedEntitlementByUsage () {
      const gen = shadeGenerator({g: 128, stepSize: 0.05})
      return this.entitlementByUsage
        .concat()
        .sort((left, right) => right.sum - left.sum)
        .map(entry => ({
          label: entry.usage,
          value: entry.sum,
          color: gen.next().value
        }))
    },

    transformedEntitlementByType () {
      const gen = shadeGenerator({g: 128, stepSize: 0.20})
      return this.entitlementByType
        .map(entry => ({
          label: entry.type,
          value: entry.sum,
          color: gen.next().value
        }))
    }
  }
}

</script>

<style lang="scss">

.land-parcel-entitlement-aggregation-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: {
             left: 20px;
             right: 20px;
           }
  * {
    position: relative;
  }

  div {
    margin: {
      top: 10px;
      /*left: auto;
      right: auto;*/
    }
  }

  .total-entitlement-box {
    font-size: larger;
  }

  .entitlement-by-usage-box,
  .entitlement-by-type-box {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 10%;
    max-height: 80%;

    h4 { display: block; position: relative; }
    div { position: relative; }
  }
}

</style>
