<template>
  <BsModal v-model="isModalOpen" title="Neue Zahlungsempfänger anlegen">
    <div class="payment-form">
      <VueSelectize
        v-model="recipientData.paymentMode"
        :label="SR_ModelProperties.PaymentMode"
        :fields="{value: 'value', text: 'text'}"
        :options="paymentModes"
        required
        allow-empty
      />

      <ContactSelect
        v-model="recipientData.recipient"
        :label="SR_ModelProperties.Recipient"
        :allow-create="false"
        contact-type="Other"
        @input="selectBankDetailsOption"
      />

      <VueSelectize
        v-model="bankDetailsSelection"
        :options="bankDetailsSelectizeOptions"
        :label="SR_RealEstate.BankDetails"
        allow-empty
        @input="bankDetailsInput"
      />

      <TextInput
        v-model="recipientData.transactionComment"
        :label="SR_ModelProperties.TransactionComment"
        name="transactionComment"
      />

      <a class="small span-2" @click="showBankDetailInputs">
        <IxRes :resource="SR_RealEstate">AddBankDetails</IxRes>
      </a>

      <div
        v-if="bankDetailInputsVisible"
        key="transitionDiv"
        class="bank-details-form span-2"
      >
        <TextInput
          v-model="recipientData.iban"
          :label="SR_ModelProperties.IBAN"
          class="span-2"
        />

        <TextInput
          v-model="recipientData.bic"
          :label="SR_ModelProperties.BIC"
          @blur="loadBankName"
        />

        <TextInput
          v-if="bankDetailInputsVisible"
          v-model="recipientData.bankName"
          :label="SR_RealEstate.Bank"
          :disabled="recipientData.bankNameAlreadyExists"
        />
      </div>

      <div class="payment span-2">
        <VueSelectize
          v-model="selectedPaymentOptions"
          :options="paymentOptions"
          :label="SR_Common.Type"
          allow-empty
          @input="definePaymentMode"
        />

        <NumericInput
          v-if="!paymentMode"
          v-model="recipientData.share"
          :label="SR_ModelProperties.Share"
          class="span-4"
        />

        <NumericInput
          v-if="paymentMode"
          v-model="recipientData.flatSum"
          :label="SR_ModelProperties.FlatSum"
          class="span-3"
        />

        <VueSelectize
          v-if="paymentMode"
          v-model="recipientData.currency"
          :label="SR_ModelProperties.Currency"
          :fields="{value: 'uid', text: 'short', label: 'name'}"
          :options="availableCurrencies"
          required
          allow-empty
        />
      </div>

      <TextInput
        v-model="recipientData.typeOfPayment"
        :label="SR_ModelProperties.TypeOfPayment"
        class="payment-type-of-payment span-2"
      />

      <div class="payment-button span-2">
        <IxButton
          icon="check" save
          large
          :disabled="!requiredInputsFilledOut" @click="save"
        >
          <IxRes :resource="SR_Common">
            Apply
          </IxRes>
        </IxButton>
        <IxButton cancel large @click="cancel" />
      </div>
    </div>
  </BsModal>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import TextInput from 'src/vue/components/forms/TextInput'
import BsModal from '@components/bootstrap/BsModal'
import VueSelectize from '@components/VueSelectize'
import ContactSelect from '@realestate/components/contacts/ContactSelect'
import NumericInput from 'src/vue/components/forms/NumericInput'
import IxButton from '@components/IxButton'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {

  components: {
    BsModal,
    ContactSelect,
    NumericInput,
    TextInput,
    VueSelectize,
    IxButton
  },

  mixins: [
    makeResourceMixin(resources)
  ],

  props: {
    isOpen: Boolean,
    value: Object
  },

  data () {
    return {
      bankDetailInputsVisible: false,
      inputPaymentRecipient: {},
      title: String,
      bankDetails: [],
      paymentMode: {},
      paymentModes: [],
      bankDetailsSelectizeOptions: [],
      bankDetailsSelection: null,
      selectedPaymentOptions: {
        text: 'FlatSum',
        value: 'FlatSum'
      },
      paymentOptions: [
        {
          text: 'Part',
          value: 'Part'
        }, {
          text: 'FlatSum',
          value: 'FlatSum'
        }
      ],
      recipientData: this.value ||
        {
          bankName: '',
          bankNameAlreadyExists: false,
          bic: null,
          currency: null,
          flatSum: null,
          iban: null,
          paymentMode: null,
          recipient: null,
          saveNewBankDetails: false,
          share: null,
          transactionComment: null,
          typeOfPayment: null
        }
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'availableCurrencies',
      'currentOrgUnit'
    ]),

    isModalOpen: {
      get () {
        return this.isOpen
      },
      set (value) {
        this.$emit('isOpenChanged', value)
      }
    },

    requiredInputsFilledOut () {
      return this.recipientData.paymentMode &&
        this.recipientData.recipient &&
        this.recipientData.iban &&
        this.recipientData.typeOfPayment &&
        this.shareAndFlatsumValid
    },

    shareAndFlatsumValid () { // at the moment, either share or flatSum has to be zero in order to be able to save the contract
      return this.selectedPaymentOptions.text === 'Part'
        ? this.recipientData.share != null
        : (this.recipientData.flatSum != null &&
          this.recipientData.currency != null)
    }
  },

  methods: {
    selectBankDetailsOption (bankDetail) {
      if (!bankDetail) return

      let ibanFromValue = this.inputPaymentRecipient.iban ? this.inputPaymentRecipient.iban.trim() : null
      let bicFromValue = this.inputPaymentRecipient.bic ? this.inputPaymentRecipient.bic.trim() : null

      axios('/RealEstate/Contacts/GetBankDetailsForContact', {params: {contactId: bankDetail.value}})
        .then((result) => {
          this.bankDetails = result.data
          this.bankDetailsSelectizeOptions = []
          result.data.forEach((x) => {
            let currentItem = {
              value: x.uid,
              text: x.iban ? x.iban.trim() : ' ' +
                ' - ' +
                x.name ? x.name.trim() : ' ',
              label: x.swiftBic ? x.swiftBic.trim() : ' '
            }
            this.bankDetailsSelectizeOptions.push(currentItem)

            if (ibanFromValue && bicFromValue && ibanFromValue === x.iban && bicFromValue === x.swiftBic) {
              this.bankDetailsSelection = currentItem
            }
          })
        })
    },

    bankDetailsInput () {
      if (this.bankDetailsSelection) {
        this.bankDetailInputsVisible = false

        this.bankDetails.forEach((x) => {
          if (this.bankDetailsSelection.value === x.uid) {
            this.recipientData.iban = x.iban.trim()
            this.recipientData.bic = x.swiftBic ? x.swiftBic.trim() : ''
            this.recipientData.bankName = x.name ? x.name.trim() : ''
            this.recipientData.saveNewBankDetails = false
          }
        })
      } else {
        this.resetBankDetailInputs()
      }
    },

    showBankDetailInputs () {
      this.bankDetailInputsVisible = true
      this.bankDetailsSelection = null
      this.recipientData.saveNewBankDetails = true
      this.recipientData.bankNameAlreadyExists = false
      this.resetBankDetailInputs()
    },

    resetBankDetailInputs () {
      this.recipientData.iban = null
      this.recipientData.bic = null
      this.recipientData.bankName = null
    },

    loadBankName () {
      this.recipientData.bankName = ''
      this.recipientData.bankNameAlreadyExists = false
      if (this.recipientData.bic) {
        axios('/RealEstate/MasterDataBank/GetBankNameForBic', {params: {entityId: this.currentOrgUnit.uid, bic: this.recipientData.bic}})
          .then((result) => {
            if (result.data !== '') { // TODO check if the weak equality check is actually required, CORE-910
              this.recipientData.bankName = result.data
              this.recipientData.bankNameAlreadyExists = true
            }
          })
      }
    },

    definePaymentMode (value) {
      this.paymentMode = value.text === 'FlatSum'
      if (this.paymentMode) {
        this.recipientData.share = null
      } else {
        this.recipientData.flatSum = null
        this.recipientData.currency = null
      }
    },

    save () {
      if (this.bankDetailInputsVisible) {
        this.saveNewBankDetails()
      }
      this.$emit('input', this.recipientData)
      this.bankDetailInputsVisible = false
      this.bankDetailsSelection = null
    },

    saveNewBankDetails () {
      let data = {
        iban: this.recipientData.iban,
        swiftBic: this.recipientData.bic ? this.recipientData.bic.trim() : '',
        name: this.recipientData.bankName ? this.recipientData.bankName.trim() : '',
        contactId: this.recipientData.recipient.value,
        entityId: this.currentOrgUnit.uid
      }

      axios.post('/RealEstate/Contacts/CreateBankDetailsForContact', data)

      this.createBankDetailsForContact(data)
    },

    cancel () {
      this.$emit('cancel')
      this.bankDetailInputsVisible = false
      this.bankDetailsSelection = null
      this.resetBankDetailInputs()
    }

  },
  created () {
    axios('/RealEstate/PaymentMode/GetPaymentModes', {params: {entityId: this.currentOrgUnit.uid, full: true}})
      .then((result) => {
        result.data.forEach(mode => {
          mode.isSystem = mode.ownerId === null
        })
        this.paymentModes = result.data.map(x => {
          return {
            label: null,
            text: x.name,
            value: x.uid}
        })
      })
  }
}

</script>

<style lang="scss" scoped>
@import "../../styles/_grid";

.payment-form {
  @include makeGrid(2);
}
.payment {
  @include makeGrid(5);
}

.payment-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-right: 5px;
  }

  :last-child {
    margin-right: 0px;
  }
}

.bank-details-form {
  @include makeGrid(2);
}

</style>
