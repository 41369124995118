<template>
  <div class="easement-form">
    <VueSelectize
      v-model="easementType"
      :fields="{value: 'uid', text: 'name'}"
      :options="easementTypes"
      :create="true"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.EasementType</IxRes>
      </template>
    </VueSelectize>

    <VueSelectize
      v-model="beneficiary"
      :fields="{value: 'uid', text: 'name'}"
      :options="beneficiaries"
      :create="true"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Beneficiary</IxRes>
      </template>
    </VueSelectize>

    <TextInput
      v-model="remark"
      :rows="2"
      multiline
    >
      <template #label>
        <IxRes>Common.SR_Common.Remark</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import axios from 'axios'
import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'

export default {

  components: {
    VueSelectize,
    TextInput
  },

  props: {
    value: Object
  },

  data () {
    return {
      easementTypes: [],
      beneficiaries: []
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),

    easementType: {
      get () {
        return this.value ? this.value.easementType : null
      },
      set (value) {
        this.$emit('input', {easementType: value, beneficiary: this.value.beneficiary, remark: this.value.remark, uid: this.value.uid})
      }
    },

    beneficiary: {
      get () {
        return this.value ? this.value.beneficiary : null
      },
      set (value) {
        this.$emit('input', {easementType: this.value.easementType, beneficiary: value, remark: this.value.remark, uid: this.value.uid})
      }
    },

    remark: {
      get () {
        return this.value ? this.value.remark : null
      },
      set (value) {
        this.$emit('input', {easementType: this.value.easementType, beneficiary: this.value.beneficiary, remark: value, uid: this.value.uid})
      }
    }
  },

  methods: {
    _loadEasements () {
      axios.get('/RealEstate/MasterDataEasementType/GetEasementTypes', {params: {entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.easementTypes = result.data
        })
    },

    _loadBeneficiaries () {
      axios.get('/RealEstate/MasterDataBeneficiary/GetBeneficiaries', {params: {entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.beneficiaries = result.data
        })
    }
  },

  mounted () {
    this._loadEasements()
    this._loadBeneficiaries()
  }
}

</script>

<style lang="scss">

@import "../../../styles/_grid";

.easement-form {
  @include makeGrid(1);
}

</style>
