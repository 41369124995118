<template>
  <div class="mortgage-form">
    <NumericInput v-model="amount" hide-description>
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Amount</IxRes>
      </template>
    </NumericInput>

    <VueSelectize
      v-model="currency"
      :options="availableCurrencies"
      :fields="{value: 'uid', text: 'name'}"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
      </template>
    </VueSelectize>

    <VueSelectize
      v-model="bank"
      :options="availableBanks"
      :fields="{value: 'uid', text: 'name'}"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Bank</IxRes>
      </template>
    </VueSelectize>

    <VueSelectize
      v-model="loanNumber"
      :options="availableLoanNumbers"
      :fields="{value: 'uid', text: 'name'}"
      :create="true"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.LoanNumber</IxRes>
      </template>
    </VueSelectize>

    <BsCheckbox v-model="removalAllowed">
      <IxRes>Areas.RealEstate.SR_RealEstate.RemovalAllowed</IxRes>
    </BsCheckbox>

    <TextInput
      v-model="remark"
      :rows="2"
      multiline
    >
      <template #label>
        <IxRes>Common.SR_Common.Remark</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {

  components: {
    VueSelectize,
    NumericInput,
    TextInput,
    BsCheckbox
  },

  props: {
    value: Object
  },

  computed: {
    ...mapState('realEstate', {
      availableLoanNumbers: state => state.masterData.loanNumbers,
      availableBanks: state => state.masterData.bankData
    }),
    ...mapGetters('realEstate', [
      'availableCurrencies'
    ]),

    amount: {
      get () {
        return this.value ? this.value.amount : 0
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: this.value.bank,
          currency: this.value.currency,
          loanNumber: this.value.loanNumber,
          amount: value,
          removalAllowed: this.value.removalAllowed,
          remark: this.value.remark
        })
      }

    },

    currency: {
      get () {
        return this.value
          ? (this.availableCurrencies.find(cur => cur.uid === this.value.currency) || null)
          : null
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: this.value.bank,
          currency: value.uid,
          loanNumber: this.value.loanNumber,
          amount: this.value.amount,
          removalAllowed: this.value.removalAllowed,
          remark: this.value.remark
        })
      }
    },

    bank: {
      get () {
        return this.value ? this.value.bank : null
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: value,
          currency: this.value.currency,
          loanNumber: this.value.loanNumber,
          amount: this.value.amount,
          removalAllowed: this.value.removalAllowed,
          remark: this.value.remark
        })
      }
    },

    loanNumber: {
      get () {
        return this.value ? this.value.loanNumber : null
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: this.value.bank,
          currency: this.value.currency,
          loanNumber: value,
          amount: this.value.amount,
          removalAllowed: this.value.removalAllowed,
          remark: this.value.remark
        })
      }
    },

    removalAllowed: {
      get () {
        return this.value ? this.value.removalAllowed : false
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: this.value.bank,
          currency: this.value.currency,
          loanNumber: this.value.loanNumber,
          amount: this.value.amount,
          removalAllowed: value,
          remark: this.value.remark
        })
      }
    },

    remark: {
      get () {
        return this.value ? this.value.remark : null
      },
      set (value) {
        this.$emit('input', {
          uid: this.value.uid,
          bank: this.value.bank,
          currency: this.value.currency,
          loanNumber: this.value.loanNumber,
          amount: this.value.amount,
          removalAllowed: this.value.removalAllowed,
          remark: value
        })
      }
    }
  }
}

</script>

<style lang="scss">

@import "../../../styles/_grid";

.mortgage-form {
  @include makeGrid(1);
}

</style>
