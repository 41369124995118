<template>
  <div class="contract-finances">
    <NumericInput
      v-model="financeData.price" :label="priceLabel"
      class="span-1 re-numeric"
      format="f2" hide-description
    >
      <template #label>
        <IxRes v-if="priceLabel">Areas.RealEstate.SR_RealEstate.{{ priceLabel }}</IxRes>
      </template>
    </NumericInput>
    <VueSelectize
      v-model="currency"
      allow-empty
      :options="availableCurrencies" :fields="currencyFields"
      class="span-1"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
      </template>
    </VueSelectize>

    <div class="span-5">
      <h4><IxRes>Areas.RealEstate.SR_RealEstate.Payments</IxRes></h4>
      <SimpleTable
        ref="payment-table"
        :columns="paymentColumns"
        :data="financeData.rates"
        :highlight="highlightSelectedPayments"
      >
        <template slot="pricePaid" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsMoney }}
          </div>
        </template>
        <template slot="currency" slot-scope="{value}">
          <span>{{ value | formatAsCurrency }}</span>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedPayments"
        :enable-print="false"
        @add="onAddPayment"
        @edit="onEditPayment"
        @delete="onDeletePayment"
      />
    </div>

    <div class="invoices">
      <h4><IxRes>Areas.RealEstate.SR_RealEstate.Invoices</IxRes></h4>
      <SimpleTable
        ref="invoice-table"
        :columns="invoiceColumns"
        :data="financeData.invoiceNumbers"
        :highlight="highlightSelectedInvoice"
      />

      <TableButtons
        :selected-items="selectedInvoices"
        :enable-print="false"
        @add="onAddInvoice"
        @edit="onEditInvoice"
        @delete="onDeleteInvoice"
      />
    </div>

    <BsModal
      v-model="showPaymentModal"
      blocking
      @cancel="onCancelPayment"
    >
      <template #title>
        <IxRes>Zahlung hinzufügen</IxRes>
      </template>
      <div class="payment-modal-content">
        <VueDateTimePicker v-model="editPayment.datePaid" iso>
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.DatePaid</IxRes>
          </template>
        </VueDateTimePicker>
        <NumericInput
          v-model="editPayment.pricePaid"
          format="f2"
          hide-description
        >
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.PricePaid</IxRes>
          </template>
        </NumericInput>
        <VueSelectize
          v-model="editPayment.currency"
          allow-empty
          :options="availableCurrencies" :fields="currencyFields"
          class="currency-selectize"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
          </template>
        </VueSelectize>
        <TextInput v-model="editPayment.remark" multiline>
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Remarks</IxRes>
          </template>
        </TextInput>
      </div>
      <template slot="footer">
        <IxButton save large @click="onSavePayment" />
        <IxButton cancel large @click="onCancelPayment" />
      </template>
    </BsModal>

    <BsModal
      v-model="showInvoiceModal"
      blocking
      @cancel="onCancelInvoice"
    >
      <template #title>
        <IxRes>Rechnung hinzufügen</IxRes>
      </template>
      <div class="invoice-modal-content">
        <TextInput v-model="editInvoiceNumber.invoiceNumber">
          <template #label>
            <IxRes>ModelProperties.SR_ModelProperties.InvoiceNumber</IxRes>
          </template>
        </TextInput>
      </div>
      <template slot="footer">
        <IxButton save large @click="onSaveInvoice" />
        <IxButton cancel large @click="onCancelInvoice" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import newGuid from '@helpers/new-guid'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'

import {formatAsCurrency, formatAsMoney} from '@realestate/filters/numberFormatters'

export default {

  components: {
    BsModal,
    SimpleTable,
    IxButton,
    NumericInput,
    TableButtons,
    TextInput,
    VueDateTimePicker,
    VueSelectize
  },

  filters: {
    formatAsCurrency,
    formatAsMoney
  },

  mixins: [
    makeSelectionMixin('payment-table'),
    makeSelectionMixin('invoice-table')
  ],

  data () {
    return {
      showPaymentModal: false,
      showInvoiceModal: false,
      editPayment: {
        uid: null,
        datePaid: null,
        currency: null,
        pricePaid: null,
        remark: null
      },
      editInvoiceNumber: {
        uid: null,
        invoiceNumber: null
      },
      currencyFields: {text: 'short', value: 'uid'},

      highlightSelectedPayments: (row) => {
        if (this.selectedPayments.includes(row)) {
          return ['success']
        }
        return []
      },

      highlightSelectedInvoice: (row) => {
        if (this.selectedInvoices.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties',
      'Common.SR_Common'
    ]),

    ...mapFormFields('realEstate/contract', [
      'current.financeData'
    ]),

    ...mapGetters('realEstate', [
      'currentArea',
      'availableCurrencies'
    ]),

    currency: {
      get () {
        return this.financeData.currency
      },
      set (value) {
        this.financeData.currency = value && value.uid ? value.uid : value
      }
    },

    selectedPayments: {
      get () {
        return this.selection['payment-table']
      },
      set (value) {
        this.selection['payment-table'] = value
      }
    },

    selectedInvoices: {
      get () {
        return this.selection['invoice-table']
      },
      set (value) {
        this.selection['invoice-table'] = value
      }
    },

    paymentColumns () {
      return {
        datePaid: {
          title: this.SR_ModelProperties.DatePaid,
          type: 'date'
        },
        pricePaid: {
          title: this.SR_ModelProperties.PricePaid
        },
        currency: {
          title: this.SR_RealEstate.Currency
        },
        remark: {
          title: this.SR_Common.Remark
        }
      }
    },

    invoiceColumns () {
      return {
        invoiceNumber: {
          title: this.SR_ModelProperties.InvoiceNumber
        }
      }
    },

    priceLabel () {
      return this.currentArea.id === 'purchases'
        ? 'PurchasingPrice'
        : this.currentArea.id === 'sales'
          ? 'SalePrice'
          : null
    }
  },

  methods: {
    ...mapMutations('realEstate/contract', [
      'addPayment',
      'deletePayments',
      'addInvoice',
      'deleteInvoices'
    ]),

    onAddPayment () {
      this.showPaymentModal = true
      this.editPayment = {
        uid: null,
        datePaid: null,
        currency: null,
        pricePaid: null,
        remark: null
      }
    },

    onEditPayment () {
      this.showPaymentModal = true
      this.editPayment = this.selectedPayments[0]
    },

    onSavePayment () {
      if (!this.editPayment.uid) {
        const payment = {
          uid: newGuid(),
          currency: this.editPayment.currency.uid,
          datePaid: this.editPayment.datePaid,
          pricePaid: this.editPayment.pricePaid,
          remark: this.editPayment.remark
        }
        this.addPayment(payment)
        let index = this.financeData.rates.indexOf(payment)
        this.selectedPayments = [this.financeData.rates[index]]
      } else {
        this.selectedPayments[0] = this.editPayment
      }
      this.showPaymentModal = false
    },

    onCancelPayment () {
      this.showPaymentModal = false
    },

    onDeletePayment () {
      this.deletePayments(this.selectedPayments)
      this.selectedPayments = []
    },

    onAddInvoice () {
      this.showInvoiceModal = true
      this.editInvoiceNumber = {uid: null, invoiceNumber: null}
    },

    onEditInvoice () {
      this.showInvoiceModal = true
      this.editInvoiceNumber = this.selectedInvoices[0]
    },

    onSaveInvoice () {
      if (!this.editInvoiceNumber.uid) {
        const invoice = {uid: newGuid(), invoiceNumber: this.editInvoiceNumber.invoiceNumber}
        this.addInvoice(invoice)
        let index = this.financeData.invoiceNumbers.indexOf(invoice)
        this.selectedInvoices = [this.financeData.invoiceNumbers[index]]
      } else {
        this.selectedInvoices[0] = this.editInvoiceNumber
      }
      this.showInvoiceModal = false
    },

    onCancelInvoice () {
      this.showInvoiceModal = false
    },

    onDeleteInvoice () {
      this.deleteInvoices(this.selectedInvoices)
      this.selectedInvoices = []
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.contract-finances{
  @include makeGrid(5);
}

.invoices {
  @extend .span-5;
  margin-top: 10px;
}

</style>
