<template lang="html">
  <div>
    <ul class="nav nav-tabs" role="tablist">
      <li
        v-for="(tab, index) in tabs" :key="index"
        role="presentation"
        :class="{active: value === tabValue(tab, index), disabled: tabDisabled(tab)}"
      >
        <a role="tab" @click="onClick(tab, index)">
          <slot :name="`${tabValue(tab, index)}-header`">
            {{ tab.title || tab }}
          </slot>
        </a>
      </li>
    </ul>
    <div ref="content" class="tab-content">
      <div
        v-for="(tab, index) in tabs" :key="index"
        role="tabpanel"
        class="tab-pane" :class="{active: value === tabValue(tab, index)}"
      >
        <slot :name="tabValue(tab, index)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // valid values for tabs prop:
  // array of titles: e.g. ['Tab 1', 'Tab 2'] => value will be an int (tab index)
  // array of objects of the form {title: '', value: '', disabled: false} => value will be whatever is defined in the objects
  // available slots: 2 slots per tab, 'x' and 'x-header' where x is the tab value
  // if no header is supplied by slot, it defaults to the title property of the tab object or the title in the array
  props: ['value', 'tabs', 'disabled'],
  methods: {
    tabValue (tab, index) {
      return tab.value === undefined ? index : tab.value
    },
    tabDisabled (tab) {
      return tab.disabled === undefined ? false : tab.disabled
    },
    onClick (tab, index) {
      if (!this.tabDisabled(tab)) {
        this.$emit('input', this.tabValue(tab, index))
      }
    }
  },
  watch: {
    value (value) {
      this.tabs.forEach((tab, index) => {
        if (this.tabValue(tab, index) === value) {
          this.$refs.content.childNodes.item(index).dispatchEvent(new Event('shown'))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// TODO find out why this is even necessary, bootstrap links should have that by default
li {
  cursor: pointer;
}
</style>
