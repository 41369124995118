function makeSelectionMixin (tableName) {
  return {
    data () {
      return {
        selection: {
          [tableName]: []
        }
      }
    },

    methods: {
      [tableName + 'select'] (eventArgs) {
        let selectedRows = []

        if (eventArgs.event.shiftKey) {
          const firstSelectedRow = this.selection[tableName][0] //
          const lastSelectedRow = this.selection[tableName][this.selection[tableName].length - 1]
          const currentIndexOfFirstSelectedRow = this.$refs[tableName].sortedData.findIndex(row => row === firstSelectedRow) //
          const currentIndexOfLastSelectedRow = this.$refs[tableName].sortedData.findIndex(row => row === lastSelectedRow)
          const indexCurrent = eventArgs.rowIndex

          let begin, end

          if (indexCurrent === currentIndexOfFirstSelectedRow) {
            begin = indexCurrent
            end = indexCurrent + 1
          } else if (indexCurrent <= currentIndexOfFirstSelectedRow) {
            begin = indexCurrent
            end = currentIndexOfLastSelectedRow + 1
          } else {
            begin = currentIndexOfFirstSelectedRow
            end = indexCurrent + 1
          }

          selectedRows = this.$refs[tableName].sortedData.slice(begin, end)
        } else if (eventArgs.event.ctrlKey) {
          selectedRows = [eventArgs.row]
          if (selectedRows.length === 1 && this.selection[tableName].includes(selectedRows[0])) {
            selectedRows = this.selection[tableName].filter(row => row !== selectedRows[0])
          } else {
            selectedRows = this.selection[tableName].concat(selectedRows)
          }
        } else {
          selectedRows = [eventArgs.row]
          if (selectedRows.length === 1 && this.selection[tableName].length === 1 && this.selection[tableName].includes(selectedRows[0])) {
            selectedRows = []
          }
        }

        this.selection[tableName] = selectedRows
        this.$emit('selected', selectedRows)
      }
    },
    mounted () {
      this.$refs[tableName].$on('click', this[tableName + 'select'])
    }
  }
}

export default makeSelectionMixin
