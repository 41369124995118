<template>
  <div class="functional-zoning-category-selector">
    <select-create
      v-model="selectedItem"
      :options="items"
      :fields="fields"
      :title="modalTitle"
      :label="selectLabel"
      :make-new-item="makeNewItem"
      :allow-create="true"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <div slot-scope="{value: model}" class="create-functional-zoning-category-dialog">
        <text-input v-if="model" ref="firstInput" v-model="model.name" />
      </div>
    </select-create>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import newGuid from '@helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    SelectCreate,
    TextInput
  },

  props: {
    label: String,
    value: Object
  },

  data () {
    return {
      items: [],
      newItemName: null,
      fields: {
        text: 'name',
        value: 'uid'
      }
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),
    modalTitle () {
      return 'Art der Bebauung erfassen'
    },
    selectLabel () {
      return this.label ? this.label : 'Art der Bebauung'
    },

    selectedItem: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewItem (input) {
      return {name: input, uid: newGuid()}
    },

    onSave (newItem) {
      const dto = {
        uid: newItem.uid,
        name: newItem.name,
        fkDataOwner: this.currentOrgUnit.uid
      }

      axios.post('/RealEstate/MasterDataFunctionalZoningCategory/Create', dto)
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()
    },

    _loadOptions () {
      axios.get('/RealEstate/MasterDataFunctionalZoningCategory/GetFunctionalZoningCategories', {params: {entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.items = result.data
        })
    }
  },

  mounted () {
    this._loadOptions()
  }
}

</script>

<style lang="scss">

</style>
