<template>
  <BsModal :title="title" :value="showModal" @hidden="closeModal">
    <div class="modal-container">
      <div class="form-entry">
        <span class="form-input">
          <LocalDistrictSelect
            v-model="localDistrict"
            class="select"
            :label="SR_RealEstate.LocalDistrict"
            allow-empty
            :allow-create="false"
          />
        </span>
        <span class="form-input">
          <VueSelectize
            v-model="selectedCadastralDistrict"
            class="input-field"
            allow-empty
            :fields="{text: 'number', value: 'uid'}"
            :options="districts"
          >
            <template #label>
              <IxRes>Areas.RealEstate.SR_RealEstate.CadastralDistrict</IxRes>
            </template>
          </VueSelectize>
        </span>
        <span>
          <VueSelectize
            v-model="landParcel"
            class="input-field"
            allow-empty
            :fields="{text: 'number', value: 'uid'}"
            :options="landParcels"
          >
            <template #label>
              <IxRes>Areas.RealEstate.SR_RealEstate.Number</IxRes>
            </template>
          </VueSelectize>
        </span>
      </div>
      <br>

      <div v-if="!segments.length" class="not-found-container">
        <i class="fa fa-exclamation-triangle space-right" />
        <p class="space-right">
          <IxRes>Areas.RealEstate.SR_RealEstate.LandParcelNotFound</IxRes>
        </p>
        <IxButton large add>
          <IxRes>Areas.RealEstate.SR_RealEstate.AddNewLandParcel</IxRes>
        </IxButton>
      </div>

      <SegmentSelection
        v-else
        @addSegment="onAdd"
      />
    </div>
  </BsModal>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mapResources, mapFormFields} from '@helpers/vuex'

import BsModal from '@components/bootstrap/BsModal'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import LocalDistrictSelect from '@realestate/components/local-district/LocalDistrictSelect'
import SegmentSelection from './SegmentSelection'

export default {
  components: {
    BsModal,
    IxButton,
    VueSelectize,
    LocalDistrictSelect,
    SegmentSelection
  },
  props: {
    title: String,
    showModal: Boolean
  },
  computed: {
    ...mapState('realEstate', {
      cadastralDistricts: state => state.data.cadastralDistricts,
      landParcels: state => state.data.landParcels,
      segments: state => state.data.segments
    }),
    ...mapFormFields('realEstate', {
      'data.localDistrict': {
        read: value => value,
        write: option => option
      },
      'data.selectedCadastralDistrict': {
        read: value => value,
        write: option => option
      },
      'data.landParcel': {
        read: value => value,
        write: option => option
      }
    }),
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),
    districts () {
      return this.cadastralDistricts ? this.cadastralDistricts : []
    }
  },
  methods: {
    ...mapActions('realEstate', [
      'getLocalDistrict',
      'getLandParcelInCadastrialDistrict',
      'getSegmentsForLandParcel'
    ]),
    ...mapMutations('realEstate', [
      'setSelectedCadastralDistrict',
      'setLandParcel',
      'resetSelection'
    ]),
    onAdd (segment) {
      this.resetSelection()
      this.$emit('addSegment', segment)
    },
    closeModal () {
      this.resetSelection()
      this.$emit('hidden')
    }
  },
  watch: {
    localDistrict () {
      this.setLandParcel(null)
      this.setSelectedCadastralDistrict(null)
      this.getLocalDistrict()
    },
    selectedCadastralDistrict () {
      this.setLandParcel(null)
      this.getLandParcelInCadastrialDistrict()
    },
    landParcel () {
      this.getSegmentsForLandParcel()
    }
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: 16px;
}
p {
  padding-top: 8px;
}
.modal-container {
  display: flex;
  flex-direction: column;
}
.form-entry {
  display: flex;
  justify-content: flex-start;
}
.form-input {
  margin-right: 16px;
}
.select {
  width: 260px;
}
.input-field {
  width: 134px;
}
.not-found-container {
  display: flex;
  justify-content: flex-start;
}
.fa-exclamation-triangle {
  padding-top: 8px;
  color: red;
  font-size: 1.8em;
}
.space-right {
  margin-right: 6px;
}
</style>
