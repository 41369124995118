<template>
  <div class="contract-land-parcel-form">
    <NumericInput
      v-model="sumLandParcelSize"
      format="f4"
      addon="ha"
      readonly
      hide-description
      class="landparcel-size-input re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.TotalSizeOfLandParcels</IxRes>
      </template>
    </NumericInput>

    <div class="span-3">
      <SimpleTable
        ref="contract-landparcel-table"
        :columns="columnDefinition"
        :highlight="selected"
        :data="landParcelData"
      >
        <template slot="link" slot-scope="{value}">
          <a :href="value" target="_blank" data-ajax="false">
            <span class="glyphicon glyphicon-circle-arrow-right" />
          </a>
        </template>
      </SimpleTable>
      <TableButtons
        :selected-items="selectedLandParcels"
        :enable-print="false"
        :enable-edit="false"
        @add="onAdd"
        @delete="onDelete"
      >
        <IxButton icon="scissors" large @click="onDividePrice">
          <IxRes>Areas.RealEstate.SR_RealEstate.DividePrice</IxRes>
        </IxButton>
      </TableButtons>
    </div>

    <LandParcelModal
      :show-modal="showLandParcelModal" :associated-entities="commonData.associatedEntities"
      @save="insertLandParcel"
      @cancel="showLandParcelModal=false"
    >
      <template slot="additionalFieldsForExistingLandParcel">
        <div class="additional-inputs">
          <NumericInput
            v-model="price"
            hide-description
            format="f2"
          >
            <template #label>
              <IxRes>ModelProperties.SR_ModelProperties.Price</IxRes>
            </template>
          </NumericInput>
          <VueSelectize
            v-model="currency"
            allow-empty :options="availableCurrencies"
            :fields="{text: 'short', value: 'uid'}" class="span-1"
          >
            <template #label>
              <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
            </template>
          </VueSelectize>
        </div>
      </template>
      <template slot="additionalFieldsForNewLandParcel">
        <div class="additional-inputs">
          <NumericInput
            v-model="price"
            hide-description
            format="f2"
          >
            <template #label>
              <IxRes>ModelProperties.SR_ModelProperties.Price</IxRes>
            </template>
          </NumericInput>
          <VueSelectize
            v-model="currency"
            allow-empty :options="availableCurrencies"
            :fields="{text: 'short', value: 'uid'}" class="span-1"
          >
            <template #label>
              <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
            </template>
          </VueSelectize>
        </div>
      </template>
    </LandParcelModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'
import LandParcelModal from '@realestate/components/land-parcel/LandParcelModal'
import NumericInput from '@components/forms/NumericInput'

import VueSelectize from '@components/VueSelectize'

import {formatAsMoney, formatAsCurrency, formatAsHectare} from '@realestate/filters/numberFormatters'

export default {

  components: {
    IxButton,
    SimpleTable,
    LandParcelModal,
    NumericInput,
    TableButtons,
    VueSelectize
  },

  filters: {
    formatAsMoney,
    formatAsHectare,
    formatAsCurrency
  },

  mixins: [
    makeSelectionMixin('contract-landparcel-table')
  ],

  data () {
    return {
      sumLandParcelSize: 0,
      price: null,
      currency: null,
      showLandParcelModal: false,
      createLandParcel: false,
      selected: (row) => {
        if (this.selectedLandParcels.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/contract', [
      'current.commonData',
      'current.facilityData',
      'current.financeData',
      'current.landParcelData'
    ]),

    ...mapGetters('realEstate', [
      'availableCurrencies'
    ]),

    selectedLandParcels () {
      return this.selection['contract-landparcel-table']
    },

    columnDefinition () {
      return {
        link: {
          title: ' '
        },
        localDistrict: {
          title: this.SR_RealEstate.LocalDistrict
        },
        cadastralDistrict: {
          title: this.SR_RealEstate.CadastralDistrict
        },
        number: {
          title: this.SR_RealEstate.Number
        },
        community: {
          title: this.SR_RealEstate.Community
        },
        page: {
          title: this.SR_RealEstate.Page
        },
        currentSize: {
          title: this.SR_ModelProperties.CurrentSize,
          value: x => x.currentSize ? formatAsHectare(x.currentSize) : null
        },
        sizeByContract: {
          title: this.SR_RealEstate.SizeByContract,
          value: x => x.sizeByContract ? formatAsHectare(x.sizeByContract) : null
        },
        price: {
          title: this.SR_ModelProperties.Price,
          value: x => x.price ? formatAsMoney(x.price) : null
        },
        currency: {
          title: this.SR_RealEstate.Currency,
          value: x => x.currency ? formatAsCurrency(x.currency) : null
        }
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/contract', [
      'addLandParcel',
      'removeLandParcel',
      'setLandParcelPrice'
    ]),

    onAdd () {
      this.price = null
      this.currency = null
      this.showLandParcelModal = true
    },

    insertLandParcel (input) {
      this.showLandParcelModal = false
      const landParcel = {
        landParcelId: input.number.uid,
        entities: this.commonData.associatedEntities,
        link: '/RealEstate/Company/' + this.commonData.associatedEntities[0] + '/Landparcel/' + input.number.uid,
        localDistrict: input.localDistrict.text,
        cadastralDistrict: input.cadastralDistrict.number,
        number: input.number.number,
        community: input.community,
        page: input.page,
        currentSize: input.currentSize,
        sizeByContract: input.sizeByContract,
        remark: input.remark,
        price: this.price,
        currency: this.currency.uid
      }

      this.addLandParcel(landParcel)
    },

    onDelete () {
      this.removeLandParcel(this.selectedLandParcels)
    },

    cancelAddLandParcel () {
      this.showLandParcelModal = false
    },

    onDividePrice () {
      const parcels = this.landParcelData
      const facilities = this.facilityData

      const action = () => {
        const totalSize = parcels.map(lp => lp.sizeByContract).reduce((pre, cur) => pre + (cur || 0.0), 0)
        const totalPrice = this.financeData.price
        const facilityPriceTotal = facilities.map(f => f.price).reduce((pre, cur) => pre + (cur || 0.0), 0)
        const currency = this.financeData.currency

        parcels.forEach(lp => {
          const size = lp.sizeByContract ? lp.sizeByContract : 0.0
          const price = size * (totalPrice - facilityPriceTotal) / totalSize
          this.setLandParcelPrice({parcelId: lp.uid, price, currency})
        })
      }

      if (parcels.some((parcel) => parcel.price !== 0.0)) {
        action()
        // @askForUserConfirmation('priceDistribution', action)
      } else {
        action()
      }
    }
  },

  watch: {
    landParcelData (data) {
      this.sumLandParcelSize = data.map((x) => x.sizeByContract).reduce((a, b) => a + b, 0)
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.contract-land-parcel-form {
  @include makeGrid(3);
}

.additional-inputs {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 3fr 2fr;
  grid-gap: 5px;
}
</style>
