<template>
  <div class="finances">
    <div>
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.PaymentRecipients</IxRes>
      </h4>
      <SimpleTable
        ref="payment-recipients"
        :columns="paymentRecipientColumns"
        :data="paymentRecipientsData"
        :highlight="highlightSelectedRecipients"
      >
        <template slot="flatSum" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsMoney }}
          </div>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedPaymentRecipients"
        :enable-print="false"
        @add="onAddPaymentRecipients"
        @edit="onEditPaymentRecipient"
        @delete="onDeletePaymentRecipients"
      />

      <PaymentsRecipientModal
        v-model="editPaymentRecipient"
        :is-open="isPaymentRecipientsModalOpen"
        @cancel="onCancelPaymentRecipientModal"
        @isOpenChanged="onIsOpenChanged"
      />
    </div>

    <div class="payment-button">
      <IxButton large :disabled="selectedPaymentRecipients.length === 0" @click="calculatePayments">
        <IxRes>Areas.RealEstate.SR_RealEstate.GeneratePayments</IxRes>
      </IxButton>
    </div>

    <div>
      <h4>
        <IxRes>Areas.RealEstate.SR_RealEstate.Payments</IxRes>
      </h4>
      <SimpleTable
        ref="payments"
        :columns="paymentColumns"
        :data="paymentsData"
        :highlight="onSelectedPayments"
      >
        <template slot="amount" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsMoney }}
          </div>
        </template>
      </SimpleTable>
      <TableButtons
        :selected-items="selectedPayments"
        :enable-print="false"
        @add="onAddPayment"
        @edit="onEditPayment"
        @delete="onDeletePayment"
      />

      <BsModal v-model="isPaymentModalOpen" :title="paymentModalTitle">
        <PaymentForm v-model="editPayment" />

        <div slot="footer">
          <IxButton
            icon="check" save
            large
            @click="onSavePayment"
          >
            <IxRes>Common.SR_Common.Apply</IxRes>
          </IxButton>
          <IxButton cancel large @click="closePaymentModal" />
        </div>
      </BsModal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {get} from 'src/coffee/infrastructure/ajax'

import TableButtons from '@realestate/components/common/TableButtons'
import SimpleTable from '@components/table/SimpleTable'
import IxButton from '@components/IxButton'
import newGuid from 'src/js/helpers/new-guid'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'
import {formatAsMoney} from '@realestate/filters/numberFormatters'
import PaymentsRecipientModal from '@realestate/components/lease/PaymentRecipientModal'
import BsModal from '@components/bootstrap/BsModal'
import PaymentForm from '@realestate/components/lease/PaymentForm'

export default {

  components: {
    BsModal,
    IxButton,
    SimpleTable,
    TableButtons,
    PaymentsRecipientModal,
    PaymentForm
  },

  filters: {
    formatAsMoney
  },

  mixins: [
    makeSelectionMixin('payment-recipients'),
    makeSelectionMixin('payments')
  ],

  data () {
    return {
      paymentRecipient: null,
      editPaymentRecipient: null,
      highlightSelectedRecipients: (row) => {
        if (this.selectedPaymentRecipients.includes(row)) {
          return ['success']
        }
        return []
      },

      onSelectedPayments: (row) => {
        if (this.selection.payments.includes(row)) {
          return ['success']
        }
        return []
      },

      isPaymentRecipientsModalOpen: false,

      editPayment: null,
      isPaymentModalOpen: false,
      paymentModalTitle: null
    }
  },

  computed: {
    ...mapResources([
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/lease', [
      'current.paymentRecipients',
      'current.payments',
      'current.terms'
    ]),

    selectedPaymentRecipients () {
      return this.selection['payment-recipients']
    },

    selectedPayments () {
      return this.selection['payments']
    },

    paymentsData () {
      return this.payments || []
    },

    paymentRecipientsData () {
      return this.paymentRecipients || []
    },

    termFrom () {
      return this.terms[0].start
    },

    termTo () {
      return this.terms[this.terms.length - 1].end
    },

    paymentRecipientColumns () {
      return {
        paymentMode: {
          title: this.SR_ModelProperties.PaymentMode,
          value: x => x.paymentMode.text
        },
        recipient: {
          title: this.SR_ModelProperties.Recipient,
          value: x => x.recipient.text
        },
        iban: this.SR_ModelProperties.IBAN,
        bic: this.SR_ModelProperties.BIC,
        share: this.SR_ModelProperties.Share,
        flatSum: this.SR_ModelProperties.FlatSum,
        currency: {
          title: this.SR_ModelProperties.Currency,
          value: x => x.currency.symbol
        },
        typeOfPayment: this.SR_ModelProperties.TypeOfPayment
      }
    },

    paymentColumns () {
      return {
        recipient: {
          title: this.SR_ModelProperties.Recipient,
          value: x => x.recipient.text
        },
        transactionComment: this.SR_ModelProperties.TransactionComment,
        dateDue: {
          title: this.SR_ModelProperties.DateDue,
          value: x => moment(x.dateDue).format('L')
        },
        datePaid: {
          title: this.SR_ModelProperties.DatePaid,
          value: x => x.datePaid ? moment(x.datePaid).format('L') : null
        },
        lessorIBAN: this.SR_ModelProperties.IBAN,
        lessorBIC: this.SR_ModelProperties.BIC,
        amount: this.SR_ModelProperties.Amount,
        currency: {
          title: this.SR_ModelProperties.Currency,
          value: x => x.currency.symbol
        },
        typeOfPayment: this.SR_ModelProperties.TypeOfPayment,
        vetoed: this.SR_ModelProperties.Vetoed
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/lease', [
      'appendPayment',
      'removePayment',
      'updatePayment',
      'appendPaymentRecipient',
      'removePaymentRecipient',
      'updatePaymentRecipient'
    ]),

    calculatePayments () {
      const recipients = this.paymentRecipientsData

      const totalShares = recipients.map(value => value.share).reduce((acc, value) => acc + Number.parseFloat(value))

      this.selectedPaymentRecipients.forEach((recipient) => {
        get('/RealEstate/PaymentMode/GetFullPaymentMode', {uid: recipient.paymentMode.value})
          .done((result) => {
            const payDates = []
            const startDate = moment(this.termFrom)
            const endDate = moment(this.termTo)

            for (let currentDate = startDate.clone(); !currentDate.isAfter(endDate); currentDate.add(1, 'month')) {
              const monthToPay = result.dates.find(date => date.month === (currentDate.month() + 1))
              if (monthToPay === undefined) {
                continue
              }
              const payDate = moment(currentDate).date(monthToPay.day)
              if (payDate.isBefore(startDate) || payDate.isAfter(endDate)) {
                continue
              }
              payDates.push(payDate)
            }

            const payments = []

            for (let term of this.terms) {
              const yearsInTerm = Math.ceil(moment(term.end).diff(moment(term.start), 'months') / 12)
              const now = moment()
              const payDatesInTerm = payDates.filter(payDate => payDate.isSameOrAfter(term.start) && payDate.isSameOrBefore(term.end))
              const countPayDatesInTerm = payDatesInTerm.length

              const toPayByRecipient = term.leasePrice * yearsInTerm / totalShares * Number.parseFloat(recipient.share)
              const partPrice = toPayByRecipient / countPayDatesInTerm

              for (let idx = 0; idx < countPayDatesInTerm; idx++) {
                if (payDatesInTerm[idx].isBefore(now)) {
                  continue
                }

                const payment = {
                  uid: newGuid(),
                  recipient: recipient.recipient,
                  transactionComment: recipient.transactionComment,
                  amount: recipient.flatSum !== 0 ? recipient.flatSum : partPrice,
                  currency: {
                    uid: recipient.currency.uid,
                    symbol: recipient.currency.symbol
                  },
                  typeOfPayment: recipient.typeOfPayment,
                  lessorIBAN: recipient.iban,
                  lessorBIC: recipient.bic,
                  leaserIBAN: '',
                  leaserBIC: '',
                  dateDue: payDatesInTerm[idx].format(),
                  datePaid: null,
                  vetoed: false
                }
                payments.push(payment)
              }
            }
            for (let p of payments) {
              this.appendPayment(p)
            }
          })
      })
    },

    onAddPaymentRecipients () {
      this.isPaymentRecipientsModalOpen = true
    },

    onEditPaymentRecipient () {
      this.editPaymentRecipient = this.selection['payment-recipients'][0]
      this.isPaymentRecipientsModalOpen = true
    },

    onSavePaymentRecipient (paymentRecipient) {
      if (this.paymentRecipients.find(pr => pr.uid === paymentRecipient.uid)) {
        this.updatePaymentRecipient(paymentRecipient)
      } else {
        this.appendPaymentRecipient(paymentRecipient)
      }
      this.paymentRecipient = null
    },

    onCancelPaymentRecipientModal () {
      this.paymentRecipient = null
    },

    onDeletePaymentRecipients () {
      for (let paymentRecipient of this.selection['payment-recipients']) {
        this.removePaymentRecipient(paymentRecipient)
      }
    },

    onIsOpenChanged (value) {
      this.isPaymentRecipientsModalOpen = value
    },

    onAddPayment () {
      this.editPayment = {
        recipient: null,
        transactionComment: null,
        dateDue: null,
        datePaid: null,
        lessorIBAN: null,
        lessorBIC: null,
        amount: null,
        currency: null,
        vetoed: false,
        typeOfPayment: null
      }
      this.openPaymentModal()
    },

    onEditPayment () {
      this.editPayment = JSON.parse(JSON.stringify(this.selection.payments[0]))
      this.openPaymentModal()
    },

    openPaymentModal () {
      this.isPaymentModalOpen = true
    },

    closePaymentModal () {
      this.editPayment = null
      this.isPaymentModalOpen = false
    },

    onSavePayment () {
      if (this.editPayment.uid) {
        this.updatePayment(this.editPayment)
      } else {
        this.appendPayment(this.editPayment)
      }
      this.closePaymentModal()
    },

    onDeletePayment () {
      for (let payment of this.selection['payments']) {
        this.removePayment(payment)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.finances {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

</style>
