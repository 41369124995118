<template>
  <div class="land-parcel-segments">
    <NumericInput
      v-model="segmentsSize"
      readonly
      addon="ha"
      format="f4"
      hide-description
      class="segment-size-input re-numeric"
    >
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.SumSegmentSize</IxRes>
      </template>
    </NumericInput>

    <div class="span-3">
      <SimpleTable
        ref="segments"
        :columns="columnDefinition"
        :data="rows"
        :highlight="highlightSelected"
        class="span-3"
      >
        <template slot="area" slot-scope="{value}">
          <div class="pull-right">
            {{ value | formatAsHectare }}
          </div>
        </template>
      </SimpleTable>

      <TableButtons
        :selected-items="selectedSegment"
        :enable-print="false"
        @add="onAdd"
        @edit="onEdit"
        @delete="onDelete"
      />
    </div>

    <BsModal v-model="isSegmentDialogOpen">
      <template v-if="dialogTitle" #title>
        <IxRes>Areas.RealEstate.SR_RealEstate.{{ dialogTitle }}</IxRes>
      </template>
      <SegmentForm v-model="editSegment" />

      <template slot="footer">
        <IxButton
          icon="check" save
          large
          @click="onUpdateSegment"
        >
          <IxRes>Common.SR_Common.Apply</IxRes>
        </IxButton>
        <IxButton cancel large @click="cancelDialog" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import SimpleTable from '@components/table/SimpleTable'
import NumericInput from '@components/forms/NumericInput'
import IxButton from '@components/IxButton'
import TableButtons from '@realestate/components/common/TableButtons'
import SegmentForm from '@realestate/components/land-parcel/segments/SegmentForm'
import BsModal from '@components/bootstrap/BsModal'

import {formatAsHectare} from '@realestate/filters/numberFormatters'

export default {

  components: {
    BsModal,
    NumericInput,
    SimpleTable,
    IxButton,
    TableButtons,
    SegmentForm
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('segments')
  ],

  data () {
    return {
      isSegmentDialogOpen: false,
      editSegment: null,
      highlightSelected: (row) => {
        return this.selectedSegment.includes(row) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.segmentations'
    ]),

    selectedSegment () {
      return this.selection.segments
    },

    dialogTitle () {
      if (this.editSegment) {
        if (this.editSegment.uid) {
          return 'EditSegment'
        } else {
          return 'AddSegment'
        }
      } else {
        return null
      }
    },

    columnDefinition () {
      return {
        usage: {
          title: this.SR_RealEstate.Usages
        },
        groundpoints: {
          title: this.SR_RealEstate.Groundpoints
        },
        area: {
          title: this.SR_RealEstate.Size
        }
      }
    },

    rows () {
      return this.segmentations[0].segments
    },

    segmentsSize () {
      return this.segmentations[0]
        .segments
        .map((x) => x.area)
        .reduce((a, b) => a + b, 0)
    }
  },

  methods: {
    ...mapMutations('realEstate/landparcels', [
      'appendSegment',
      'removeSegment',
      'updateSegment'
    ]),

    onAdd () {
      this.editSegment = {groundpoints: null, usage: null, area: null}
      this.isSegmentDialogOpen = true
    },

    onEdit () {
      this.editSegment = JSON.parse(JSON.stringify(this.selectedSegment[0]))
      this.isSegmentDialogOpen = true
    },

    onDelete () {
      for (let segment of this.selectedSegment) {
        this.removeSegment(segment)
      }
    },

    onUpdateSegment () {
      if (this.editSegment.uid) {
        this.updateSegment(this.editSegment)
      } else {
        this.appendSegment(this.editSegment)
      }

      this.closeDialog()
    },

    cancelDialog () {
      this.closeDialog()
    },

    closeDialog () {
      this.editSegment = null
      this.isSegmentDialogOpen = false
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.land-parcel-segments{
  @include makeGrid(3);
}

</style>
