<template>
  <div>
    <SimpleTable
      ref="easements"
      :columns="columnDefinition"
      :data="easements"
      :highlight="selected"
    />

    <TableButtons
      :selected-items="selectedEasements"
      :enable-print="false"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    />

    <BsModal v-model="isEasementDialogOpen">
      <template #title>
        <IxRes>Areas.RealEstate.SR_RealEstate.{{ modalTitle }}</IxRes>
      </template>
      <EasementForm v-model="editEasement" />

      <template slot="footer">
        <IxButton
          icon="check" save
          large
          @click="update"
        >
          <IxRes>Common.SR_Common.Apply</IxRes>
        </IxButton>
        <IxButton cancel large @click="cancelDialog" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'
import SimpleTable from '@components/table/SimpleTable'
import IxButton from '@components/IxButton'
import TableButtons from '@realestate/components/common/TableButtons'
import EasementForm from '@realestate/components/land-parcel/easements/EasementForm'
import BsModal from '@components/bootstrap/BsModal'

export default {

  components: {
    SimpleTable,
    IxButton,
    TableButtons,
    EasementForm,
    BsModal
  },

  mixins: [
    makeSelectionMixin('easements')
  ],

  data () {
    return {
      isEasementDialogOpen: false,
      modalTitle: null,
      editEasement: null,
      selected: (row) => {
        return this.selectedEasements.find(ele => ele.uid === row.uid) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.easements'
    ]),

    selectedEasements () {
      return this.selection.easements
    },

    columnDefinition () {
      return {
        easementType: {
          title: this.SR_RealEstate.EasementType
        },
        beneficiary: {
          title: this.SR_RealEstate.Beneficiary
        },
        remark: {
          title: this.SR_Common.Remark
        }
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/landparcels', [
      'appendEasement',
      'removeEasement',
      'updateEasement'
    ]),

    onAdd () {
      this.editEasement = {easementType: null, beneficiary: null, remark: null}
      this.modalTitle = 'AddEasementType'
      this.isEasementDialogOpen = true
    },

    onEdit () {
      this.editEasement = JSON.parse(JSON.stringify(this.selectedEasements[0]))
      this.modalTitle = this.SR_RealEstate.EditEasementType
      this.isEasementDialogOpen = true
    },

    onDelete () {
      for (let easement of this.selectedEasements) {
        this.removeEasement(easement)
      }
    },

    update () {
      if (this.editEasement.uid) {
        this.updateEasement(this.editEasement)
      } else {
        this.appendEasement(this.editEasement)
      }
      this.closeDialog()
    },

    cancelDialog () {
      this.closeDialog()
    },

    closeDialog () {
      this.editEasement = null
      this.isEasementDialogOpen = false
    }

  }
}

</script>
