<template>
  <div class="community-selector">
    <SelectCreate
      v-model="selectedCommunity"
      :options="communities"
      :title="modalTitle"
      :label="label"
      :make-new-item="makeNewCommunity"
      :allow-create="true"
      @type="onTyping"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <div
        v-if="value"
        slot-scope="{value: model}"
        class="create-community-dialog"
      >
        <label>
          <input
            v-model="model.isDistrictFree"
            type="checkbox"
          >
          <IxRes>Areas.RealEstate.SR_RealEstate.DistrictFreeCity</IxRes>
        </label>

        <VueSelectize
          v-if="model.isDistrictFree"
          v-model="selectedDistrict"
          :options="districtFreeCities"
          allow-empty
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Region</IxRes>
          </template>
        </VueSelectize>

        <VueSelectize
          v-else
          v-model="selectedDistrict"
          :options="districts"
          allow-empty
          @type="onDistrictTyping"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.District</IxRes>
          </template>
        </VueSelectize>

        <TextInput
          v-if="model"
          v-model="model.text"
          label="Name"
        />

        <TextInput
          v-model="model.officialCode"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.OfficialCodeCommunity</IxRes>
          </template>
        </TextInput>
      </div>
    </SelectCreate>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import newGuid from '@helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    SelectCreate,
    TextInput,
    VueSelectize
  },

  props: {
    value: {
      type: Object
    },
    modalTitle: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      communities: [],
      districtFreeCities: [],
      districts: [],
      selectedDistrict: null
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),

    selectedCommunity: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewCommunity (input) {
      return {
        value: newGuid(),
        text: input,
        isDistrictFree: false,
        district: null,
        region: null,
        officialCode: null
      }
    },

    onTyping (input) {
      this._loadOptions(input)
    },

    onSave (newCommunity) {
      const dto = {
        uid: newCommunity.value,
        name: newCommunity.text,
        officialCode: newCommunity.officialCode,
        fkDistrict: this.selectedDistrict.value,
        fkDataOwner: this.currentOrgUnit.uid
      }

      axios.post('/realestate/community/create', dto)
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()

      this._loadDistrictFreeCities()
    },

    onDistrictTyping (input) {
      this._loadDistricts(input)
    },

    _loadOptions (input) {
      axios.get('/realestate/community', {params: {query: input, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.communities = result.data
        })
    },

    _loadDistricts (input) {
      axios.get('/realestate/district/getdistrictsforpicker', {params: {query: input, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.districts = result.data
        })
    },

    _loadDistrictFreeCities () {
      axios.get('/realestate/district/getfreecitiesforpicker', {params: {entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.districtFreeCities = result.data
        })
    }
  }
}

</script>

<style lang="scss" scoped>

@import '../../styles/_grid';

.create-community-dialog {
  @include makeGrid(1);
}

</style>
