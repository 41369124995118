<template lang="html">
  <transition>
    <div class="ixmap-control mapcontrol" tabindex="0" />
  </transition>
</template>

<script>
import 'ol/ol.css'

import ZoomToExtent from 'ol/control/ZoomToExtent'
import Zoom from 'ol/control/Zoom'
import ZoomSlider from 'ol/control/ZoomSlider'
import ScaleLine from 'ol/control/ScaleLine'
import Attribution from 'ol/control/Attribution'
import Group from 'ol/layer/Group'
import Map from 'ol/Map'
import View from 'ol/View'
import {debounce} from 'lodash'

export default {
  inject: [
    'registerMap'
  ],
  props: {
    extent: Array,
    afterExtentChanged: {
      type: String,
      default: 'fit' // zoom // null
    },
    layers: {},
    plugins: {
      type: Array,
      default: () => [],
      validator: x => x.length === 0 // NOTE ASK-1273, to find usages that have to be migrated
    },
    translations: {
      type: Object,
      default: () => ({zoomToExtent: 'Reset'})
    }
  },
  data () {
    return {
      defaultExtent: [135040.32696281525, 5759272.137209072, 1886365.5190327736, 7647572.4839660665],
      actionControl: null,
      domReady: false
    }
  },
  computed: {
    validExtent () {
      return this.checkExtentIsValid(this.extent) // Array.isArray(this.extent) && this.extent.length === 4 && !this.extent.some(isNaN) && !this.extent.some(x => x === Infinity)
    },
    hasSize () {
      const size = this.map.getSize()
      return size && size[0] && size[1]
    },
    hasCenter () {
      return !!this.map.getView().getCenter()
    },
    isReady () {
      return this.domReady && this.hasSize && this.hasCenter
    }
  },
  methods: {
    checkExtentIsValid (extent) {
      return Array.isArray(extent) && extent.length === 4 && !extent.some(isNaN) && !extent.some(x => x === Infinity)
    },
    fit: debounce(function () {
      if (!this.isReady) {
        return
      }
      const extent = this.validExtent ? this.extent : this.defaultExtent
      const size = this.map.getSize()
      this.map.getView().fit(extent, {size: [size[0] - 800, size[1]], duration: 500})
    }, 350),
    createZoomToExtentControl (extent) {
      const extentNode = document.createElement('i')
      extentNode.classList.add('icon', 'icon-ix-crosshairs')
      return new ZoomToExtent({extent, tipLabel: this.translations.zoomToExtent, label: extentNode})
    },
    zoomToExtent: debounce(function () {
      if (!this.isReady) {
        return
      }
      const extent = this.validExtent ? this.extent : this.defaultExtent
      const size = this.map.getSize()
      this.map.getView().fit(extent, {size: [size[0], size[1]], duration: 500})
    }, 350),
    fixViewport () {
      this.map.updateSize()
      window.dispatchEvent(new Event('resize'))
    },
    // onMouseDown (event) {
    //   if (this.map.hasFeatureAtPixel(event.pixel)) {
    //     this.map.forEachFeatureAtPixel(event.pixel, feature => {
    //       this.$emit('click', feature)
    //       return true
    //     })
    //     // return true
    //   }
    // },
    onMouseMove (/* event */) {
      // TODO hover logic
    }
  },
  watch: {
    domReady (value) {
      if (value) {
        this.$emit('domReady', value)
      }
    },
    isReady (value) {
      if (value) {
        this.fixViewport()

        switch (this.afterExtentChanged) {
        case 'fit':
          this.fit()
          break
        case 'zoom':
          this.zoomToExtent()
          break
        }
        this.$emit('isReady', value)
      }
    },
    extent (extent) {
      // this only checks if the current extent is valid - pointless?
      if (!this.checkExtentIsValid(extent)) {
        console.error(`invalid map extent: ${extent}`)
        return
      }

      this.map.updateSize()
      // setting the extent did not work properly
      // therefore we recreate the control and remove the old
      this.map.removeControl(this.zoomToExtentControl)
      const zoomToExtentControl = this.createZoomToExtentControl(extent)
      this.map.addControl(zoomToExtentControl)
      this.zoomToExtentControl = zoomToExtentControl

      // debugger
      switch (this.afterExtentChanged) {
      case 'fit':
        this.fit()
        break
      case 'zoom':
        this.zoomToExtent()
        break
      }
    },
    layers (layers) {
      this.map.setLayerGroup(Array.isArray(layers) ? new Group({layers}) : layers)
    },
    plugins (newPlugins = [], oldPlugins = []) {
      // oldPlugins.forEach(plugin => plugin.detach())
      // newPlugins.forEach(plugin => plugin.attach(this.map))
      const added = newPlugins.filter(x => !oldPlugins.includes(x))
      const removed = oldPlugins.filter(x => !newPlugins.includes(x))
      removed.forEach(plugin => {
        plugin.detach()
      })
      added.forEach(plugin => plugin.attach(this.map))
    }
  },
  mounted () {
    const view = new View({
      projection: 'EPSG:3857',
      minZoom: 1,
      maxZoom: 19
    })

    // TODO make better go-to-extent control, with runtime change option, vue, etc.
    const extent = this.validExtent ? this.extent : this.defaultExtent
    const zoomToExtentControl = this.createZoomToExtentControl(extent)

    const controls = [
      zoomToExtentControl,
      new Zoom({zoomInTipLabel: 'Zoom +', zoomOutTipLabel: 'Zoom -'}),
      new ZoomSlider(),
      new ScaleLine(),
      new Attribution()
    ]

    this.zoomToExtentControl = zoomToExtentControl
    this.otherLayers = new Group()

    this.map = new Map({
      target: this.$el,
      projection: 'EPSG:900913',
      view,
      controls,
      layers: this.layers,
      loadTilesWhileInteracting: true
    })

    if (this.registerMap) {
      this.registerMap(this.map)
    }

    this.plugins.filter(x => x.map).forEach(plugin => plugin.detach())
    this.plugins.forEach(plugin => plugin.attach(this.map))

    // start at default extent
    this.map.getView().fit(this.defaultExtent)

    // enables a dom check, because sometimes the map does not show up in the proper frame
    // due to some delayed css transitions in the header when it wasn't loaded via ajax
    const domReadyCheck = () => {
      // see if updating the size helps - or it's empty - ajax workaround
      this.map.updateSize()
      const size = this.map.getSize()

      if (size && size[0] && size[1] && this.map.getView().getCenter()) {
        // wait a few... or those pesky pixels will blurr again - non-ajax workaround
        this.domReadyTimer = setTimeout(() => {
          this.fixViewport()
          // after this is done, everything else will execute
          this.domReady = true
        }, 900)
      } else {
        this.$nextTick(domReadyCheck)
      }
    }
    this.$nextTick(domReadyCheck)
  },
  beforeDestroy () {
    if (this.domReadyTimer) {
      clearTimeout(this.domReadyTimer)
    }

    this.plugins.forEach(plugin => plugin.detach())

    this.map.setTarget(null)
  }
}
</script>

<style lang="scss" scoped>
// .map {
//   .ol-zoomslider {
//     top: 7.5em !important;
//   }
// }
.mapcontrol {
  height: 100%;
}
.v-enter-active {
  transition: opacity 0.75s;
}
.v-enter {
  opacity: 0;
}
</style>

<style lang="scss">
.ixmap-control.mapcontrol {
  // .map-tooltip {
  //   position: relative;
  //   background: rgba(0, 0, 0, 0.5);
  //   border-radius: 4px;
  //   color: white;
  //   padding: 4px 8px;
  //   opacity: 0.7;
  //   white-space: nowrap;
  // }
  // .map-tooltip-measure {
  //   opacity: 1;
  //   font-weight: bold;
  // }
  // .map-tooltip-static {
  //   background-color: #ffcc33;
  //   color: black;
  //   border: 1px solid white;
  // }
  // .map-tooltip-measure:before,
  // .map-tooltip-static:before {
  //   border-top: 6px solid rgba(0, 0, 0, 0.5);
  //   border-right: 6px solid transparent;
  //   border-left: 6px solid transparent;
  //   content: "";
  //   position: absolute;
  //   bottom: -6px;
  //   margin-left: -7px;
  //   left: 50%;
  // }
  // .map-tooltip-static:before {
  //   border-top-color: #ffcc33;
  // }

  .ol-control.ol-attribution {
    bottom: 6px;

    ul {
      font-size: 0.8em;
      // padding: 5px;

      li {
        // display: block;
      }
    }
  }
}
</style>
