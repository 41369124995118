<template>
  <div>
    <SimpleTable
      ref="table"
      :columns="columnDefinition"
      :data="facilityData"
      :highlight="selected"
    >
      <template slot="price" slot-scope="{value}">
        <div class="pull-right">
          {{ value | formatAsMoney }}
        </div>
      </template>

      <template slot="currency" slot-scope="{value}">
        <span>{{ value | formatAsCurrency }}</span>
      </template>
    </SimpleTable>

    <TableButtons
      :selected-items="selectedItems"
      :enable-print="false"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    />

    <BsModal v-model="showModal" title="Anlagen hinzufügen" blocking>
      <div class="facility-modal-content">
        <TextInput v-model="facility.name">
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Name</IxRes>
          </template>
        </TextInput>
        <NumericInput
          v-model="facility.price"
          format="f2"
          hide-description
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Price</IxRes>
          </template>
        </NumericInput>
        <VueSelectize
          v-model="facility.currency"
          allow-empty
          :options="availableCurrencies" :fields="{text: 'short', value: 'uid'}"
          class="currency-selectize"
        >
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
          </template>
        </VueSelectize>
        <TextInput v-model="facility.remark" multiline>
          <template #label>
            <IxRes>Areas.RealEstate.SR_RealEstate.Remarks</IxRes>
          </template>
        </TextInput>
      </div>

      <template slot="footer">
        <IxButton save large @click="onSave" />
        <IxButton cancel large @click="onModalCancel" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import newGuid from '@helpers/new-guid'

import {formatAsCurrency, formatAsMoney} from '@realestate/filters/numberFormatters'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'
import SimpleTable from '@components/table/SimpleTable'
import TableButtons from '@realestate/components/common/TableButtons'

export default {
  components: {
    BsModal,
    SimpleTable,
    IxButton,
    NumericInput,
    TableButtons,
    TextInput,
    VueSelectize
  },
  filters: {
    formatAsCurrency,
    formatAsMoney
  },
  mixins: [
    makeSelectionMixin('table')
  ],
  data () {
    return {
      showModal: false,
      facility: {
        name: '',
        price: null,
        currency: null,
        remark: ''
      },
      selected: (row) => {
        if (this.selectedItems.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },
  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties',
      'Common.SR_Common'
    ]),
    ...mapFormFields('realEstate/contract', [
      'current.facilityData'
    ]),
    ...mapGetters('realEstate', [
      'availableCurrencies'
    ]),
    selectedItems: {
      get () {
        return this.selection.table
      },
      set (value) {
        this.selection.table = value
      }
    },
    columnDefinition () {
      return {
        name: {
          title: this.SR_ModelProperties.Name
        },
        price: {
          title: this.SR_ModelProperties.Price
        },
        currency: {
          title: this.SR_RealEstate.Currency
        },
        remark: {
          title: this.SR_Common.Remark
        }
      }
    }
  },
  methods: {
    ...mapMutations('realEstate/contract', [
      'addFacility',
      'deleteFacilities'
    ]),
    onAdd () {
      this.showModal = true
      this.facility = {
        uid: null,
        name: '',
        price: null,
        currency: null,
        remark: ''
      }
    },
    onEdit () {
      this.facility = this.selectedItems[0]
      this.showModal = true
    },
    onModalCancel () {
      this.showModal = false
    },
    onSave () {
      this.facility.currency = this.facility.currency.uid
      if (!this.facility.uid) {
        this.facility.uid = newGuid()
        this.addFacility(this.facility)
      }
      this.selectedItems[0] = this.facility
      this.showModal = false
    },
    onDelete () {
      this.deleteFacilities(this.selectedItems)
      this.selectedItems = []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
