import {unByKey} from 'ol/Observable'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Cluster from 'ol/source/Cluster'
import Point from 'ol/geom/Point'
import GeoJSON from 'ol/format/GeoJSON'
import {getCenter} from 'ol/extent'

import {defaultAttribution} from '@helpers/openlayers/layers'

const geoJsonFormat = new GeoJSON({dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})

export default {
  inject: [
    'getMap'
  ],
  props: {
    features: {
      default: () => []
    },
    vectorStyle: {
      required: true
    },
    clusterStyle: {
      default: null
    },
    maxZoom: {
      type: Number,
      default: Infinity
    },
    zIndex: {
      type: Number,
      default: 0
    },
    autoFocus: Boolean,
    fast: Boolean,
    layerId: String
  },
  data () {
    return {
      zoom: null
    }
  },
  computed: {
    olFeatures () {
      const featureArrayOrCollection = this.features

      let features
      if (featureArrayOrCollection.type === 'FeatureCollection') {
        features = geoJsonFormat.readFeatures(featureArrayOrCollection)
      } else {
        if (featureArrayOrCollection.length && featureArrayOrCollection[0].type === 'Feature') {
          features = featureArrayOrCollection.map(x => geoJsonFormat.readFeature(x))
        } else {
          // TODO replace with throw at some point, when ol.Feature references have been purged from application code
          features = featureArrayOrCollection
        }
      }

      return features
    }
  },
  methods: {
    recreate () {
      if (this.layer) {
        this.map.getLayers().remove(this.layer)
        this.layer = null
      }

      if (this.zoom > this.maxZoom) return

      this.layer = new VectorLayer({
        source: new VectorSource({
          attributions: [defaultAttribution],
          features: this.olFeatures,
          useSpatialIndex: !this.fast
        }),
        style: this.vectorStyle,
        zIndex: this.zIndex,
        updateWhileAnimating: this.fast,
        updateWhileInteracting: this.fast
      })

      if (this.layerId) {
        this.layer.set('id', this.layerId)
      }

      this.map.getLayers().push(this.layer)

      if (this.clusterStyle) {
        this.clusterLayer = new VectorLayer({
          source: new Cluster({
            attributions: [defaultAttribution],
            distance: 20,
            source: this.layer.getSource(),
            geometryFunction: feature => {
              const geometry = feature.getGeometry()
              switch (geometry.getType()) {
              case 'MultiPolygon': {
                return new Point(getCenter(geometry.getInteriorPoints().getExtent()))
              }
              case 'Polygon': {
                return geometry.getInteriorPoint()
              }
              case 'MultiPoint': {
                return getCenter(geometry.getExtent())
              }
              default: return geometry
              }
            }
          }),
          style: this.clusterStyle
        })

        if (this.layerId) {
          this.clusterLayer.set('id', `${this.layerId}-cluster`)
        }

        this.map.getLayers().push(this.clusterLayer)
      }

      if (this.autoFocus) {
        this.focus()
      }
    },
    focus () {
      if (!this.olFeatures || (Array.isArray(this.olFeatures) && !this.olFeatures.length)) return

      const extent = this.layer.getSource().getExtent()
      const size = this.map.getSize()
      this.map.getView().fit(extent, {size, duration: 500})
    }
  },
  watch: {
    zoom (zoom) {
      if ((this.layer && zoom > this.maxZoom) || (!this.layer && zoom <= this.maxZoom)) {
        this.recreate()
      }

      this.$emit('zoom', this.zoom)
    },
    olFeatures (features) {
      if (!this.layer) return

      const source = this.layer.getSource()
      source.clear()
      source.addFeatures(features)

      if (this.autoFocus) {
        this.focus()
      }
    },
    zIndex (zIndex) {
      if (this.layer) {
        this.layer.setZIndex(zIndex)
      }
    },
    vectorStyle () {
      if (this.map) {
        this.recreate()
      }
    }
  },
  updated () {
    if (this.map) {
      this.recreate()
    }
  },
  mounted () {
    this.getMap().then(map => {
      this.map = map

      this.zoom = this.map.getView().getZoom()
      this.zoomListenerKey = this.map.getView().on('change:resolution', () => {
        this.zoom = this.map.getView().getZoom()
      })

      this.recreate()
    })
  },
  beforeDestroy () {
    if (this.layer) {
      this.map.getLayers().remove(this.layer)
    }
    if (this.clusterLayer) {
      this.map.getLayers().remove(this.clusterLayer)
    }
    unByKey(this.zoomListenerKey)
  },
  render () {
    return null
  }
}
