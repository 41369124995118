<template>
  <div class="table-buttons">
    <IxButton
      v-if="enableAdd"
      add
      large
      :disabled="!canAdd"
      class="table-button"
      @click="raiseAdd"
    />

    <IxButton
      v-if="enableEdit"
      edit
      large
      :disabled="!canEdit"
      class="table-button"
      @click="raiseEdit"
    />

    <IxButton
      v-if="enableDelete"
      remove
      large
      :disabled="!canDelete"
      class="table-button"
      @click="raiseDelete"
    />

    <IxButton
      v-if="enablePrint"
      icon="print"
      large
      :disabled="!canPrint"
      class="table-button"
      @click="raisePrint"
    >
      <IxRes>Common.SR_Common.Print</IxRes>
    </IxButton>

    <slot />
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },

  props: {
    selectedItems: Array,
    enableAdd: {type: Boolean, default: true},
    enableEdit: {type: Boolean, default: true},
    enableDelete: {type: Boolean, default: true},
    enablePrint: {type: Boolean, default: true},
    canPrint: {type: Boolean, default: true}
  },

  computed: {
    ...mapGetters('realEstate', {
      mainTableSelection: 'selection'
    }),

    canAdd () {
      return this.mainTableSelection.length === 1
    },

    canEdit () {
      return this.selectedItems ? this.selectedItems.length === 1 : false
    },

    canDelete () {
      return this.selectedItems ? this.selectedItems.length > 0 : false
    }
  },

  methods: {
    raiseAdd () {
      this.$emit('add')
    },
    raiseEdit () {
      this.$emit('edit')
    },
    raiseDelete () {
      this.$emit('delete')
    },
    raisePrint () {
      this.$emit('print')
    }
  }
}

</script>

<style lang="scss" scoped>

.table-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -15px;
}

.table-button:not(:last-child) {
  margin-right: 2px;
}

</style>
