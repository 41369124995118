<template lang="html">
  <div class="area-selector">
    <VueSelectize
      v-if="selectedArea"
      v-model="selectedArea"
      :options="availableAreas"
      :placeholder="placeholder"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.AreaSelectorInfo</IxRes>
      </template>
    </VueSelectize>
    <VueSelectize
      v-if="subAreas && selectedSubArea"
      v-model="selectedSubArea"
      :options="subAreas"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

import VueSelectize from '@components/VueSelectize'

export default {

  components: {
    VueSelectize
  },

  data () {
    return {
      placeholder: 'Bereich auswählen',
      area: null,
      subArea: null
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'availableAreas'
    ]),
    subAreas () {
      return this.area && this.area.subAreas ? this.area.subAreas : null
    },

    selectedArea: {
      get () {
        return this.area
      },
      set (value) {
        this.area = value
        if (!this.subAreas) {
          this.setCurrentArea(value)
        } else {
          this.selectedSubArea = this.subAreas[0]
        }
      }
    },

    selectedSubArea: {
      get () {
        return this.subArea
      },
      set (value) {
        this.subArea = value
        this.setCurrentArea(value)
      }
    }

  },

  methods: {
    ...mapMutations('realEstate', [
      'setCurrentArea'
    ]),

    ...mapActions('realEstate', [
      'loadAvailableAreas'
    ])
  },

  watch: {
    availableAreas () {
      this.selectedArea = this.availableAreas[0]
    }
  },

  mounted () {
    this.loadAvailableAreas()
  }
}
</script>

<style lang="scss">
.area-selector{
  padding: 10px;
}
</style>
