<template>
  <SimpleTable
    ref="table"
    :columns="columnDefinition"
    :data="rows"
    :highlight="selected"
    @click="select"
  />
</template>

<script>
import SimpleTable from '@components/table/SimpleTable'

import {mapResources} from '@helpers/vuex'
import {formatAsHectare} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {

  components: {
    SimpleTable
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('table')
  ],

  props: {
    rows: Array,
    isSales: Boolean
  },

  data () {
    return {

      selected: (row) => {
        if (this.selection.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ]),

    columnDefinition () {
      return {
        date: {
          title: this.SR_ModelProperties.Date
        },
        leaseContract: {
          title: this.SR_ModelProperties.LeaseContract
        },
        partner: {
          title: this.SR_ModelProperties.Partner
        },
        type: {
          title: this.SR_ModelProperties.Type
        },
        nextPossibleTermEnd: {
          title: this.SR_ModelProperties.NextPossibleTerm
        },
        totalDurationOfTheContractSoFarEnd: {
          title: this.SR_ModelProperties.TotalDurationOfTheContractSoFar
        }
      }
    }
  }
}

</script>
