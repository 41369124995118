<template lang="html">
  <Search
    v-model="searchString" :data="data"
    :keys="['title']"
    @result="onSearch"
  />
</template>
<script>
import Search from '@components/search/Search'

export default {

  components: {
    Search
  },

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      searchString: ''
    }
  },

  computed: {

  },

  methods: {
    onSearch (result) {
      this.$emit('onSearch', result)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
