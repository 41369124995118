<template>
  <div class="contract-aggregation-view">
    <div class="summaries">
      <div>Summe der in Kv enthaltenen Flächen in ha:</div>
      <div>{{ total_area }}</div>
      <div>Anzahl der Verträge:</div>
      <div>{{ number_contracts }}</div>
      <div>Anzahl der in Verträgen enthaltenen Flurstücke:</div>
      <div>{{ number_landparcels }}</div>
    </div>

    <div><h4>Durchschnittliche Preise landwirtschaftlich genutzter Flächen nach Gemarkung</h4></div>

    <div>
      <div class="chart-scrollpane">
        <div ref="chartContainer" class="chartContainer" :style="{'max-height': height + 'px', height: height + 'px', width: chartWidth + 'px'}">
          <BarChart
            :data="rows"
            :width="chartWidth"
            :height="height"
            :additional-options="chartOptions"
            horizontal
          />
        </div>
      </div>

      <canvas id="axisCanvas" class="axisCanvas" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {format} from 'src/js/i18n/conversion'

import BarChart from '@components/charts/BarChart'

export default {

  components: {
    BarChart
  },

  data () {
    return {
      chartWidth: 500,
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) =>
              format(tooltipItem.xLabel, 'f2') + ' €/ha'
          }
        },
        scales: {
          yAxes: [{
            barThickness: 'flex',
            maxBarThickness: 20
          }],
          xAxes: [{
            position: 'top',
            beginAtZero: true,
            ticks: {
              callback: (value) => format(value, 'f2') + ' €/ha'
            }
          }]
        },
        animation: {
          onComplete: function ({chart}) {
            const sourceCanvas = chart.canvas
            const copyHeight = chart.scales['x-axis-0'].height
            const copyWidth = sourceCanvas.width
            if (copyHeight <= 0 || copyWidth <= 0) {
              return
            }
            const targetCtx = document.getElementById('axisCanvas').getContext('2d')
            targetCtx.canvas.width = copyWidth
            targetCtx.canvas.height = copyHeight
            targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight)
          }
        }
      }
    }
  },

  computed: {
    ...mapState('realEstate/contract', [
      'total_area',
      'number_contracts',
      'number_landparcels',
      'avg_by_localdistrict'
    ]),

    height () {
      return this.rows.length * 30
    },

    rows () {
      return this.avg_by_localdistrict.map(item => ({
        label: item.local_district,
        value: item.price_per_ha,
        color: '#147f00'
      }))
        .sort((left, right) =>
          left.value === right.value
            ? 0
            : left.value < right.value
              ? 1
              : -1)
    }
  },

  updated () {
    this.chartWidth = this.$refs.chartContainer.clientWidth
  }
}

</script>

<style lang="scss">

.contract-aggregation-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .summaries
  {
    font-size: larger;

    display: inline-grid;
    grid-column-gap: 5px;
    grid: auto-flow / repeat(2, 1fr);

    div:nth-child(odd) {
      font-weight: bold;
      text-align: right;
    }

    margin: {
      top: 10px;
      bottom: 10px;
    }
  }

  .chartContainer {
    position: relative;
    width: 100%;
  }

  .chart-scrollpane {
    position: relative;
    overflow-y: auto;
    height: 100%;
  }

  .axisCanvas{
    position: absolute;
    top: 0;
    left: 0;
    background: white;
  }

  div {
    flex: 0 0 auto;
  }

  div:last-child {
    position: relative;
    flex: 1 1 auto;
    height: 10%;
  }
}

</style>
