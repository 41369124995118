<template>
  <div class="segment-form">
    <VueSelectize
      v-model="usage"
      :fields="{value: 'uid', text: 'name'}"
      :options="usages"
      allow-empty
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Usage</IxRes>
      </template>
    </VueSelectize>

    <NumericInput
      v-model="groundpoints"
      integer
      hide-description
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Groundpoints</IxRes>
      </template>
    </NumericInput>

    <NumericInput
      v-model="area"
      addon="ha"
      format="f4"
      hide-description
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Size</IxRes>
      </template>
    </NumericInput>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'

export default {

  components: {
    VueSelectize,
    NumericInput
  },

  props: {
    value: Object
  },

  data () {
    return {
      usages: []
    }
  },

  computed: {
    usage: {
      get () {
        return this.value && this.value.usage ? this.value.usage : null
      },
      set (value) {
        this.$emit('input', {usage: value, groundpoints: this.value.groundpoints, area: this.value.area, uid: this.value.uid})
      }
    },

    groundpoints: {
      get () {
        return this.value && this.value.groundpoints >= 0 ? this.value.groundpoints : null
      },
      set (value) {
        this.$emit('input', {usage: this.value.usage, groundpoints: value, area: this.value.area, uid: this.value.uid})
      }
    },

    area: {
      get () {
        return this.value && this.value.area ? this.value.area : null
      },
      set (value) {
        this.$emit('input', {usage: this.value.usage, groundpoints: this.value.groundpoints, area: value, uid: this.value.uid})
      }
    }
  },

  methods: {
    _loadusages () {
      axios.get('/realestate/masterdatausagetype/GetUsageTypes')
        .then(result => {
          this.usages = result.data
        })
    }
  },

  mounted () {
    this._loadusages()
  }
}

</script>

<style lang="scss">

@import "../../../styles/_grid";

.segment-form {
  @include makeGrid(1);
}

</style>
