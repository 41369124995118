<template>
  <IxMapScope class="map-contents">
    <IxMap ref="map" />
    <IxBingLayer imagery="hybrid" />
    <IxVectorLayer
      v-if="showSingleSelectionLayer" :features="singleSelectionFeatures"
      :vector-style="vectorLayerStyle"
      auto-focus
    />
    <ReVectorLayer
      v-if="showPolygonAndClusterLayer" :feature-style="featureStyleFunction"
      :data-for-loading="loaderData"
      @zoom="onZoom"
    />
  </IxMapScope>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

import Group from 'ol/layer/Group'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'

import IxMap from '@components/map/IxMap'
import IxMapScope from '@components/map/IxMapScope'
import IxBingLayer from '@components/map/IxBingLayer'
import IxVectorLayer from '@components/map/IxVectorLayer'
import ReVectorLayer from './ReVectorLayer'

import {makeDefaultLayerGroup} from '@helpers/openlayers/bing'

import GeoJSON from 'ol/format/GeoJSON'
import Wkt from 'ol/format/WKT'

const clusterText = new Text({
  text: '',
  fill: new Fill({color: 'rgba(255, 255, 255, 0.8)'})
})

const clusterImage = new Circle({
  radius: 15,
  fill: new Fill({color: 'rgba(255, 150, 0, 0.8)'}),
  stroke: new Stroke({color: 'rgba(0, 0, 0, 0.5)', width: 1})
})

const clusterStyle = new Style({
  image: clusterImage,
  text: clusterText
})

const polygonStyle = new Style({
  stroke: new Stroke({color: 'rgba(155, 216, 255, 0.8)', width: 2}),
  fill: new Fill({color: 'rgba(0, 60, 190, 0.5)'})
})

export default {

  provide () {
    return {
      getMap: () => this.$refs.map.map
    }
  },

  components: {
    IxBingLayer,
    IxMap,
    IxMapScope,
    IxVectorLayer,
    ReVectorLayer
  },

  data () {
    return {
      layerGroup: new Group(),
      singleSelectionFeatures: null,
      zoom: 10,
      maxZoomForCluster: 11,
      featureType: 'cluster'
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentArea',
      'currentState',
      'currentOrgUnit',
      'leftSidebarPinned',
      'selection'
    ]),

    vectorLayerStyle () {
      return polygonStyle
    },

    showSingleSelectionLayer () {
      return this.currentArea && this.currentOrgUnit && this.selection.length === 1 && this.singleSelectionFeatures
    },

    showPolygonAndClusterLayer () {
      return !this.showSingleSelectionLayer && this.currentArea && this.currentOrgUnit
    },

    stateHasGeometries () {
      return this.currentState && this.currentState.geometry && this.currentState.geometry.length > 0
    },

    loaderData () {
      if (!this.currentOrgUnit || !this.currentOrgUnit.uid || !this.currentArea || !this.currentArea.id) {
        return null
      }
      let url = `/RealEstate/${this.currentOrgUnit.uid}/${this.currentArea.id}/features`
      let ids = this.currentArea.id === 'lease' ? this.selection.map(item => item.uid) : this.selection.map(item => item.Uid)

      let data = {
        url: url,
        ids: ids,
        orgUnit: this.currentOrgUnit.uid,
        featureType: this.featureType
      }
      return data
    }
  },

  methods: {

    updateFeaturesFromState () {
      if (this.stateHasGeometries) {
        const geoJsonReader = new GeoJSON()
        const wktReader = new Wkt()
        let wktFeatures = this.currentState.geometry.map(entry => wktReader.readFeature(entry.geometry))
        this.singleSelectionFeatures = geoJsonReader.writeFeaturesObject(wktFeatures)
      } else this.singleSelectionFeatures = null
    },

    updateFeaturesFromServer () {
      let id = this.currentArea.id === 'lease' ? this.selection[0].uid : this.selection[0].Uid
      let url = `/RealEstate/${this.currentOrgUnit.uid}/${this.currentArea.id}/${id}/feature`
      axios.get(url)
        .then(result => {
          this.singleSelectionFeatures = result.data && result.data.features ? result.data : []
        })
    },

    featureStyleFunction (feature) {
      if (feature.values_.num_items) {
        let numLandParcels = feature.values_.num_items
        clusterImage.setRadius(15 + Math.round(numLandParcels / 500))
        clusterText.setText(numLandParcels.toString())
        return [clusterStyle]
      } else {
        return [polygonStyle]
      }
    },

    onZoom (value) {
      this.zoom = value
      this.featureType = value > this.maxZoomForCluster ? 'polygon' : 'cluster'
    }
  },

  watch: {

    leftSidebarPinned () {
      this.$refs.map.map.updateSize()
    },

    selection (value) {
      if (value.length === 1) {
        if (this.currentArea.id === 'landparcels') {
          this.updateFeaturesFromState()
        } else {
          this.updateFeaturesFromServer()
        }
      }
    }
  },

  created () {
    const bingGroup = makeDefaultLayerGroup({culture: this.$store.state.i18n.locale})
    this.layerGroup.getLayers().insertAt(0, bingGroup)
  }
}

</script>

<style lang="scss" scoped>
  .map-contents {
    width: auto;
    height: 100%;
  }
</style>
