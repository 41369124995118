<template>
  <div class="property-type-selector">
    <select-create
      v-model="selectedPropertyType"
      :options="propertyTypes"
      :fields="fields"
      :title="modalTitle"
      :label="selectLabel"
      :make-new-item="makeNewPropertyType"
      :allow-create="true"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <div slot-scope="{value: model}" class="create-property-type-dialog">
        <text-input v-if="model" ref="firstInput" v-model="model.name" />
      </div>
    </select-create>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import newGuid from '@helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    SelectCreate,
    TextInput
  },

  props: {
    label: String,
    value: Object
  },

  data () {
    return {
      propertyTypes: [],
      newPropertyTypeName: null,
      fields: {
        text: 'name',
        value: 'uid'
      }
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),
    modalTitle () {
      return 'Grundstückstyp erfassen'
    },
    selectLabel () {
      return this.label ? this.label : 'Grundstückstyp'
    },

    selectedPropertyType: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewPropertyType (input) {
      return {name: input, uid: newGuid()}
    },

    onSave (newPropertyType) {
      const dto = {
        uid: newPropertyType.value,
        name: newPropertyType.text,
        fkDataOwner: this.currentOrgUnit.uid
      }

      axios.post('/realestate/masterdatapropertytype/create', dto)
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()
    },

    _loadOptions () {
      axios.get('/RealEstate/MasterDataPropertyType/GetPropertyTypes', {params: {entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.propertyTypes = result.data
        })
    }
  },

  mounted () {
    this._loadOptions()
  }
}

</script>

<style lang="scss">

</style>
