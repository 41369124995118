<template>
  <keep-alive>
    <!-- TODO: check if keep-alive is necessary -->
    <ReMap />
  </keep-alive>
</template>

<script>
import {mapGetters} from 'vuex'

import ReMap from '@realestate/components/right-sidebar/ReMap'

export default {

  components: {
    ReMap
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentArea',
      'selection'
    ])
  }
}

</script>

<style lang="scss" scoped>

</style>
