<template>
  <div>
    <div class="payment-modal-content">
      <ContactSelect
        v-model="recipient"
        contact-type="Other"
        allow-empty
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Recipient</IxRes>
        </template>
      </ContactSelect>
      <TextInput v-model="transactionComment">
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.TransactionComment</IxRes>
        </template>
      </TextInput>
      <VueDateTimePicker v-model="dateDue" iso>
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.DateDue</IxRes>
        </template>
      </VueDateTimePicker>
      <VueDateTimePicker v-model="datePaid" iso>
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.DatePaid</IxRes>
        </template>
      </VueDateTimePicker>
      <TextInput v-model="lessorIBAN">
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.IBAN</IxRes>
        </template>
      </TextInput>
      <TextInput v-model="lessorBIC">
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.BIC</IxRes>
        </template>
      </TextInput>
      <NumericInput
        v-model="amount"
        format="f2"
        hide-description
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Amount</IxRes>
        </template>
      </NumericInput>
      <VueSelectize
        v-model="currency"
        allow-empty
        :options="availableCurrencies" :fields="{text: 'name', label: 'short', value: 'uid'}"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.Currency</IxRes>
        </template>
      </VueSelectize>
    </div>
    <TextInput v-model="typeOfPayment">
      <template #label>
        <IxRes>ModelProperties.SR_ModelProperties.TypeOfPayment</IxRes>
      </template>
    </TextInput>
    <BsCheckbox v-model="vetoed">
      <IxRes>ModelProperties.SR_ModelProperties.Vetoed</IxRes>
    </BsCheckbox>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import ContactSelect from '@realestate/components/contacts/ContactSelect'

export default {
  components: {
    BsCheckbox,
    NumericInput,
    ContactSelect,
    TextInput,
    VueDateTimePicker,
    VueSelectize
  },

  props: {
    value: Object
  },

  data () {
    return { }
  },

  computed: {
    ...mapGetters('realEstate', [
      'availableCurrencies'
    ]),

    recipient: {
      get () {
        return this.value ? this.value.recipient : null
      },
      set (newValue) {
        this.emitInput({recipient: newValue})
      }
    },

    transactionComment: {
      get () {
        return this.value ? this.value.transactionComment : null
      },
      set (newValue) {
        this.emitInput({transactionComment: newValue})
      }
    },

    dateDue: {
      get () {
        return this.value ? this.value.dateDue : null
      },
      set (newValue) {
        this.emitInput({dateDue: newValue})
      }
    },

    datePaid: {
      get () {
        return this.value ? this.value.datePaid : null
      },
      set (newValue) {
        this.emitInput({datePaid: newValue})
      }
    },

    lessorIBAN: {
      get () {
        return this.value ? this.value.lessorIBAN : null
      },
      set (newValue) {
        this.emitInput({lessorIBAN: newValue})
      }
    },

    lessorBIC: {
      get () {
        return this.value ? this.value.lessorBIC : null
      },
      set (newValue) {
        this.emitInput({lessorBIC: newValue})
      }
    },

    amount: {
      get () {
        return this.value ? this.value.amount : null
      },
      set (newValue) {
        this.emitInput({amount: newValue})
      }
    },

    currency: {
      get () {
        return this.value ? this.value.currency : null
      },
      set (newValue) {
        this.emitInput({currency: newValue})
      }
    },

    vetoed: {
      get () {
        return this.value ? this.value.vetoed : null
      },
      set (newValue) {
        this.emitInput({vetoed: newValue})
      }
    },

    typeOfPayment: {
      get () {
        return this.value ? this.value.typeOfPayment : null
      },
      set (newValue) {
        this.emitInput({typeOfPayment: newValue})
      }
    }
  },

  methods: {
    emitInput ({
      uid = this.value.uid,
      recipient = this.recipient,
      transactionComment = this.transactionComment,
      dateDue = this.dateDue,
      datePaid = this.datePaid,
      lessorIBAN = this.lessorIBAN,
      lessorBIC = this.lessorBIC,
      amount = this.amount,
      currency = this.currency,
      vetoed = this.vetoed,
      typeOfPayment = this.typeOfPayment
    }) {
      this.$emit('input', {uid, recipient, transactionComment, dateDue, datePaid, lessorIBAN, lessorBIC, amount, currency, vetoed, typeOfPayment})
    }
  }
}

</script>

<style lang="scss">
  .payment-modal-content {
    @import "../../styles/_grid";
    @include makeGrid(2);
  }

</style>
