<template>
  <div v-if="singleSelection" class="single-container">
    <header>
      <BsTabs v-model="activeTab" :tabs="currentTabs" />
    </header>

    <div class="scroll-container">
      <main>
        <keep-alive>
          <!-- TODO: check if keep-alive is necessary -->
          <component :is="activeTab" />
        </keep-alive>
      </main>
    </div>
  </div>
  <div v-else class="aggregation-container">
    <component :is="currentAggregation" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'

import BsTabs from '@components/bootstrap/BsTabs'

import ContractCommon from '@realestate/components/contract/ContractCommon'
import ContractFacilitiesEquipment from '@realestate/components/contract/ContractFacilitiesEquipment'
import ContractFinances from '@realestate/components/contract/ContractFinances'
import ContractLandParcel from '@realestate/components/contract/ContractLandParcel'
import SalesAggregation from '@realestate/components/contract/aggregations/SalesAggregation'
import PurchaseAggregation from '@realestate/components/contract/aggregations/PurchaseAggregation'

import FacilityCommon from '@realestate/components/facility/FacilityCommon'
import FacilityInsurance from '@realestate/components/facility/FacilityInsurance'
import FacilityPart from '@realestate/components/facility/FacilityPart'

import LandParcelCommon from '@realestate/components/land-parcel/LandParcelCommon'
import LandParcelContracts from '@realestate/components/land-parcel/LandParcelContracts'
import LandParcelEasements from '@realestate/components/land-parcel/LandParcelEasements'
import LandParcelHistory from '@realestate/components/land-parcel/LandParcelHistory'
import LandParcelMortgages from '@realestate/components/land-parcel/LandParcelMortgages'
import LandParcelPredecessorsSuccessors from '@realestate/components/land-parcel/LandParcelPredecessorsSuccessors'
import LandParcelSegments from '@realestate/components/land-parcel/LandParcelSegments'

import LandParcelAggregation from '@realestate/components/land-parcel/aggregations/LandParcelAggregation'
import LeaseAggregation from '@realestate/components/lease/LeaseAggregation'

import LeaseContractCommon from '@realestate/components/lease/LeaseContractCommon'
import LeaseContractFinances from '@realestate/components/lease/LeaseContractFinances'
import LeaseContractAmendment from '@realestate/components/lease/LeaseContractAmendment'
import LeaseContractTerm from '@realestate/components/lease/LeaseContractTerm'
import LeaseContractAreas from '@realestate/components/lease/LeaseContractAreas'

import PropertyCommon from '@realestate/components/property/PropertyCommon'
import PropertySegments from '@realestate/components/property/PropertySegments'
import PropertySiteDevelopment from '@realestate/components/property/PropertySiteDevelopment'
import PropertyTaxNotice from '@realestate/components/property/PropertyTaxNotice'
import PropertyFacilities from '@realestate/components/property/PropertyFacilities'

export default {

  components: {
    BsTabs,

    LandParcelAggregation,
    LeaseAggregation,

    ContractCommon,
    ContractFacilitiesEquipment,
    ContractFinances,
    ContractLandParcel,
    SalesAggregation,
    PurchaseAggregation,

    FacilityCommon,
    FacilityInsurance,
    FacilityPart,

    LandParcelCommon,
    LandParcelContracts,
    LandParcelEasements,
    LandParcelHistory,
    LandParcelMortgages,
    LandParcelPredecessorsSuccessors,
    LandParcelSegments,

    LeaseContractCommon,
    LeaseContractFinances,
    LeaseContractAmendment,
    LeaseContractTerm,
    LeaseContractAreas,

    PropertyCommon,
    PropertySegments,
    PropertySiteDevelopment,
    PropertyFacilities,
    PropertyTaxNotice
  },

  data () {
    return {
      dataChanged: false,
      activeTab: null
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapGetters('realEstate', [
      'currentArea',
      'selection'
    ]),

    singleSelection () {
      return this.selection.length === 1
    },

    currentAggregation () {
      if (!this.currentArea || !this.currentArea.id) return ''

      switch (this.currentArea.id) {
      // TODO: un-comment when components exist

      case 'lease': return 'LeaseAggregation'
      case 'landparcels': return 'LandParcelAggregation'
      // case 'properties': return 'PropertyAggregation'
      // case 'facilities': return 'FacilityAggregation'
      case 'purchases': return 'PurchaseAggregation'
      case 'sales': return 'SalesAggregation'
      default: return 'LandParcelAggregation'
      }
    },

    currentTabs () {
      switch (this.currentArea ? this.currentArea.id : null) {
      case 'lease':
        return [
          {title: this.SR_RealEstate.Common, value: 'LeaseContractCommon'},
          {title: this.SR_RealEstate.Areas, value: 'LeaseContractAreas'},
          {title: this.SR_RealEstate.Finances, value: 'LeaseContractFinances'},
          {title: this.SR_RealEstate.Terms, value: 'LeaseContractTerm'},
          {title: this.SR_RealEstate.Amendments, value: 'LeaseContractAmendment'}
        ]
      case 'landparcels':
        return [
          {title: this.SR_RealEstate.Common, value: 'LandParcelCommon'},
          {title: this.SR_RealEstate.Segments, value: 'LandParcelSegments'},
          {title: this.SR_RealEstate.Mortgages, value: 'LandParcelMortgages'},
          {title: this.SR_RealEstate.Easements, value: 'LandParcelEasements'},
          {title: this.SR_RealEstate.Contracts, value: 'LandParcelContracts'},
          {title: this.SR_RealEstate.PredecessorSuccessor, value: 'LandParcelPredecessorsSuccessors'},
          {title: this.SR_RealEstate.History, value: 'LandParcelHistory'}
        ]
      case 'properties':
        return [
          {title: this.SR_RealEstate.Common, value: 'PropertyCommon'},
          {title: this.SR_RealEstate.Segments, value: 'PropertySegments'},
          {title: this.SR_RealEstate.SiteDevelopment, value: 'PropertySiteDevelopment'},
          {title: this.SR_RealEstate.TaxNotice, value: 'PropertyTaxNotice'},
          {title: this.SR_RealEstate.Facilities, value: 'PropertyFacilities'}
        ]
      case 'facilities':
        return [
          {title: this.SR_RealEstate.Common, value: 'FacilityCommon'},
          {title: this.SR_RealEstate.FacilityParts, value: 'FacilityPart'},
          {title: this.SR_RealEstate.Insurance, value: 'FacilityInsurance'}
        ]
      case 'purchases':
      case 'sales':
        return [
          {title: this.SR_RealEstate.Common, value: 'ContractCommon'},
          {title: this.SR_RealEstate.LandParcels, value: 'ContractLandParcel'},
          {title: this.SR_RealEstate.FacilitiesAndEquipment, value: 'ContractFacilitiesEquipment'},
          {title: this.SR_RealEstate.Finances, value: 'ContractFinances'}
        ]
      default: return []
      }
    },
    currentAreaId () {
      return this.currentArea ? this.currentArea.id : null
    }
  },
  watch: {
    currentTabs () {
      if (this.currentTabs.length) {
        this.activeTab = this.currentTabs[0].value
      } else {
        this.activeTab = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .single-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding-top: 5px;
  }

  .scroll-container {
    flex: 1 0 auto;
    position: relative;
    overflow-y: auto;
  }

  .aggregation-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  main {
    position: absolute;
    width: 100%;
    padding-right: 5px;
  }
</style>
