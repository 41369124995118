<template lang="html">
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  provide () {
    return {
      registerMap: map => {
        if (this.map) {
          throw new Error('registerMap was called twice')
        }
        this.map = map
        this.resolve(map)
      },
      getMap: () => this.promise
    }
  },
  created () {
    this.promise = new Promise(resolve => {
      this.resolve = resolve
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
