var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lease-area-container"},[_c('div',{staticClass:"amendments-container"},[_c('label',[_c('IxRes',[_vm._v("Areas.RealEstate.SR_RealEstate.Amendments")])],1),_c('div',{staticClass:"amendments-list"},_vm._l((_vm.amendments),function(amendment,index){return _c('ToggleButton',{key:index,attrs:{"value":_vm.currentAmendment,"toggle-value":amendment,"large":""},on:{"input":function($event){return _vm.setCurrentAmendment(amendment)}}},[_c('Slot',[_vm._v("\n          "+_vm._s(_vm.formatISODate(amendment.validOn))+"\n        ")])],1)}),1),_c('div',{staticClass:"table-container"},[_c('SimpleTable',{ref:"segments",attrs:{"columns":_vm.columnDefinition,"data":_vm.areas,"highlight":_vm.selected},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value,"target":"_blank","data-ajax":"false"}},[_c('span',{staticClass:"glyphicon glyphicon-circle-arrow-right"})])]}},{key:"sizeActual",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"pull-right"},[_vm._v("\n            "+_vm._s(value)+"\n          ")])]}},{key:"header-sizeContractual",fn:function(ref){
var title = ref.title;
return [_c('span',[_vm._v(_vm._s(title))]),_c('span',{staticClass:"fa fa-pencil"})]}},{key:"sizeContractual",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('span',{staticClass:"input-field"},[_c('NumericInput',{staticClass:"re-numeric",attrs:{"value":value,"format":"f4","hide-description":""},on:{"input":function($event){return _vm.onSetSize(row, $event)}}})],1)]}}])}),_c('TableButtons',{attrs:{"selected-items":_vm.selection.segments,"enable-edit":false,"enable-print":false},on:{"add":_vm.onAdd,"delete":_vm.onDelete}})],1),(_vm.showModal)?_c('SegmentModal',{attrs:{"title":_vm.SR_RealEstate.AddSegmentSearchArea,"show-modal":_vm.showModal},on:{"hidden":_vm.closeModal,"addSegment":_vm.addSegment}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }