<template>
  <div class="usage-selector">
    <select-create
      v-model="usage"
      :options="usageOptions"
      :fields="fields"
      :title="modalTitle"
      :label="selectLabel"
      :make-new-item="makeNewUsage"
      :allow-create="true"
      @type="onTyping"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <div v-if="value" slot-scope="{value: model}" class="create-usage-dialog">
        <text-input v-if="model" v-model="model[fields.text]" label="Name" />
      </div>
    </select-create>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'

import newGuid from 'src/js/helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    SelectCreate,
    TextInput
  },

  props: {
    label: String,
    fields: Object,
    value: Object
  },

  data () {
    return {
      usageOptions: []
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),

    modalTitle () {
      return 'Nutzungsart erfassen'
    },

    selectLabel () {
      return this.label ? this.label : 'Usage'
    },

    usage: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewUsage (input) {
      return {
        name: input,
        uid: newGuid()
      }
    },

    onTyping (input) {
      this._loadOptions(input)
    },

    onSave (newUsage) {
      const usage = {
        uid: newUsage.value,
        name: newUsage.text,
        fkDataOwner: this.currentOrgUnit.uid
      }
      axios.post('RealEstate/Facility/CreateFacilityUsage', usage)
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()
    },

    _loadOptions (input) {
      axios.get('RealEstate/Facility/GetFacilityTypes', {params: {entityId: this.currentOrgUnit.uid, query: input}})
        .then(result => {
          this.usageOptions = result.data
        })
    }
  },

  watch: {
    usage () {
      this.$emit('input', this.usage)
    }
  }
}

</script>

<style lang="scss">
</style>
