<template lang="html">
  <div>
    <tree v-model="selectedOrgUnit" :nodes="nodes" />
  </div>
</template>

<script>

import Tree from '@components/nav/Tree'

function convertOrgUnitNode (orgUnit) {
  return {
    text: orgUnit.title,
    icon: {
      iconName: 'ix-tree-orgunit',
      style: '',
      tooltip: 'Organisationseinheit'
    },
    isExpanded: false,
    extraIcons: [],
    liClass: '',
    isActive: false,
    data: {
      template: 'OrgUnit',
      uid: ''
    },
    href: '#',
    id: orgUnit.uid,
    children: orgUnit.children ? orgUnit.children.map(convertOrgUnitNode) : [],
    entity: orgUnit
  }
}

export default {
  components: {
    Tree
  },

  props: {
    value: {type: Object, default: null},
    orgUnits: Array
  },

  computed: {
    nodes () {
      return this.orgUnits.map(convertOrgUnitNode)
    },
    selectedOrgUnit: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
