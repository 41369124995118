<template>
  <div class="business-object-selector">
    <SelectCreate
      v-model="selectedBusinessObject"
      :options="businessObjects"
      :fields="fields"
      :title="modalTitle"
      :label="label"
      :make-new-item="makeNewBusinessObject"
      :allow-create="true"
      @type="onTyping"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @cancel="onCancel"
    >
      <div
        slot-scope="{value: model}"
        class="create-business-object-dialog"
      >
        <TextInput
          v-if="model"
          ref="firstInput"
          v-model="model.text"
        />
      </div>
    </SelectCreate>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import newGuid from '@helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    SelectCreate,
    TextInput
  },

  props: {
    value: {
      type: Object
    },
    fields: {
      type: Object
    },
    label: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      businessObjects: [],
      newBusinessObjectName: null
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),

    selectedBusinessObject: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewBusinessObject (input) {
      return {text: input, value: newGuid()}
    },

    onTyping (input) {
      this._loadOptions(input)
    },

    onSave (newBusinessObject) {
      const dto = {
        uid: newBusinessObject.value,
        name: newBusinessObject.text,
        fkDataOwner: this.currentOrgUnit.uid
      }

      axios.post('/RealEstate/MasterDataBusinessObject/Create', dto)
    },

    onCancel () {

    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()
    },

    _loadOptions (input) {
      axios.get('/RealEstate/MasterDataBusinessObject/GetBusinessObjectsSelectize', {params: {query: input, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.businessObjects = result.data
        })
    }
  },

  watch: {
    selectedBusinessObject () {
      this.$emit('input', this.selectedBusinessObject)
    }
  }
}

</script>
