<template>
  <div class="land-parcel-mortgages">
    <SimpleTable
      ref="mortgages"
      :columns="columnDefinition"
      :data="mortgages"
      :highlight="selected"
    >
      <template slot="amount" slot-scope="{value}">
        <div class="pull-right">
          {{ value | formatAsMoney }}
        </div>
      </template>

      <template slot="currency" slot-scope="{value}">
        <span>{{ value | formatAsCurrency }}</span>
      </template>
    </SimpleTable>

    <TableButtons
      :selected-items="selection.mortgages"
      :enable-print="false"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    />

    <BsModal v-model="isDialogOpen" :title="modalTitle">
      <MortgageForm v-model="editMortgage" />

      <template slot="footer">
        <IxButton
          icon="check" save
          large
          @click="update"
        >
          <IxRes>Common.SR_Common.Apply</IxRes>
        </IxButton>
        <IxButton cancel large @click="cancelDialog" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'
import {formatAsCurrency, formatAsMoney} from '@realestate/filters/numberFormatters'

import SimpleTable from '@components/table/SimpleTable'
import IxButton from '@components/IxButton'
import TableButtons from '@realestate/components/common/TableButtons'
import MortgageForm from '@realestate/components/land-parcel/mortgages/MortgageForm'
import BsModal from '@components/bootstrap/BsModal'

export default {

  components: {
    SimpleTable,
    IxButton,
    TableButtons,
    MortgageForm,
    BsModal
  },

  filters: {
    formatAsCurrency,
    formatAsMoney
  },

  mixins: [
    makeSelectionMixin('mortgages')
  ],

  data () {
    return {
      editMortgage: null,
      modalTitle: null,
      isDialogOpen: false,
      selected: (row) => {
        return this.selection.mortgages.find(ele => ele.uid === row.uid) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.mortgages'
    ]),

    columnDefinition () {
      return {
        amount: {
          title: this.SR_RealEstate.Amount
        },
        currency: {
          title: this.SR_RealEstate.Currency
        },
        bank: {
          title: this.SR_RealEstate.Bank
        },
        loanNumber: {
          title: this.SR_RealEstate.LoanNumber
        },
        removalAllowed: {
          title: this.SR_RealEstate.RemovalAllowed
        },
        remark: {
          title: this.SR_Common.Remark
        }
      }
    }
  },

  methods: {
    ...mapMutations('realEstate/landparcels', [
      'appendMortgage',
      'removeMortgage',
      'updateMortgage'
    ]),

    onAdd () {
      this.editMortgage = {
        bank: null,
        currency: null,
        loanNumber: null,
        amount: null,
        removalAllowed: false,
        remark: null
      }
      this.openDialog(this.SR_RealEstate.AddMortgage)
    },

    onEdit () {
      this.editMortgage = JSON.parse(JSON.stringify(this.selection.mortgages[0]))
      this.openDialog(this.SR_RealEstate.EditMortgage)
    },

    onDelete () {
      for (let mortgage of this.selection.mortgages) {
        this.removeMortgage(mortgage)
      }
    },

    update () {
      if (this.editMortgage.uid) {
        this.updateMortgage(this.editMortgage)
      } else {
        this.appendMortgage(this.editMortgage)
      }
      this.closeDialog()
    },

    cancelDialog () {
      this.closeDialog()
    },

    openDialog (title) {
      this.modalTitle = title
      this.isDialogOpen = true
    },

    closeDialog () {
      this.editItem = null
      this.isDialogOpen = false
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.land-parcel-mortgages {
  @include makeGrid(1);
}

</style>
