import Tile from 'ol/layer/Tile'
import BingMaps from 'ol/source/BingMaps'
import Group from 'ol/layer/Group'

import {config} from 'src/js/infrastructure'

export const makeBingLayer = ({id, name, imagerySet, culture, visible = false}) => {
  const layer = new Tile({
    source: new BingMaps({
      key: config.bingApiKey,
      imagerySet,
      culture
    }),
    visible,
    preload: Infinity
  })
  layer.setProperties({
    id,
    name
  })
  return layer
}

export const makeDefaultLayerGroup = ({culture}) => new Group({
  layers: [
    makeBingLayer({id: 'bing/aerial', name: 'Satellitenbilder', imagerySet: 'Aerial', visible: false, culture}),
    makeBingLayer({id: 'bing/hybrid', name: 'Hybrid', imagerySet: 'AerialWithLabels', visible: true, culture}),
    makeBingLayer({id: 'bing/road', name: 'Straßenkarte', imagerySet: 'Road', visible: false, culture})
  ]
})
