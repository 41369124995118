import {mapState} from 'vuex'

import {makeDefaultLayerGroup} from '@helpers/openlayers/bing'

export default {
  inject: [
    'getMap'
  ],
  props: {
    imagery: {
      type: String,
      validator (type) {
        return ['aerial', 'road', 'hybrid'].indexOf(type) !== -1
      },
      default: 'hybrid'
    }
  },
  data () {
    return {
      resolution: null
    }
  },
  computed: {
    ...mapState('i18n', [
      'locale'
    ])
  },
  methods: {
    recreate () {
      if (this.layer) {
        this.map.getLayers().remove(this.layer)
        this.layer = null
      }

      if (this.resolution > this.maxResolution) return

      this.layer = makeDefaultLayerGroup({culture: this.locale})
      this.layer.set('id', 'bing')

      this.map.getLayers().push(this.layer)
    }
  },
  watch: {
    locale: 'recreate'
  },
  updated () {
    if (this.map) {
      this.recreate()
    }
  },
  created () {
    this.getMap().then(map => {
      this.map = map
      this.recreate()
    })
  },
  beforeDestroy () {
    if (this.layer) {
      this.map.getLayers().remove(this.layer)
    }
  },
  render () {
    return null
  }
}
