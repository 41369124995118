<template>
  <div>
    <SimpleTable
      ref="amendments"
      :columns="columnDefinition"
      :data="rows"
      :highlight="highlightSelected"
    >
      <template slot="leaser" slot-scope="{value}">
        <span>{{ value | formatContactsAsString }}</span>
      </template>

      <template slot="lessor" slot-scope="{value}">
        <span>{{ value | formatContactsAsString }}</span>
      </template>
    </SimpleTable>

    <TableButtons
      :selected-items="selection.amendments"
      :enable-print="false"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    />

    <BsModal
      v-if="editedAmendment"
      v-model="showModal"
    >
      <template #title>
        <IxRes>Areas.RealEstate.SR_RealEstate.{{ modalTitle }}</IxRes>
      </template>
      <VueDateTimePicker
        v-model="editedAmendment.validOn"
        iso
      >
        <template #label>
          <IxRes>ModelProperties.SR_ModelProperties.Date</IxRes>
        </template>
      </VueDateTimePicker>
      <div slot="footer">
        <IxButton
          icon="check" save
          large
          @click="save"
        >
          <IxRes>Common.SR_Common.Apply</IxRes>
        </IxButton>
        <IxButton cancel large @click="cancelModal" />
      </div>
    </BsModal>
  </div>
</template>

<script>
import moment from 'moment'
import {mapMutations} from 'vuex'

import newGuid from '@helpers/new-guid'
import {mapFormFields, mapResources} from '@helpers/vuex'

import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

import IxButton from '@components/IxButton'
import SimpleTable from '@components/table/SimpleTable'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import BsModal from '@components/bootstrap/BsModal'
import TableButtons from '@realestate/components/common/TableButtons'

export default {

  components: {
    BsModal,
    IxButton,
    SimpleTable,
    TableButtons,
    VueDateTimePicker
  },

  filters: {
    formatContactsAsString (value) {
      let contactString = ''
      let index = 0
      value.forEach(contact => {
        contactString = contactString + contact.text
        index++
        if (index < value.length) {
          contactString += ' / '
        }
      })
      return contactString
    }
  },

  mixins: [
    makeSelectionMixin('amendments')
  ],

  data () {
    return {
      showModal: false,
      modalTitle: null,

      editedAmendment: null,

      highlightSelected: (row) => {
        return this.selection.amendments.includes(row) ? ['success'] : []
      }
    }
  },

  computed: {
    ...mapResources([
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/lease', [
      'current.amendments'
    ]),

    columnDefinition () {
      return {
        isStart: {
          title: this.SR_ModelProperties.IsStart,
          type: 'boolean'
        },
        validOn: {
          title: this.SR_ModelProperties.Date,
          type: 'date'
        },
        leaser: {
          title: this.SR_ModelProperties.Leaser
        },
        lessor: {
          title: this.SR_ModelProperties.Lessor
        },
        hasAreaChanges: {
          title: this.SR_ModelProperties.HasAreaChanges,
          type: 'boolean'
        },
        isExchangeAllowed: {
          title: this.SR_ModelProperties.IsExchangeAllowed,
          type: 'boolean'
        },
        isWaterPaidByLeaser: {
          title: this.SR_ModelProperties.IsWaterPaidByLeaser,
          type: 'boolean'
        },
        isTaxPaidByLeaser: {
          title: this.SR_ModelProperties.IsTaxPaidByLeaser,
          type: 'boolean'
        }
      }
    },

    rows () {
      return this.amendments || []
    }
  },

  methods: {

    ...mapMutations('realEstate/lease', [
      'appendAmendment',
      'removeAmendment',
      'updateAmendment'
    ]),

    onAdd () {
      this.modalTitle = 'CreateAmendmentTitle'
      this.editedAmendment = {
        validOn: moment()
      }
      this.showModal = true
    },

    onEdit () {
      this.modalTitle = 'EditAmendment'
      this.editedAmendment = this.selection.amendments[0]
      this.showModal = true
    },

    save () {
      // TODO: update editedAmendment with stuff from other tabs to correctly save all changes
      if (!this.editedAmendment.uid) {
        this.editedAmendment.uid = newGuid()
        this.appendAmendment(this.editedAmendment)
      } else {
        this.updateAmendment(this.editedAmendment)
      }
      this.closeModal()
    },

    cancelModal () {
      this.closeModal()
    },

    closeModal () {
      this.editedAmendment = null
      this.showModal = false
    },

    onDelete () {
      for (let amendment in this.selection.amendments) {
        this.removeAmendment(amendment)
      }
    }
  }

}

</script>

<style lang="scss">

</style>
