import { render, staticRenderFns } from "./CenterContent.vue?vue&type=template&id=0fa32967&scoped=true&lang=html&"
import script from "./CenterContent.vue?vue&type=script&lang=js&"
export * from "./CenterContent.vue?vue&type=script&lang=js&"
import style0 from "./CenterContent.vue?vue&type=style&index=0&id=0fa32967&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa32967",
  null
  
)

export default component.exports