<template lang="html">
  <div class="rocket-navigation">
    <hr>
    <div v-if="rootNodeSelected">
      <ListCard>
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.AllOrgUnits</IxRes>
        </template>
      </ListCard>
      <ListCard
        v-for="(node, i) in currentNode.children"
        :key="i"
        class="first-tier"
        :title="node.title"
        :subtitle="createSubtitle(node)"
        @click.native="selectNode(node)"
      />
    </div>
    <div v-else>
      <ListCard
        :title="currentNode.parentNode.title"
        @click.native="selectParentNode"
      />
      <ListCard
        :title="currentNode.title"
        class="first-tier"
        :subtitle="createSubtitle(currentNode)"
        selected
      />
      <ListCard
        v-for="(node, i) in currentNode.children"
        :key="i"
        class="second-tier"
        :title="node.title"
        :subtitle="createSubtitle(node)"
        @click.native="selectNode(node)"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'

import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },

  props: {
    orgUnits: Array,
    value: Object
  },

  data () {
    return {
      rootNode: {
        title: '',
        children: []
      },
      currentNode: {
        title: '',
        subtitle: '',
        children: {},
        parentNode: {}
      },
      rootNodeSelected: true
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ])
  },

  methods: {
    ...mapActions('realEstate', [
      'loadBankData',
      'loadLoanNumbers'
    ]),
    createSubtitle (node) {
      if (node.children) {
        return node.children.length > 1 ? node.children.length + ' ' + this.SR_RealEstate.SubordinateOrgUnits : '1 ' + this.SR_RealEstate.SubordinateOrgUnit
      } else return ''
    },

    selectNode (node) {
      this.rootNodeSelected = false
      if (!this.rootNode.title) {
        this.rootNode.title = this.SR_RealEstate.AllOrgUnits
      }
      node.parentNode = this.currentNode
      this.currentNode = node
      this.$emit('input', node)
      this.loadBankData()
      this.loadLoanNumbers()
    },

    selectParentNode () {
      if (this.currentNode.parentNode === this.rootNode) {
        this.rootNodeSelected = true
        this.$emit('input', null)
        this.currentNode = this.rootNode
      } else {
        this.$emit('input', this.currentNode.parentNode)
        this.currentNode = this.currentNode.parentNode
      }
    },

    setParentNode (node, parent) {
      node.parentNode = parent
      if (node.children) {
        node.children.forEach((child) => this.setParentNode(child, node))
      }
    },

    addParentsForOrgUnits () {
      this.orgUnits.forEach((orgUnit) => {
        orgUnit.parentNode = this.rootNode
        if (orgUnit.children) {
          orgUnit.children.forEach((child) => this.setParentNode(child, orgUnit))
        }
      })
    },

    updateSelection () {
      if (this.value && !this.value.parentNode) {
        this.addParentsForOrgUnits()
        this.rootNodeSelected = false
      }
      if (this.value) {
        this.currentNode = this.value
      }
    }
  },

  watch: {
    value () {
      this.updateSelection()
    },
    orgUnits () {
      if (this.orgUnits) {
        this.rootNode.children = this.orgUnits
        this.currentNode = this.rootNode
        this.updateSelection()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.first-tier {
  margin-left: 5%;
}
.second-tier {
  margin-left: 10%;
}

</style>
