<template>
  <div class="property-facilities">
    <NumericInput
      :value="facilityTotalValue"
      :addon="SR_Common.CurrencyDM"
      readonly
      format="f2"
      hide-description
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.SumBuildingValue</IxRes>
      </template>
    </NumericInput>

    <SimpleTable
      :columns="columnDefinition"
      :data="facilities"
      class="span-3"
    >
      <template slot="header-link">
        <span class="glyphicon dont-show" />
      </template>

      <template slot="evaluation" slot-scope="{value}">
        <div class="align-right">
          {{ value | formatAsMoney }}
        </div>
      </template>

      <template slot="link" slot-scope="{value}">
        <a :href="value" target="_blank" data-ajax="false">
          <span class="glyphicon glyphicon-circle-arrow-right" />
        </a>
      </template>
    </SimpleTable>
  </div>
</template>

<script>

import {mapResources, mapFormFields} from '@helpers/vuex'

import {formatAsMoney} from '@realestate/filters/numberFormatters'

import NumericInput from '@components/forms/NumericInput'
import SimpleTable from '@components/table/SimpleTable'

export default {

  components: {
    NumericInput,
    SimpleTable
  },
  filters: {
    formatAsMoney
  },
  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common',
      'ModelProperties.SR_ModelProperties'
    ]),

    ...mapFormFields('realEstate/properties', [
      'current.facilities'
    ]),

    facilityTotalValue () {
      return this.facilities
        .map(row => row.evaluation)
        .reduce((pre, curr) => pre + curr, 0)
    },

    columnDefinition () {
      return {
        link: {
          title: ' '
        },

        name: {
          title: this.SR_ModelProperties.Name
        },

        evaluation: {
          title: this.SR_ModelProperties.Evaluation
        },

        facilityType: {
          title: this.SR_ModelProperties.FacilityType
        }
      }
    }
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.property-facilities {
  @include makeGrid(3);

  .dont-show::before {
    white-space: pre;
    content: ' ';
  }
}

.align-right {
  text-align: right;
}

</style>
