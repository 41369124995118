<template>
  <div class="local-district-selector">
    <SelectCreate
      v-model="selectedLocalDistrict"
      :options="localDistricts"
      :fields="fields"
      :title="modalTitle"
      :label="internalLabel"
      :make-new-item="makeNewLocalDistrict"
      :allow-create="allowCreate"
      @type="onTyping"
      @save="onSave"
      @dialogOpened="onDialogOpened"
      @dialogClosed="onDialogClosed"
    >
      <div v-if="isDialogOpen" slot-scope="{value: model}" class="create-local-district-dialog">
        <VueSelectize
          ref="firstInput" v-model="selectedCountry"
          label="Land"
          :options="countries" :allow-empty="true"
          @input="onCountrySelected"
        />

        <VueSelectize
          v-model="selectedRegion" label="Bundesland"
          :options="regions"
          :allow-empty="true" @input="onRegionSelected"
        />

        <VueSelectize
          v-model="selectedDistrict" label="Landkreis"
          :options="districts"
          :allow-empty="true" @input="onDistrictSelected"
        />

        <TextInput v-if="model" v-model="model.text" :label="label" />

        <TextInput v-model="officialCode" label="Amtlicher Schlüssel" />
      </div>
    </SelectCreate>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import newGuid from '@helpers/new-guid'

import SelectCreate from '@realestate/components/common/SelectCreate'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    SelectCreate,
    TextInput,
    VueSelectize
  },

  props: {
    label: String,
    fields: Object,
    value: Object,
    allowCreate: {type: Boolean, default: true}
  },

  data () {
    return {
      localDistricts: [],
      newLocalDistrictName: null,
      selectedDistrict: null,
      countries: [undefined],
      regions: [],
      districts: [],
      currentDistrictId: null,
      isDialogOpen: false,
      officialCode: '',
      selectedCountry: null,
      selectedRegion: null
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit']),
    modalTitle () {
      return 'Gemarkung erfassen'
    },
    internalLabel () {
      return this.label ? this.label : 'Gemarkung'
    },

    selectedLocalDistrict: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makeNewLocalDistrict (input) {
      return {text: input, value: newGuid()}
    },

    onTyping (input) {
      this._loadOptions(input)
    },

    onSave (newLocalDistrict) {
      const dto = {
        uid: newLocalDistrict.value,
        name: newLocalDistrict.text,
        fkDistrict: this.selectedDistrictId,
        fkDataOwner: this.currentOrgUnit.uid,
        officialCode: this.officialCode
      }
      axios.post('/realestate/localdistricts/create', dto)
    },

    onDialogOpened () {
      // ToDo: We need a focus method for inputs
      // this.$refs.firstInput.focus()

      this.isDialogOpen = true

      axios.get('/realestate/country/getselectablecountries')
        .then(result => {
          this.countries = result.data
        })
    },

    onDialogClosed () {
      this.isDialogOpen = false
    },

    onCountrySelected (selectedCountry) {
      if (selectedCountry && selectedCountry.value) {
        axios.get('/realestate/region', {params: {country: selectedCountry.value}})
          .then(result => {
            this.regions = result.data.map(x => {
              return {text: x.name, value: x.uid}
            })
          })
      }
    },

    onRegionSelected (selectedRegion) {
      if (selectedRegion && selectedRegion.value) {
        axios.get('/realestate/district', {params: {region: selectedRegion.value, entityId: this.currentOrgUnit.uid}})
          .then(result => {
            this.districts = result.data
          })
      }
    },

    onDistrictSelected (selectedDistrict) {
      if (selectedDistrict && selectedDistrict.value) {
        this.selectedDistrictId = selectedDistrict.value
      }
    },

    _loadOptions (input) {
      axios.get('/realestate/localdistricts', {params: {query: input, entityId: this.currentOrgUnit.uid}})
        .then(result => {
          this.localDistricts = result.data
        })
    }
  }
}

</script>

<style lang="scss">
</style>
