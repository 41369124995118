<template lang="html">
  <div>
    <p> Tag Navigation (to be extended) </p>
  </div>
</template>

<script>

export default {
  components: {

  },

  props: {
    orgUnits: Array
  },

  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
