<template>
  <div class="property-site-development">
    <FunctionalZoningCategorySelect
      v-model="functionalZoningCategory"
      :label="SR_RealEstate.TypeOfBuildung"
    />

    <TextInput
      v-model="buildingLicenseRestrictions"
      multiline
      :rows="3"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.BuildingLicenseRestrictions</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import FunctionalZoningCategorySelect from '@realestate/components/functional-zoning-category/FunctionalZoningCategorySelect'

export default {

  components: {
    TextInput,
    FunctionalZoningCategorySelect
  },

  data () {
    return {}
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapGetters('realEstate', [
      'tableSelectionData'
    ]),

    ...mapFormFields('realEstate/properties', [
      'current.functionalZoningCategory',
      'current.buildingLicenseRestrictions'
    ])
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.property-site-development {
  @include makeGrid(2);
}

</style>
