<template lang="html">
  <div class="center-content">
    <div class="upper-row">
      <p class="lead">
        {{ orgUnitName }}
      </p>
      <p class="lead spacer">
        |
      </p>
      <p class="lead">
        {{ areaText }}
      </p>
    </div>
    <p class="number-possible-items">
      {{ formattedTotalItemsForEntity }} <IxRes>Areas.RealEstate.SR_RealEstate.{{ totalItemsForEntityText }}</IxRes>
    </p>
    <div class="table-content-row">
      <land-parcel-table
        v-if="currentAreaId === 'landparcels'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <facility-table
        v-else-if="currentAreaId === 'facilities'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <property-table
        v-else-if="currentAreaId === 'properties'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <contract-table
        v-else-if="currentAreaId === 'sales' || currentAreaId === 'purchases'"
        :is-sales="currentAreaId === 'sales'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <lease-contract-table
        v-else-if="currentAreaId === 'lease'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <lease-payment-table
        v-else-if="currentAreaId === 'lease/payments'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
      <lease-appointment-table
        v-else-if="currentAreaId === 'lease/appointments'"
        :rows="rows"
        :selection="selection"
        @selected="selectRowsDeferred"
        @sorted="onSorted"
        @scroll="onScroll"
      />
    </div>
    <div class="bottom-row">
      <IxButton
        large add
        class="menu-button"
        @click="addItem"
      />
      <IxButton
        large icon="trash"
        :colored="false"
        @click="removeItem"
      >
        <IxRes>Common.SR_Common.Delete</IxRes>
      </IxButton>
      <IxButton large icon="file" @click="exportDetails">
        <IxRes>Common.SR_Common.Export</IxRes>
      </IxButton>
      <IxButton large icon="print" @click="print">
        <IxRes>Common.SR_Common.Print</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash'
import {mapGetters, mapMutations, mapActions} from 'vuex'

import {format} from 'src/js/i18n/conversion'

import IxButton from '@components/IxButton'
import LandParcelTable from '@realestate/components/land-parcel/LandParcelTable'
import FacilityTable from '@realestate/components/facility/FacilityTable'
import PropertyTable from '@realestate/components/property/PropertyTable'
import ContractTable from '@realestate/components/contract/ContractTable'
import LeaseContractTable from '@realestate/components/lease/LeaseContractTable'
import LeasePaymentTable from '@realestate/components/lease/LeasePaymentTable'
import LeaseAppointmentTable from '@realestate/components/lease/LeaseAppointmentTable'

export default {

  components: {
    IxButton,
    ContractTable,
    FacilityTable,
    LandParcelTable,
    LeaseContractTable,
    LeasePaymentTable,
    LeaseAppointmentTable,
    PropertyTable
  },

  data () {
    return {
      lastScrollPosition: 0,
      sortParameter: null
    }
  },

  computed: {
    ...mapGetters('realEstate', [
      'currentOrgUnit',
      'currentArea',
      'endOfDataReached',
      'totalItemsForEntity',
      'rows',
      'selection'
    ]),

    orgUnitName () {
      return this.currentOrgUnit ? this.currentOrgUnit.title : ''
    },

    areaText () {
      return this.currentArea ? this.currentArea.text : ''
    },

    currentAreaId () {
      return this.currentArea ? this.currentArea.id : ''
    },

    formattedTotalItemsForEntity () {
      return format(this.totalItemsForEntity, 'f0g')
    },

    totalItemsForEntityText () {
      if (!this.currentArea || !this.currentArea.id || !this.currentOrgUnit) return ''
      switch (this.currentArea.id) {
      case 'landparcels': return 'LandParcels'
      case 'facilities': return 'Facilities'
      case 'properties': return 'Properties'
      case 'purchases': return 'PurchaseContractsHeader'
      case 'sales': return 'SalesContracts'
      case 'lease': return 'LeaseContractsHeader'
      default: return ''
      }
    }
  },

  methods: {
    ...mapMutations('realEstate', [
      'selectRows',
      'emptyTable',
      'resetScrollData'
    ]),

    ...mapActions('realEstate', [
      'loadTableData'
    ]),

    addItem () {
    },

    removeItem () {
    },

    exportDetails () {
    },

    print () {
    },

    selectRowsDeferred: debounce(function (rows) {
      this.selectRows(rows)
    }, 500),

    onScroll: debounce(function (event) {
      if (!this.endOfDataReached) {
        this.handleScroll(event)
      }
    }, 100),

    handleScroll (event) {
      const ele = event.target
      if (((ele.scrollTop + ele.clientHeight) / ele.scrollHeight) > 0.85 && this.lastScrollPosition < ele.scrollTop) {
        this.loadTableData({direction: 'next', sort: this.sortParameter})
      }
      this.lastScrollPosition = ele.scrollTop
    },

    onSorted (sortedEventArgs) {
      this.resetScrollData()
      if (sortedEventArgs === null) {
        this.loadTableData({direction: 'initial', sort: this.sortParameter})
      } else {
        this.sortParameter = [{
          column: sortedEventArgs.column.id,
          direction: sortedEventArgs.descending ? 'desc' : 'asc'
        }]
        this.loadTableData({direction: 'initial', sort: this.sortParameter})
      }
    }
  },

  watch: {
    currentOrgUnit () {
      this.emptyTable()
      this.resetScrollData()
      this.loadTableData({direction: 'initial', sort: this.sortParameter})
    },
    currentArea () {
      this.emptyTable()
      this.sortParameter = null
      this.resetScrollData()
      this.loadTableData({direction: 'initial', sort: this.sortParameter})
    }
  }
}
</script>

<style lang="scss" scoped>

.center-content {
  height: 100%;
  padding: 0px;
  display: flex;
  flex-flow: column;
}

.spacer {
  margin-left: 5px;
  margin-right: 5px;
}

.upper-row {
  flex: 0 1 auto;
  display: flex;
  padding: 5px;
}

.table-content-row {
  position: relative;
  flex: 0 1 auto;
  border-top: 1px solid grey;
  height: 100%;
  overflow-y: hidden;
}

.number-possible-items {
  padding: 5px;
}

.scrollpane {
  position: absolute;
  width: 100%;
}

.bottom-row {
  flex: 0 0 auto;
  border-top: 1px solid grey;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin: 5px;
  }
}

</style>
