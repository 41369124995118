<template>
  <div class="property-common">
    <TextInput v-model="number">
      <template #label>
        <IxRes>Common.SR_Common.Number</IxRes>
      </template>
    </TextInput>
    <TextInput v-model="name">
      <template #label>
        <IxRes>Common.SR_Common.Name</IxRes>
      </template>
    </TextInput>
    <PropertyTypeSelect
      v-model="propertyType"
      :label="SR_RealEstate.PropertyType"
      allow-empty
    />

    <div class="address">
      <h4><IxRes>Common.SR_Common.Address</IxRes></h4>
      <TextInput v-model="street">
        <template #label>
          <IxRes>Common.SR_Common.StreetAndHouseNumber</IxRes>
        </template>
      </TextInput>
      <div />
      <TextInput v-model="postalCode">
        <template #label>
          <IxRes>Common.SR_Common.PostalCode</IxRes>
        </template>
      </TextInput>
      <TextInput v-model="city">
        <template #label>
          <IxRes>Common.SR_Common.City</IxRes>
        </template>
      </TextInput>
    </div>

    <NumericInput
      :value="size"
      addon="ha"
      readonly
      hide-description
      format="f4"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Common.SR_Common.Size</IxRes>
      </template>
    </NumericInput>
    <TextInput
      v-model="remark"
      :rows="3"
      multiline
      class="span-2"
    >
      <template #label>
        <IxRes>Common.SR_Common.Remark</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'

import PropertyTypeSelect from '@realestate/components/property-type/PropertyTypeSelect'

export default {
  components: {
    TextInput,
    NumericInput,
    PropertyTypeSelect
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    ...mapGetters('realEstate', [
      'tableSelectionData'
    ]),

    ...mapFormFields('realEstate/properties', [
      'current.number',
      'current.name',
      'current.propertyType',
      'current.address',
      'current.address.street',
      'current.address.postalCode',
      'current.address.city',
      'current.remark',
      'current.segments'
    ]),

    size: {
      get () {
        if (this.segments) {
          let size = this.segments.map(seg => seg.size).reduce((pre, curr) => pre + curr, 0)
          return size
        } else return null
      }
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../../styles/_grid";

.property-common {
  @include makeGrid(2);
}

.address {
  @extend .span-2;
  @include makeGrid(2);

  margin: {
            top: 10px;
            bottom: 10px;
          }

  h4 {
    @extend .span-2;
    margin: {
              top: 0px;
              bottom: 0px;
            }
  }
}

.property-remark {
  grid-column: span 2;
}

</style>
