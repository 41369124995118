<template>
  <div class="contract-common-form">
    <TextInput v-model="commonData.documentNumber">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.ContractNumber</IxRes>
      </template>
    </TextInput>

    <VueDateTimePicker v-model="commonData.documentDate" iso>
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.DateOfContract</IxRes>
      </template>
    </VueDateTimePicker>

    <ContactSelect v-model="commonData.notary" :label="SR_RealEstate.Notary" contact-type="Notary" />

    <div />

    <ContactSelect v-model="commonData.partnerA" :label="partnerALabel" contact-type="Other" />
    <div>
      <label>{{ partnerBLabel }}</label>
      <div v-for="(partner, idx) in commonData.partnerB" :key="idx" class="purchase-partnerB">
        <ContactSelect v-model="commonData.partnerB[idx]" contact-type="Other" />
        <button class="btn btn-default delete-btn" :disabled="commonData.partnerB.length === 1" @click="removePartnerB(partner)">
          <span class="fa fa-trash-o" />
        </button>
      </div>
      <a class="small" @click="addPartnerB"> {{ linkText }}</a>
    </div>

    <TextInput v-model="commonData.dmsEntry">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.DMSEntry</IxRes>
      </template>
    </TextInput>
    <BusinessObjectSelect v-model="commonData.businessObject" :label="SR_Common.Object" modal-title="Kaufobjekt hinzufügen" /> <!-- TODO: use resource -->
    <NumericInput
      v-model="commonData.contractArea"
      format="f4"
      addon="ha" hide-description
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.AreaOfContract</IxRes>
      </template>
    </NumericInput>
    <TextInput
      v-model="commonData.remark"
      multiline
      class="remark"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.Remarks</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import NumericInput from '@components/forms/NumericInput'

import BusinessObjectSelect from '@realestate/components/business-object/BusinessObjectSelect'
import ContactSelect from '@realestate/components/contacts/ContactSelect'

export default {

  components: {
    BusinessObjectSelect,
    ContactSelect,
    NumericInput,
    TextInput,
    VueDateTimePicker
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common'
    ]),

    ...mapFormFields('realEstate/contract', [
      'current.commonData'
    ]),

    ...mapGetters('realEstate', [
      'currentArea',
      'tableSelectionData'
    ]),

    areaIsPurchase () {
      return this.currentArea.id === 'purchases'
    },

    partnerALabel () {
      return this.areaIsPurchase ? this.SR_RealEstate.Buyer : this.SR_RealEstate.Seller
    },

    partnerBLabel () {
      return this.areaIsPurchase ? this.SR_RealEstate.Seller : this.SR_RealEstate.Buyer
    },

    linkText () {
      return this.areaIsPurchase ? this.SR_RealEstate.AddSeller : this.SR_RealEstate.AddBuyer
    }
  },

  methods: {
    ...mapMutations('realEstate/contract', [
      'addPartnerB',
      'removePartnerB'
    ])
  }
}

</script>

<style lang="scss" scoped>

  @import "../../styles/_grid";

  .contract-common-form {
    @include makeGrid(2);
  }

  .purchase-partnerB {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .contact-selector {
      flex: 1 1 auto;
      margin-right: 2px;
    }

    .btn {
           flex: 0 0 auto;
    }
  }

  .remark {
    @extend .span-2;
  }

</style>
