<template lang="html">
  <div class="layout">
    <div class="main" :class="{pinned: leftSidebarPinned}">
      <slot name="mainContent" />
    </div>
    <div class="left" :class="{pinned: leftSidebarPinned}">
      <div class="content">
        <slot name="leftContent" />
      </div>
      <div id="left-handle" class="handle">
        <i class="fa fa-2x fa-thumb-tack" @click="toggleLeftSidebarPinned" />
      </div>
    </div>

    <div class="right">
      <div class="right-content">
        <div class="right-bottom-layer">
          <slot name="rightBottomLayer" />
        </div>

        <div class="right-upper-layer" :class="{collapsed: !rightSidebarPinned}">
          <div id="right-handle" class="handle">
            <i class="fa fa-2x fa-thumb-tack" @click="toggleRightSidebarPinned" />
          </div>

          <div class="right-upper-layer-content">
            <slot name="rightUpperLayer" />
          </div>
        </div>
      </div>

      <div class="button-bar">
        <IxButton
          save large
          :disabled="!dataEdited"
          @click="save"
        />
        <IxButton
          remove large
          icon="undo"
          :disabled="!dataEdited" @click="reset"
        >
          <IxRes>Common.SR_Common.Reset</IxRes>
        </IxButton>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'

export default {

  components: {
    IxButton
  },

  data () {
    return {
      dataEdited: false
    }
  },
  computed: {
    ...mapGetters('realEstate', [
      'leftSidebarPinned',
      'rightSidebarPinned',
      'currentOrgUnit',
      'selection',
      'currentState'
    ]),

    singleSelection () {
      return this.selection.length === 1
    }
  },
  methods: {
    ...mapMutations('realEstate', [
      'toggleLeftSidebarPinned',
      'toggleRightSidebarPinned'
    ]),

    ...mapActions('realEstate', [
      'fetchAggregationData',
      'resetData',
      'saveData'
    ]),

    save () {
      this.saveData()
        .then(() => {
          this.dataEdited = false
          notifications.success('Erfolgreich gespeichert') // TODO: use resource
        })
        .catch(() => {
          notifications.error('Speichern fehlgeschlagen') // TODO: use resource
        })
    },

    reset () {
      this.resetData()
        .then(() => {
          this.dataEdited = false
        })
        .catch((error) => {
          alert(error)
        })
    }
  },

  watch: {
    currentState: {
      handler (newVal, oldVal) {
        if (newVal === oldVal) {
          this.dataEdited = true
        }
      },
      deep: true
    },

    selection () {
      this.dataEdited = false
      if (!this.singleSelection && this.currentOrgUnit) {
        this.fetchAggregationData()
      }
    },

    currentOrgUnit (value) {
      this.dataEdited = false
      if (!this.singleSelection && value && value.length > 0) {
        this.fetchAggregationData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  $slidingOutDuration: 0.5s;
  $slidingInDuration: $slidingOutDuration;
  $slideInDelay: 0.3s;

  .layout {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: "left main right" 1fr / auto 1fr 1fr;
  }

  .content {
    padding: 10px;
  }

  .left {
    position: absolute;
    width: 20px;
    height: 100%;
    transition: width $slidingInDuration ease-in-out $slideInDelay;
    background-color: #fff;
    display: grid;
    grid-template: "content handle" 1fr / 1fr auto;
    border-right: 1px solid grey;
    overflow: hidden;
    .content

  {
    opacity: 0;
    transition: opacity $slidingInDuration ease-out $slideInDelay;
  }

  .handle {
    grid-area: handle;
    display: flex;
    flex-direction: column;
    i

  {
    opacity: 0;
    transition: opacity $slidingInDuration ease-out $slideInDelay;
  }

  }

  &.pinned {
    position: relative;
    height: 100%;
    grid-area: left;
    width: 20vw;
    .content

  {
    opacity: 1;
  }

  .handle {
    i

  {
    opacity: 1;
    transform: rotate(20deg);
  }

  }
  }

  &:hover {
    width: 20vw;
    transition: width $slidingOutDuration ease-in-out;
    .content

  {
    opacity: 1;
    transition: opacity $slidingOutDuration ease-in;
  }

  .handle {
    i

  {
    opacity: 1;
    transition: opacity $slidingOutDuration ease-in;
  }

  }
  }
  }

  .main {
    grid-area: main;
    border-right: 1px solid;
    margin-left: 20px;
    transition: width 0.5s ease-in-out;
    &.pinned

  {
    margin-left: 0px;
    transition: width 0.5s ease-in-out;
  }

  }

  .right-content {
    flex: 1 1 auto;
    position: relative;
    display: flex;
  }

  .right {
    grid-area: right;
    /*position: relative;*/
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .right-bottom-layer {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin-right: 20px;
  }

  .right-upper-layer {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    transition: all $slidingInDuration ease-in-out;
    .right-upper-layer-content

  {
    width: 100%;
    margin-right: 20px;
  }

  &.collapsed {
    right: calc(0px - 100%);
    left: calc(100% - 20px);
    background-color: #fff;
    border-left: 1px solid grey;
    transition: all $slidingInDuration ease-in-out;
    .right-upper-layer-content

  {
    opacity: 0;
    transition: all $slidingInDuration ease-in-out;
  }

  }
  }

  .button-bar {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid grey;
    button

  {
    margin: 5px;
  }
  }
</style>
