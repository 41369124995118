<template>
  <div class="property-tax-notice">
    <NumericInput
      v-model="landValueDM"
      :addon="SR_Common.CurrencyDM"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.LandValue</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="outdoorFacilitiesValue"
      :addon="SR_Common.CurrencyDM"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.OutdoorFacilitiesValue</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="propertyValue"
      :addon="SR_Common.CurrencyDM"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.PropertyValue</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="unitValueDM"
      :addon="SR_Common.CurrencyDM"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.UnitValue</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="unitValueEUR"
      :addon="SR_Common.CurrencyEuro"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.UnitValue</IxRes>
      </template>
    </NumericInput>
    <VueDateTimePicker v-model="dateUnitValueFromTaxNotice">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.DateUnitValueFromTaxNotice</IxRes>
      </template>
    </VueDateTimePicker>
    <NumericInput
      v-model="assessedRate"
      :addon="SR_Common.InPercent"
      hide-description format="f0"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.AssessedRate</IxRes>
      </template>
    </NumericInput>
    <NumericInput
      v-model="basicAmmount"
      :addon="SR_Common.CurrencyEuro"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.BaseValueForTaxPurposes</IxRes>
      </template>
    </NumericInput>
    <div />
    <TaxAuthoritySelect v-model="taxAuthority" :label="SR_RealEstate.TaxAuthority" />
    <TextInput v-model="referenceNumber">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.ReferenceNumber</IxRes>
      </template>
    </TextInput>
    <VueDateTimePicker v-model="notificationDate">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.NotificationDate</IxRes>
      </template>
    </VueDateTimePicker>
    <CommunitySelect v-model="community" :label="SR_RealEstate.Community" :modal-title="SR_RealEstate.AddCommunity" />
    <TextInput v-model="cashReferenceNumber">
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.CashReferenceNumber</IxRes>
      </template>
    </TextInput>
    <div />
    <NumericInput
      v-model="annualPropertyTax"
      :addon="SR_Common.CurrencyEuro"
      hide-description format="f2"
      class="re-numeric"
    >
      <template #label>
        <IxRes>Areas.RealEstate.SR_RealEstate.AnnualPropertyTax</IxRes>
      </template>
    </NumericInput>

    <div class="property-tax-rates">
      <h4><IxRes>Areas.RealEstate.SR_RealEstate.PropertyTaxRates</IxRes></h4>
      <NumericInput
        v-model="propertyTaxQ1"
        :addon="SR_Common.CurrencyEuro"
        hide-description format="f2"
        class="re-numeric"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.PropertyTaxRatesPropertyTaxQ1</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="propertyTaxQ2"
        :addon="SR_Common.CurrencyEuro"
        hide-description format="f2"
        class="re-numeric"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.PropertyTaxQ2</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="propertyTaxQ3"
        :addon="SR_Common.CurrencyEuro"
        hide-description format="f2"
        class="re-numeric"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.PropertyTaxQ3</IxRes>
        </template>
      </NumericInput>
      <NumericInput
        v-model="propertyTaxQ4"
        :addon="SR_Common.CurrencyEuro"
        hide-description format="f2"
        class="re-numeric"
      >
        <template #label>
          <IxRes>Areas.RealEstate.SR_RealEstate.PropertyTaxQ4</IxRes>
        </template>
      </NumericInput>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'

import CommunitySelect from '@realestate/components/community/CommunitySelect'
import TaxAuthoritySelect from '@realestate/components/tax-authority/TaxAuthoritySelect'

export default {
  components: {
    TextInput,
    NumericInput,
    TaxAuthoritySelect,
    VueDateTimePicker,
    CommunitySelect
  },

  props: {

  },

  data () {
    return {}
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'Common.SR_Common'
    ]),

    ...mapGetters('realEstate', [
      'tableSelectionData'
    ]),

    ...mapFormFields('realEstate/properties', [
      'current.taxNotice.annualPropertyTax',
      'current.taxNotice.assessedRate',
      'current.taxNotice.basicAmmount',
      'current.taxNotice.cashReferenceNumber',
      'current.taxNotice.community',
      'current.taxNotice.dateUnitValueFromTaxNotice',
      'current.taxNotice.landValueDM',
      'current.taxNotice.notificationDate',
      'current.taxNotice.outdoorFacilitiesValue',
      'current.taxNotice.propertyTaxQ1',
      'current.taxNotice.propertyTaxQ2',
      'current.taxNotice.propertyTaxQ3',
      'current.taxNotice.propertyTaxQ4',
      'current.taxNotice.propertyValue',
      'current.taxNotice.referenceNumber',
      'current.taxNotice.taxAuthority',
      'current.taxNotice.unitValueDM',
      'current.taxNotice.unitValueEUR'
    ])
  }
}

</script>

<style lang="scss">

@import "../../styles/_grid";

.property-tax-notice {
  @include makeGrid(3);
}

.property-tax-rates {
  @extend .span-3;
  @include makeGrid(4);
  margin: {
            top: 10px;
            bottom: 10px;
          }

  h4 {
        margin: {
                  top: 0px;
                  bottom: 0px;
                }
        @extend .span-4;
      }
}

</style>
