<template>
  <div class="land-parcel-contracts">
    <SimpleTable
      ref="table"
      :columns="columnDefinition"
      :data="contracts"
    >
      <template slot="header-link">
        <span class="glyphicon dont-show" />
      </template>

      <template slot="link" slot-scope="{value}">
        <a :href="value" target="_blank" data-ajax="false">
          <span class="glyphicon glyphicon-circle-arrow-right" />
        </a>
      </template>

      <template slot="contractDate" slot-scope="{value}">
        <span>{{ value | formatDate }}</span>
      </template>

      <template slot="size" slot-scope="{value}">
        <div class="pull-right">
          {{ value | formatAsHectare }}
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {mapResources, mapFormFields} from '@helpers/vuex'
import {formatAsHectare, formatDate} from '@realestate/filters/numberFormatters'

import SimpleTable from '@components/table/SimpleTable'

export default {

  components: {
    SimpleTable
  },

  filters: {
    formatAsHectare,
    formatDate
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties',
      'Common.SR_Common'
    ]),

    ...mapFormFields('realEstate/landparcels', [
      'current.contracts'
    ]),

    columnDefinition () {
      return {
        link: {
          title: ' '
        },
        contractDate: {
          title: this.SR_RealEstate.DateOfContract
        },
        documentNumber: {
          title: this.SR_RealEstate.ContractNumber
        },
        contractType: {
          title: this.SR_RealEstate.ContractType
        },
        partner: {
          title: this.SR_ModelProperties.Partner
        },
        size: {
          title: this.SR_RealEstate.Size
        },
        begin: {
          title: this.SR_ModelProperties.Begin,
          type: 'date'
        },
        end: {
          title: this.SR_ModelProperties.End,
          type: 'date'
        }
      }
    }
  }
}

</script>

<style lang="scss">

.land-parcel-contracts {

  .dont-show::before {
    white-space: pre;
    content: ' ';
  }
}

</style>
