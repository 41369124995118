<template>
  <SimpleTable
    ref="table"
    :columns="columnDefinition"
    :data="rows"
    :highlight="selected"
    :disable-internal-sort="true"
    fixed-header
    @sorted="onSorted"
    @scroll="onScroll"
  >
    <template slot="Area" slot-scope="{value}">
      <div class="pull-right">
        {{ value | formatAsHectare }}
      </div>
    </template>
  </SimpleTable>
</template>

<script>
import {mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'

import SimpleTable from '@components/table/SimpleTable'

import {formatAsHectare} from '@realestate/filters/numberFormatters'
import makeSelectionMixin from '@realestate/mixins/SelectionMixin'

export default {

  components: {
    SimpleTable
  },

  filters: {
    formatAsHectare
  },

  mixins: [
    makeSelectionMixin('table')
  ],

  props: {
    rows: Array,
    isSales: Boolean
  },

  data () {
    return {

      selected: (row) => {
        if (this.selection.table.includes(row)) {
          return ['success']
        }
        return []
      }
    }
  },

  computed: {
    ...mapResources([
      '@realestate.SR_RealEstate'
    ]),

    columnDefinition () {
      return {
        DocumentNumber: {
          title: this.SR_RealEstate.ContractNumber,
          sortable: true,
          width: '20%'
        },
        DocumentDate: {
          title: this.SR_RealEstate.DateOfContract,
          type: 'date',
          sortable: true,
          width: '20%'
        },
        PartnerA: {
          title: this.isSales ? this.SR_RealEstate.Seller : this.SR_RealEstate.Purchaser,
          width: '20%'
        },
        PartnerB: {
          title: this.isSales ? this.SR_RealEstate.Purchaser : this.SR_RealEstate.Seller,
          width: '20%'
        },
        Area: {
          title: this.SR_RealEstate.Size,
          sortable: true,
          width: '20%'
        }
      }
    }
  },

  methods: {
    ...mapActions('realEstate', [
      'loadDataForTableSelection'
    ]),

    onSelected (selectedItems) {
      if (selectedItems && selectedItems.length === 1) {
        this.loadDataForTableSelection(`/realestate/Contract/GetContractDetailData?id=${selectedItems[0].Uid}`)
      } else {
        // TODO: load data for multiple selection
      }
    },

    onSorted (sortedEventArgs) {
      this.$emit('sorted', sortedEventArgs)
    },

    onScroll (scrollEvent) {
      this.$emit('scroll', scrollEvent)
    }
  },

  mounted () {
    this.$on('selected', this.onSelected)
  }
}

</script>

<style lang="scss">

</style>
