<template>
  <div class="history-container">
    <div
      v-for="(entry, key) in history"
      :key="key"
      class="history-entry"
    >
      <div>
        <label class="entry-label">
          <IxRes>Areas.RealEstate.SR_RealEstate.Timestamp</IxRes>
        </label>{{ entry.general.validTimestamp | formatDate }}
      </div>
      <div>
        <label class="entry-label">
          <IxRes>Areas.RealEstate.SR_RealEstate.Entrytime</IxRes>
        </label>{{ entry.general.entryTimestamp | formatDate }}
      </div>
      <div>
        <label class="entry-label">
          <IxRes>Areas.RealEstate.SR_RealEstate.ChangeType</IxRes>
        </label>{{ convertChangeType(entry.general.changeType) }}
      </div>

      <div v-if="convertChangeType(entry.general.changeType) === 'data migration state'">
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.LandRegister</IxRes>
          </label>
          <template v-if="entry.migrationData.communityName">
            {{ entry.migrationData.communityName }}
          </template>
          <IxRes v-else>Areas.RealEstate.SR_RealEstate.NoLandRegisterEntry</IxRes>
          {{ entry.migrationData.LandRegisterPage || '' }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.Owner</IxRes>
          </label>
          <template v-if="entry.migrationData.ownerName">
            {{ entry.migrationData.ownerName }}
          </template>
          <IxRes v-else>Areas.RealEstate.SR_RealEstate.NoOwnerKnown</IxRes>
          {{ entry.migrationData.ownerName2 || '' }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.Size</IxRes>
          </label> {{ entry.migrationData.size / 10000 | formatAsHectare }} ha
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.ExistsAtMigrationTime</IxRes>
          </label> {{ entry.migrationData.existsAtTimeOfMigration ? 'ja' : 'nein' }}
        </div>
      </div>

      <div v-if="convertChangeType(entry.general.changeType) === 'Registrierung'">
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.LandRegister</IxRes>
          </label>
          <template v-if="entry.migrationData.communityName">
            {{ entry.migrationData.communityName }}
          </template>
          <IxRes v-else>Areas.RealEstate.SR_RealEstate.NoLandRegisterEntry</IxRes>
          {{ entry.registrationData.LandRegisterPage || '' }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.Owner</IxRes>
          </label>
          <template v-if="entry.migrationData.ownerName">
            {{ entry.migrationData.ownerName }}
          </template>
          <IxRes v-else>Areas.RealEstate.SR_RealEstate.NoOwnerKnown</IxRes>
          {{ entry.registrationData.ownerName2 || '' }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.Size</IxRes>
          </label> {{ entry.registrationData.size / 10000 | formatAsHectare }} ha
        </div>
      </div>

      <div v-if="convertChangeType(entry.general.changeType) === 'Kauf' || convertChangeType(entry.general.changeType) === 'Verkauf' || convertChangeType(entry.general.changeType) === 'contractual assignment' ">
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.ContractType</IxRes>
          </label> {{ entry.contractData.contractType }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.ContractNumber</IxRes>
          </label> {{ entry.contractData.contractNumber }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.DmsReferences</IxRes>
          </label> {{ entry.contractData.contractELO }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.SalePrice</IxRes>
          </label> {{ entry.contractData.price }} {{ entry.contractData.currency }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.LandRegister</IxRes>
          </label> {{ entry.contractData.LandRegisterCommunityName }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.NewOwner</IxRes>
          </label> {{ entry.newOwner }}
        </div>
        <div>
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.Size</IxRes>
          </label> {{ entry.contractData.contractualSize / 10000 | formatAsHectare }} ha
        </div>
      </div>

      <div v-if="convertChangeType(entry.general.changeType) === 'Manuelle Änderung'">
        <div v-if="entry.hasSizeChange">
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.SizeChange</IxRes>
          </label> {{ entry.newSize / 10000 | formatAsHectare }} ha
        </div>
        <div v-if="entry.hasOwnerChange">
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.OwnerChange</IxRes>
          </label> {{ entry.newOwner }}
        </div>
        <div v-if="entry.hasLandRegisterChange">
          <label class="entry-label">
            <IxRes>Areas.RealEstate.SR_RealEstate.LandRegisterChange</IxRes>
          </label> {{ entry.newLandRegister }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import {formatAsHectare, formatDate} from '@realestate/filters/numberFormatters'

export default {
  filters: {
    formatAsHectare,
    formatDate
  },

  computed: {
    ...mapFormFields('realEstate/landparcels', [
      'current.history'
    ])
  },

  methods: {
    convertChangeType (entry) {
      switch (entry) {
      case 0: return 'Registrierung'
      case 1: return 'Kauf'
      case 2: return 'Verkauf'
      case 3: return 'Manuelle Änderung'
      case 4: return 'contractual assignment'
      case 5: return 'transformation source'
      case 6: return 'transformation target'
      case 7: return 'data migration state'
      default: return null
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.history-entry {
  border: 1px solid grey;
  margin: 5px;
  padding: 5px;
}

.entry-label {
    width: 30%;
}

</style>
